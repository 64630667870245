import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Group, Badge } from 'atoms';

const TeamSlider = ({ teams, onSelect }) => {
  let countSpecItems = 0;

  return (
    <Group
      spaceSize={8}
      specSelector="teams-selection-slider"
      style={{
        overflowX: 'auto',
        margin: '10px 15px',
      }}>
      {teams.map(team => {
        if (!team) return null;
        const isSelectable = !team.isSelected && !team.isExcluded;
        if (isSelectable) countSpecItems = countSpecItems + 1;
        return (
          <div
            key={team.source}
            style={{ position: 'relative', margin: 'auto' }}>
            {team.isSelected && (
              <Badge
                isSmall
                isDanger={team.number === 2}
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  bottom: 5,
                  right: 5,
                }}>
                <b>{team.number}</b>
              </Badge>
            )}
            <Avatar
              data-guided-tour="team-avatar"
              data-guided-tour-emulate-click="team-logo"
              specSelector={
                isSelectable ? `teams-selection-item-${countSpecItems}` : null
              }
              source={team.source}
              isPrimary={team.isSelected && team.number === 1}
              isDanger={team.isSelected && team.number === 2}
              isOutlined
              size={80}
              {...{
                [team.isSelected ? 'isPrimary' : 'isTertiary']: true,
              }}
              style={{
                flexShrink: '0',
                opacity: team.isExcluded ? 0.4 : 1,
              }}
              onClick={() =>
                team.isSelected || team.isExcluded ? null : onSelect(team)
              }
            />
          </div>
        );
      })}
    </Group>
  );
};

TeamSlider.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.exact({
      source: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
      isExcluded: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired,
    })
  ),
  onSelect: PropTypes.func.isRequired,
};

export default TeamSlider;
