import React, { useState } from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Group, Icon, GeneralText, Space } from 'atoms';
import { Fill } from 'atoms/Group';
import { Star } from 'assets/icons';
import { StatsRow } from 'molecules';
import CardWrapper from 'molecules/CardWrapper';
import PlayerAvatar from 'molecules/PlayerAvatar';
import PersonHeading from 'molecules/PersonHeading';

import Modal from 'molecules/Modal';
import ModalHeader from 'organisms/ModalHeader';

import PlayerProfile from 'pages/PlayerProfile';

const PlayerCard = props => {
  const {
    id,
    children,
    cardDescription,
    cardLabel,
    showPlayerInfo,
    status,
    fullName,
    picture,
    position,
    seasonAverage,
    isActive,
    teamNameLogo,
    playerIndex,
  } = props;

  const [showDetails, setDetailsState] = useState(false);

  return (
    <CardWrapper
      specSelector={`player-card-${isActive ? 'active' : 'inactive'}`}
      topLabel={
        <GeneralText color={colors.white}>
          <b>{cardDescription}</b>
        </GeneralText>
      }
      sx={{ height: 450 }}>
      {isActive && (
        <>
          <Group
            isVertical
            isCentered
            padding={10}
            spaceSize={5}
            style={{
              height: 'calc(100% - 29px)',
              position: 'relative',
              zIndex: 1,
            }}>
            <Group isCentered isVerticalCentered>
              <Icon size={14}>
                <Star />
              </Icon>
              <GeneralText>
                <b>{cardLabel}</b>
              </GeneralText>
              <Icon size={14}>
                <Star />
              </Icon>
            </Group>

            <StatsRow stats={seasonAverage || []} hasLabelOnTop />

            <GeneralText
              isSmall
              style={{ position: 'absolute', top: 10, right: 10 }}>
              <b>#{playerIndex + 1}</b>
            </GeneralText>

            <PlayerAvatar
              source={isActive && picture}
              flagLabel={status}
              hasInfo={showPlayerInfo}
              teamLogo={teamNameLogo}
              size={120}
              onClick={() => setDetailsState(true)}
            />

            <Modal
              isOpen={showDetails}
              customHeader={() => (
                <ModalHeader
                  onClickToClose={() => setDetailsState(false)}
                  title={fullName}
                />
              )}
              onClickToClose={() => setDetailsState(false)}>
              {showDetails && (
                <PlayerProfile
                  competitorId={id}
                  seasonAverage={seasonAverage}
                />
              )}
            </Modal>

            <Space isVertical />

            <PersonHeading subtitle={position}>{fullName}</PersonHeading>

            <Fill />

            {children}
          </Group>
        </>
      )}
    </CardWrapper>
  );
};

PlayerCard.defaultProps = {
  cardDescription: '---',
  cardLabel: '---',
  fullName: '---',
  position: '---',
  showPlayerInfo: true,
};

PlayerCard.propTypes = {
  cardDescription: PropTypes.string,
  cardLabel: PropTypes.string,
  children: PropTypes.any,
  fullName: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  labelColor: PropTypes.string,
  labelSize: PropTypes.oneOf(['isLarge', 'isMedium', 'isSmall', 'isRegular']),
  labelTop: PropTypes.bool,
  picture: PropTypes.string,
  playerIndex: PropTypes.number,
  position: PropTypes.string,
  seasonAverage: PropTypes.array,
  showPlayerInfo: PropTypes.bool,
  status: PropTypes.string,
  teamNameLogo: PropTypes.any,
};

export default PlayerCard;
