import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Pencil } from 'assets/icons';
import { Button, GeneralText, Group, Icon } from 'atoms';
import { Fill } from 'atoms/Group';
import { CardWrapper } from 'molecules';

const CardHeader = ({ onEdit, isEditMode, title, children }) => (
  <CardWrapper
    topLabel={
      <Group isFullWidth>
        <GeneralText isLarge color={colors.white}>
          {title}
        </GeneralText>

        <Fill />

        <Button isBare onClick={() => onEdit(!isEditMode)}>
          <Icon color={colors.white}>
            <Pencil />
          </Icon>
        </Button>
      </Group>
    }>
    {children}
  </CardWrapper>
);

CardHeader.defaultProps = {
  onEdit: () => {},
};

CardHeader.propTypes = {
  onEdit: PropTypes.func,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default CardHeader;
