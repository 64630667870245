import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { Group } from 'atoms';

import colors from 'assets/themes/colors';

const StyledGroup = styled(Group)`
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 6px;
  background-color: ${colors.black};
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
`;

const Container = ({ children }) => (
  <StyledGroup
    padding={'10px 12px 12px'}
    spaceSize={4}
    isVertical
    isVerticalCentered>
    {children}
  </StyledGroup>
);

Container.propTypes = {
  children: PropTypes.any,
};

export default Container;
