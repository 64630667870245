import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import useModal from 'hooks/useModal';

import { Group, Button, GeneralText } from 'atoms';
import NumberSteps from 'molecules/NumberSteps';
import CreditCard from 'organisms/CreditCard';

import colors from 'assets/themes/colors';

import Checkout from './Checkout';
import BalanceDisplay from './BalanceDisplay';
import RefundsPolicy from './RefundsPolicy';

import getVaultData from 'hooks/getVaultData';

const Amount = styled(GeneralText)`
  text-align: center;
  font-size: 64px;
  padding: 20px;
  padding-bottom: 24px;
  font-weight: bold;
`;

const Content = ({ onClickToClose }) => {
  const [AddCreditCard, toggleAddCreditCard] = useModal();
  const [CheckoutScreen, toggleCheckout] = useModal();

  const [canConfirm, toggleConfirmation] = useState(false);
  const [funds, updateFundsValue] = useState(25);
  const [ccData, setCcData] = useState(null);

  const [callVault, { status, data }] = getVaultData();

  // TODO: We gonna finished that after launch
  // TODO: Improve design of the stored credit card displayed
  // TODO: Improve the flow to add/update the stored credit card
  // TODO: Handle add/update/remove credit card with a separate hook and cloud function

  const isLoadingVault = status === 'waiting' || status === 'processing';
  const userHasVault = status === 'success';

  useEffect(() => {
    callVault();
  }, []);

  return (
    <Group
      isVertical
      isCentered
      isFullWidth
      padding={5}
      isGrown
      style={{ flexShrink: 0, maxWidth: 450, margin: 'auto' }}>
      <GeneralText
        isSmall
        style={{
          position: 'fixed',
          zIndex: 10,
          width: '100%',
          backgroundColor: colors.alert,
          color: 'white',
          padding: 4,
          top: 83,
        }}>
        <b>THIS IS A DEMO, NO MONEY EXCHANGES ARE MADE</b>
      </GeneralText>

      <BalanceDisplay newBalance={funds} />

      <NumberSteps
        stepSize={5}
        min={25}
        defaultValue={funds}
        customInput={({ innerValue }) => (
          <Amount color={colors.accent}>${innerValue}</Amount>
        )}
        onChange={value => {
          updateFundsValue(value);
        }}
      />

      {status === 'error' && (
        <Button isXSmall isSecondary onClick={toggleAddCreditCard}>
          Add a credit card to proceed
        </Button>
      )}

      {status === 'success' && (
        <>
          {ccData ? (
            <>
              {ccData.cardType +
                ', ' +
                ccData.cardNumber +
                ', ' +
                ccData.expiryDate}
            </>
          ) : (
            <>
              {data.cardType + ', ' + data.cardNumber + ', ' + data.expiryDate}
            </>
          )}
          <hr />
          <Button isXSmall isSecondary onClick={toggleAddCreditCard}>
            Use another card?
          </Button>
        </>
      )}

      {isLoadingVault && <>Loading security data...</>}

      <hr style={{ width: '100%' }} />

      <RefundsPolicy onChange={() => toggleConfirmation(!canConfirm)} />

      <div style={{ width: '100%', padding: '20px 0 0' }}>
        <Button
          isDisabled={!canConfirm && !isLoadingVault}
          isFullWidth
          onClick={toggleCheckout}>
          CHECKOUT
        </Button>
      </div>
      <div style={{ width: '100%', padding: '15px 0 20px' }}>
        <Button
          isFullWidth
          isOutlined
          isSecondary
          isXSmall
          onClick={onClickToClose}>
          SKIP
        </Button>
      </div>

      <AddCreditCard customHeader={() => null} isCovering={false}>
        <CreditCard
          onSubmit={ccValues => {
            setCcData(ccValues);
            toggleAddCreditCard();
          }}
        />
      </AddCreditCard>

      <CheckoutScreen customHeader={() => null} isCovering={false}>
        <Checkout
          amount={funds}
          userHasVault={userHasVault}
          cc={ccData}
          onClose={onClickToClose}
        />
      </CheckoutScreen>
    </Group>
  );
};

Content.propTypes = {
  onClickToClose: PropTypes.func,
};

export default Content;
