import React from 'react';
import PropTypes from 'prop-types';

import { Group, GeneralText, Icon } from 'atoms';
import { TrophyAlt, Users } from 'assets/icons';

import colors from 'assets/themes/colors';

const GameInstance = ({
  activeParticipants,
  totalParticipants,
  payoutType,
}) => (
  <Group isFullWidth isCentered isVerticalCentered spaceSize={15}>
    <Group
      style={{
        position: 'relative',
        border: `1px solid ${colors.primary}`,
        borderRadius: 6,
        backgroundColor: colors.primary,
        textAlign: 'center',
      }}>
      <Group
        isVertical
        isCentered
        spaceSize={0}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 5,
          height: '100%',
          width: '100%',
          padding: '4px 8px 3px',
          border: `1px solid ${colors.accent}`,
        }}>
        <GeneralText isSmall color={colors.white}>
          <b>
            <span style={{ color: colors.accent }}>FREE</span>2PLAY
          </b>
        </GeneralText>
        <GeneralText isSmall color={colors.quaternary}>
          <b>CONTEST</b>
        </GeneralText>
      </Group>
    </Group>

    <Group
      isVertical
      isCentered
      isVerticalCentered
      spaceSize={0}
      style={{ marginTop: -1 }}>
      <Group isCentered isVerticalCentered>
        <Icon
          size={14}
          color={colors.tertiary}
          style={{ transform: 'rotate(-5deg)', marginTop: -1 }}>
          <Users />
        </Icon>
        <Group isVerticalCentered>
          <GeneralText isSmall>
            <b>{totalParticipants}</b>
          </GeneralText>
          <GeneralText isSmall color={colors.tertiary}>
            <b>SEATS</b>
          </GeneralText>
        </Group>
      </Group>
      <GeneralText isSmall style={{ marginTop: -4 }}>
        <b>
          You are{' '}
          <span style={{ textDecoration: 'underline' }}>
            #{activeParticipants}
          </span>
        </b>
      </GeneralText>
    </Group>

    <Group isVerticalCentered spaceSize={8}>
      <Group isVertical isVerticalCentered style={{ marginTop: 4 }}>
        <GeneralText
          style={{
            marginRight: '-15px',
            marginBottom: '-4px',
          }}>
          <b>TOP</b>
        </GeneralText>
        <Group isVerticalCentered>
          <Icon
            size={15}
            color={colors.positive}
            style={{ transform: 'rotate(-5deg)', marginTop: -4 }}>
            <TrophyAlt />
          </Icon>
          <GeneralText
            isLarge
            style={{
              marginTop: -1,
            }}>
            <b>{payoutType}</b>
          </GeneralText>
        </Group>
      </Group>
      <Group isVertical isVerticalCentered spaceSize={0}>
        <GeneralText
          color={colors.positive}
          style={{
            borderRadius: 4,
            padding: '0 4px',
            backgroundColor: colors.white,
          }}>
          <b>M$0.25</b>
        </GeneralText>
        <GeneralText isSmall>Payout</GeneralText>
      </Group>
    </Group>
  </Group>
);

GameInstance.defaultProps = {
  selectedValue: 0,
  activeParticipants: 0,
  totalParticipants: 0,
  payout: 0,
  entryFees: [],
  onEntryFeeSelect: () => {},
};

GameInstance.propTypes = {
  activeParticipants: PropTypes.number.isRequired,
  beginnersOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  entryFee: PropTypes.number,
  entryFees: PropTypes.arrayOf(
    PropTypes.shape({
      entryFee: PropTypes.number.isRequired,
      disabled: PropTypes.bool,
      beginnersOnly: PropTypes.bool,
      full: PropTypes.bool,
      gameType: PropTypes.number,
      id: PropTypes.string,
      maxEntries: PropTypes.number,
      payout: PropTypes.number,
      timeBlockId: PropTypes.string,
    })
  ).isRequired,
  full: PropTypes.bool,
  gameType: PropTypes.number,
  id: PropTypes.string,
  maxEntries: PropTypes.number,
  onEntryFeeSelect: PropTypes.func.isRequired,
  payout: PropTypes.number.isRequired,
  payoutType: PropTypes.any,
  selectedValue: PropTypes.number,
  timeBlockId: PropTypes.string,
  totalParticipants: PropTypes.number.isRequired,
};

export default GameInstance;
