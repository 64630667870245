import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const applyPropStyles = ({ backgroundColor }) =>
  css`
    background-color: ${backgroundColor};
  `;

const DividerDiv = styled.div`
  ${applyPropStyles};
  width: 1px;
  height: ${({ height }) => height}px;
  flex-shrink: 0;
`;

const VerticalDivider = ({ height, backgroundColor, ...rest }) => (
  <DividerDiv height={height} backgroundColor={backgroundColor} {...rest} />
);

VerticalDivider.defaultProps = {
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  height: 10,
};

VerticalDivider.propTypes = {
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
};

export default VerticalDivider;
