import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Button, GeneralText, Group, Link, Title } from 'atoms';

const Wrapper = styled(Group)`
  background-color: ${colors.white};
`;
const ButtonWrapper = styled(Button)`
  display: inline-block;
`;

const ResponsiblePlay = ({ navigate }) => (
  <Wrapper isVertical spaceSize={20} padding={20}>
    <GeneralText isMedium>
      At Meteor Fantasy, we are committed to protecting our players. We
      recognize that sometimes people may not have all the resources necessary
      to be able to manage their play in a productive, responsible manner.
      Therefore, we offer proactive tools and support meant to encourage healthy
      player behavior and deliver positive player experiences.
    </GeneralText>

    <Title>Self-Exclusion</Title>
    <GeneralText isMedium>
      If you wish to disable your account for any reason, you may apply a
      self-exclusion to your account. Tell us how much time you need, and we
      will make sure your account no longer allows you to log into Meteor
      Fantasy or join any contests during that period. Here’s how it works:
      <br />
      <br />
      <ButtonWrapper
        isBare
        color={colors.accent}
        onClick={() => navigate('/contact')}>
        Click here
      </ButtonWrapper>{' '}
      to submit your request for self-exclusion.
      <br />
      <br />
      ● You can suspend your account for your choice of 3, 6, 9 or 12 months.
      <br />
      <br />
      ● Any upcoming entries will be canceled and refunded.
      <br />
      <br />
      ● You can reach out to Customer Support for assistance if you wish to
      withdraw any remaining funds from your account
      <br />
      <br />
      ● You will not be able to login or access your Meteor Fantasy account.
      <br />
      <br />
      ● We will suppress your email address (the one you signed up with) from
      receiving additional, personally directed promotional or marketing
      messages. Please allow up to 24 hrs to be removed from any promotional
      e-mails.
      <br />
      <br />
      If for any reason, you want to extend the time period of your
      self-exclusion or permanently close your account, contact Customer
      Support, and we can assist you in making your final withdrawal and
      shutting down your account. If you decide that you would like to come back
      and play at the end of your self-exclusion period, please contact Customer
      Support. Each request for reactivation will be evaluated individually.
      <br />
      <br />
      Additionally, we recommend that you contact any other daily fantasy sports
      sites you may play with and request an exclusion from there as well.
      <br />
      <br />
      <ButtonWrapper
        isBare
        color={colors.accent}
        onClick={() => navigate('/contact')}>
        Click here
      </ButtonWrapper>{' '}
      to submit your request for self-exclusion.
    </GeneralText>

    <Title>Limitations on Consumer Deposits</Title>
    <GeneralText isMedium>
      In accordance with state requirements, Meteor Fantasy restricts deposits
      in any calendar month for residents of certain states unless the user has
      requested and received approval for an increase. For Massachusetts
      residents the deposit limit level is $1,000 per calendar month. For
      Tennessee residents and people located in Tennessee, the deposit limit
      level is $2,500 per calendar month.
      <br />
      <br />
      To request an increase in your deposit limit (for applicable states), you
      will be asked to complete and submit a form that helps us evaluate your
      income and asset information. You may also be required to submit
      additional documentation in support of your increase request. Increase
      requests will be evaluated on an individual basis and are in Meteor
      Fantasy’s complete discretion. Please contact Meteor Fantasy Customer
      Support{' '}
      <ButtonWrapper
        isBare
        color={colors.accent}
        onClick={() => navigate('/contact')}>
        here
      </ButtonWrapper>{' '}
      to begin this process.
      <br />
      <br />
    </GeneralText>

    <Title>Play Limits</Title>
    <GeneralText isMedium>
      Meteor Fantasy also provides a number of tools designed to help you more
      actively manage your play.{' '}
      <ButtonWrapper
        isBare
        color={colors.accent}
        onClick={() => navigate('/contact')}>
        Click here
      </ButtonWrapper>{' '}
      to contact us if you need any of these limits or alerts applied to your
      account.
      <br />
      <br />
      ● Deposit Limits – You can set a monthly, weekly or daily deposit limit.
      <br />
      <br />
      ● Contest Entry Limits – You can establish limits on the number of
      contests you enter for a monthly, weekly, or daily time period.
      <br />
      <br />
      ● Contest Entry Alerts – You can establish a monthly, weekly, or daily
      alert that notifies you of the number of contests you’ve entered on Meteor
      Fantasy.
      <br />
      <br />● Contest Entry Fee Limits – You can establish limits on the
      contests you may enter based on the entry fee amounts of those contests
      over a monthly, weekly, or daily time period.
    </GeneralText>

    <Title>Third Party Exclusion Requests</Title>
    <GeneralText isMedium>
      As part of its responsible play efforts, Meteor Fantasy allows qualified
      third parties, who have concerns about a player’s ability to manage his or
      her play, to request a limitation on that player’s use of Meteor Fantasy.
      Limitations may include restriction of or exclusion from play.
      <br />
      <br />
      <b>Q: Who Can Make a Third Party Exclusion Request?</b>
      <br />
      <br />
      A: Third Party Exclusion or Play Restriction requests for a specific
      Meteor Fantasy user can be made by requestors who provide proof that:
      <br />
      <br />
      ● The requestor is jointly obligated on the credit or debit card
      associated with the Meteor Fantasy user’s account;
      <br />
      <br />
      ● The Meteor Fantasy user is legally dependent on the requestor under
      state or federal law;
      <br />
      <br />
      ● The requestor is wholly or partially obligated for the debts of the
      Meteor Fantasy user pursuant to a court order;
      <br />
      <br />
      ● or The Meteor Fantasy user is subject to a court order requiring him or
      her to pay unmet child support obligations.
      <br />
      <br />
      <b>
        Q: What restrictions or limitation options can be imposed on the Meteor
        Fantasy user?
      </b>
      <br />
      <br />
      A: At the request of a qualified third party, a Meteor Fantasy user may be
      excluded from play for a period of 3, 6, 9 or 12 months or have deposit
      limits applied to the Meteor Fantasy user’s account. Such limits would
      range from $1,000, $750, $500, $250, $100 or $50 per month, week or day.
      <br />
      <br />
      <b>Q: Will the user be notified of the third party exclusion request?</b>
      <br />
      <br />
      A: In the event we receive a request that satisfies the above criteria, we
      will attempt to notify the Meteor Fantasy user via email of such request.
      <br />
      <br />
      <b>
        Q: Will the user be able to challenge the request or show why it is not
        valid?
      </b>
      <br />
      <br />
      A: The user will have 5 business days from the date we send email
      notification of the request to respond. If the user does not respond
      within 5 business days or Meteor Fantasy deems, in its sole discretion,
      that the information is insufficient to refute the request, it will be
      honored and the exclusion or restriction shall be enforced.
      <br />
      <br />
      If you wish to request the exclusion of a Meteor Fantasy player please
      contact Customer Support.
    </GeneralText>

    <Title>Parental Controls</Title>
    <GeneralText isMedium>
      Meteor Fantasy verifies all accounts for age and eligibility. If minors
      under 18 have access to your computer and/or personal information, please
      consider installing parental control software to prevent access to Meteor
      Fantasy and other age-restricted sites. If you believe a minor is playing
      on Meteor Fantasy, please contact Customer Support{' '}
      <ButtonWrapper
        isBare
        color={colors.accent}
        onClick={() => navigate('/contact')}>
        here
      </ButtonWrapper>{' '}
      or call our toll-free number . If a minor is found playing on Meteor
      Fantasy, then that account will be closed.
    </GeneralText>

    <Title>Need additional help?</Title>
    <GeneralText isMedium>
      We consult with various organizations – such as the National Council for
      Problem Gambling (NCPG) and the National Center for Responsible Gaming -
      that cover a wide array of consumer protection issues, including problem
      play, to discuss our policies and ways in which they can remain aligned
      with the ever-evolving needs of our customer.
      <br />
      <br />
      If you have additional concerns about managing your play on Meteor Fantasy
      or gaming anywhere else - or if you’re concerned about a friend or family
      member – NCPG offers support and counseling referrals that may help you
      through personal, gaming-related issues. The NCPG operates a 24-hour
      confidential toll-free helpline that you can reach by phone or text at
      1-800-522-4700 or chat at{' '}
      <Link isLarge to="https://www.ncpgambling.org/help-treatment/chat">
        ncpgambling.org/chat
      </Link>
      .
    </GeneralText>
  </Wrapper>
);

ResponsiblePlay.defaultProps = {
  navigate: () => {},
};

ResponsiblePlay.propTypes = {
  navigate: PropTypes.func,
};

export default ResponsiblePlay;
