import React from 'react';
import PropTypes from 'prop-types';

import GeneralText from 'atoms/GeneralText';
import Icon from 'atoms/Icon';
import { ThumbsUpSolid, ThumbsDownSolid } from 'assets/icons';

const OverUnderPrediction = ({ selected, stats }) => {
  if (!stats) return null;
  return (
    <GeneralText isSmall>
      {selected && selected.charAt ? (
        <>
          {selected.charAt(0).toUpperCase() + selected.slice(1)}
          &nbsp;
          {selected === 'over' && (
            <Icon size={10}>
              <ThumbsUpSolid />
            </Icon>
          )}
          {selected === 'under' && (
            <Icon size={10}>
              <ThumbsDownSolid />
            </Icon>
          )}
          &nbsp;
          <b>{stats.value}</b>
        </>
      ) : (
        <b>{stats.value} (Projection)</b>
      )}
    </GeneralText>
  );
};

OverUnderPrediction.propTypes = {
  selected: PropTypes.any,
  stats: PropTypes.object,
};

export default OverUnderPrediction;
