import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { GeneralText, Group } from 'atoms';

const InputLabelWrapper = ({ color, title, children, ...rest }) => (
  <Group isFullWidth isVertical spaceSize={5} {...rest}>
    <GeneralText color={color} style={{ whiteSpace: 'nowrap' }}>
      <b>{title}</b>
    </GeneralText>
    {children}
  </Group>
);

InputLabelWrapper.defaultProps = {
  color: colors.primary,
};

InputLabelWrapper.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default InputLabelWrapper;
