export { default as Account } from './Account';
export { default as About } from './About';
export { default as Contact } from './Contact';
export { default as HowToPlay } from './HowToPlay';
export { default as PlayerProfile } from './PlayerProfile';
export { default as PrivacyPolicy } from './PrivacyPolicy';
export { default as RefundPolicy } from './RefundPolicy';
export { default as ResponsiblePlay } from './ResponsiblePlay';
export { default as Splash } from './Splash';
export { default as Terms } from './Terms';
