import React, { useState } from 'react';

import { object, string } from 'yup';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import {
  Button,
  FormContainer,
  GeneralText,
  Group,
  Icon,
  Input,
  Title,
  Space,
} from 'atoms';

import { MeteorFantasyLogo } from 'molecules';

import { Fill } from 'atoms/Group';
import Alert from 'molecules/Alert';
import { Envelope, LockAlt } from 'assets/icons';

import { auth } from 'services/firebase';

import { returnTo } from 'services/utilities';

import useAnalytics from 'hooks/useAnalytics';

const validationSchema = object().shape({
  email: string().email('E-mail is not valid'),
  password: string().min(6, 'Password needs at least 6 characters'),
});

const SignIn = () => {
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { logEvent } = useAnalytics();

  return (
    <Group
      isVertical
      isFullWidth
      isCentered
      isVerticalCentered
      style={{ margin: 'auto', maxWidth: 415 }}>
      <Group isVertical isFullWidth isCentered spaceSize={20} padding={30}>
        <Space isLarge isVertical />
        <MeteorFantasyLogo size={180} />
        <Title color={colors.white}>Sign In</Title>

        <FormContainer
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={({ email, password }) => {
            setIsLoading(true);
            auth()
              .signInWithEmailAndPassword(email, password)
              .then(() => {
                logEvent('login');
                navigate(`/${returnTo() || 'gametype'}`);
              })
              .catch(() => {
                setIsLoading(false);
                setFeedback(
                  'Your username or password is incorrect, please try again!'
                );
              });
          }}>
          {({ values, isValid, handleChange, handleSubmit }) => {
            const { password, email } = values || {};
            const isEmpty = !(password && email);
            const allowSubmit = isValid && !isEmpty;
            return (
              <Group isFullWidth isVertical spaceSize={20} isCentered>
                <Input
                  specSelector="signin-input-email"
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <Envelope />
                    </Icon>
                  }
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <Input
                  specSelector="signin-input-password"
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <LockAlt />
                    </Icon>
                  }
                  name="password"
                  type="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="Password"
                />
                <Button
                  specSelector="signin-submit-button"
                  disabled={!allowSubmit}
                  type="submit"
                  isFullWidth
                  onClick={handleSubmit}>
                  ENTER
                </Button>
              </Group>
            );
          }}
        </FormContainer>

        <Group isFullWidth>
          <Button isBare onClick={() => navigate('/auth/password-recovery')}>
            <GeneralText color={colors.white}>Forgot password</GeneralText>
          </Button>
          <Fill />
          <Button isBare onClick={() => navigate('/auth/signup')}>
            <GeneralText color={colors.accent}>Create account</GeneralText>
          </Button>
        </Group>
      </Group>

      <Group isCentered isFullWidth>
        <Button isBare onClick={() => navigate('/')}>
          <GeneralText color={colors.mattedGray}>
            GO BACK TO THE GAME!
          </GeneralText>
        </Button>
      </Group>

      <Space isVertical isLarge />

      <Fill />

      <Alert
        isOpen={!!feedback}
        disableClose
        title="Something happened"
        cancelButtonLabel="Sign up"
        proceedButtonLabel={'Try again'}
        onClickToCancel={() => navigate('/auth/signup')}
        onClickToProceed={() => setFeedback('')}>
        <GeneralText>{feedback}</GeneralText>
      </Alert>

      <Alert
        isOpen={isLoading}
        disableClose
        title="Entering"
        hideCancelButton
        hideProceedButton>
        <GeneralText>Please wait...</GeneralText>
      </Alert>
    </Group>
  );
};

SignIn.propTypes = {};

export default SignIn;
