import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import Group from 'atoms/Group';

import Badge from 'atoms/Badge';
import GeneralText from 'atoms/GeneralText';
import Icon from 'atoms/Icon';
import { Plus, Minus } from 'assets/icons';

import colors from 'assets/themes/colors';
import AnimatedCheckmark from './AnimatedCheckmark';
import { isRealNumber } from 'services/utilities';

const Button = styled.button`
  cursor: pointer;
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 1px solid;
  border-color: ${colors.primary};
  background-color: white;
  transition: all 0.2s ease-out;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  padding-top: 3px;
  box-shadow: 0px 0px 2px ${colors.tertiary};
`;

// eslint-disable-next-line react/prop-types
const AnimatedTouch = ({ children, onClick, specSelector, ...rest }) => {
  return (
    <Button
      data-spec-selector={specSelector}
      onClick={() => onClick()}
      {...rest}>
      {children}
    </Button>
  );
};

const StatpicksMechanics = ({
  stats,
  onChange,
  onClickValue,
  updatedValue,
}) => {
  const [value, updateValue] = useState(null);

  useEffect(() => {
    if (isRealNumber(updatedValue * 1) && updatedValue !== value) {
      updateValues(updatedValue);
    }
  }, [updatedValue]);

  const handleChanges = action => {
    let newValue = value;
    if (action === 'add') newValue = value + 1;
    if (action === 'subtract' && value > 0) newValue = value - 1;
    updateValues(newValue);
  };

  function updateValues(valueToUse) {
    updateValue(valueToUse * 1);
    onChange('statpicks', valueToUse * 1);
  }

  const checkmark = position => {
    const style = {
      left: {
        position: 'absolute',
        top: -35,
        left: -35,
      },
      right: {
        position: 'absolute',
        top: -35,
        right: -30,
      },
    };
    return <AnimatedCheckmark style={style[position]} />;
  };

  const hasValue = typeof (value * 1) == 'number' && value != null;

  return (
    <Group
      isCentered
      isVerticalCentered
      spaceSize={20}
      padding={15}
      isFullWidth
      data-guided-tour="statpicks-mechanics"
      style={{
        backgroundColor: colors.lightGray,
        marginTop: 10,
        marginBottom: 5,
        borderRadius: 4,
        border: `1px solid ${colors.secondary}`,
        // height: 60
      }}>
      <AnimatedTouch
        specSelector="gameplay-prediction-subtract"
        onClick={() => handleChanges('subtract')}>
        <Icon size={24}>
          <Minus />
        </Icon>
      </AnimatedTouch>

      {/* TODO: Update this with Button.isBare */}
      <Group
        isCentered
        isVertical
        // isVerticalCentered
        specSelector="gameplay-prediction-input"
        onClick={onClickValue}
        style={{
          minWidth: 100,
          height: 55,
          position: 'relative',
        }}>
        {hasValue ? (
          <GeneralText
            isLarge
            style={{
              fontSize: 32,
              height: 37,
            }}>
            {checkmark('right')}
            <b>{value}</b>
          </GeneralText>
        ) : (
          <div style={{ height: 37, paddingTop: 8 }}>
            <Badge>
              <b>ENTER PREDICTION</b>
            </Badge>
          </div>
        )}
        <GeneralText isSmall style={{ marginTop: -4 }}>
          <b>{stats.label}</b>
        </GeneralText>
      </Group>

      <AnimatedTouch
        specSelector="gameplay-prediction-add"
        onClick={() => handleChanges('add')}>
        <Icon color={colors.primary} size={24}>
          <Plus />
        </Icon>
      </AnimatedTouch>
    </Group>
  );
};

StatpicksMechanics.defaultProps = {
  stats: {
    label: '----',
    value: 0,
  },
  onChange: () => {},
  onClickValue: () => {},
};

StatpicksMechanics.propTypes = {
  stats: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  onChange: PropTypes.func,
  onClickValue: PropTypes.func,
  updatedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.number,
};

export default StatpicksMechanics;
