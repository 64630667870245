import React from 'react';

import { About as AboutPage } from 'pages';
import Wrapper from './Wrapper';

const About = () => (
  <Wrapper title="About Us">
    <AboutPage />
  </Wrapper>
);

export default About;
