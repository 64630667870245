import React from 'react';
import PropTypes from 'prop-types';

import { GeneralText, Icon, Group } from 'atoms';
import { DollarSign } from 'assets/icons';

const Payout = ({ payout }) => (
  <Group isCentered isVerticalCentered isGrown spaceSize={5}>
    <Group isVertical isCentered>
      <GeneralText isMedium>
        <strong>$ {payout}</strong>
      </GeneralText>
      <GeneralText color="black">Payout</GeneralText>
    </Group>
    <Icon size={34}>
      <DollarSign />
    </Icon>
  </Group>
);

Payout.propTypes = {
  payout: PropTypes.number.isRequired,
};

export default Payout;
