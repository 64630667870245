import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Card } from 'rebass';

const TopBar = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 15px;
  flex-direction: row;
  justify-content: ${({ isCentered }) =>
    isCentered ? 'center' : 'space-between'};
  align-items: center;
  background-color: ${colors.primary};
`;

const CardWrapper = ({ specSelector, children, topLabel, sx, ...rest }) => {
  const hasMoreThanOneChildren =
    topLabel &&
    topLabel.props &&
    React.Children.toArray(topLabel.props.children).length > 1;

  return (
    <Card
      data-spec-selector={specSelector}
      variant="card.bare"
      width={'100%'}
      sx={{ ...sx, position: 'relative', overflow: 'hidden' }}
      {...rest}>
      {topLabel && (
        <TopBar isCentered={!hasMoreThanOneChildren}>{topLabel}</TopBar>
      )}
      {children}
    </Card>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  topLabel: PropTypes.any,
  specSelector: PropTypes.string,
  sx: PropTypes.object,
};

export default CardWrapper;
