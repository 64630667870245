import React from 'react';
import PropTypes from 'prop-types';
import colors from 'assets/themes/colors';
import CompetitionBlockTeam from 'molecules/CompetitionBlockTeam';
import CompetitionVersus from 'molecules/CompetitionVersus';
import { Fill } from 'atoms/Group';
import { Group, Space } from 'atoms';

const CompetitionBlock = ({
  selectedTeam,
  competitorTeam,
  homeTeamName,
  startTime,
  date,
}) => (
  <Group
    data-guided-tour="selected-team-lineup"
    isFullWidth
    isCentered
    isVerticalCentered
    spaceSize={0}
    padding={'15px 0'}
    style={{
      backgroundColor: colors.white,
    }}>
    <Fill />
    <CompetitionBlockTeam
      isHomeTeam={homeTeamName === selectedTeam.name}
      teamName={selectedTeam.name}
      source={selectedTeam.logo}
      alt={`${selectedTeam.name} logo`}
      isSelected
    />
    <Space isMedium />
    <CompetitionVersus
      style={{ marginTop: '-10px' }}
      homeTeam={homeTeamName}
      startTime={startTime}
      date={date}
    />
    <Space isMedium />
    <CompetitionBlockTeam
      isHomeTeam={homeTeamName !== selectedTeam.name}
      teamName={competitorTeam.name}
      source={competitorTeam.logo}
      alt={`${competitorTeam.name} logo`}
    />
    <Fill />
  </Group>
);

CompetitionBlock.propTypes = {
  selectedTeam: PropTypes.exact({
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
  competitorTeam: PropTypes.exact({
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
  homeTeamName: PropTypes.string,
  startTime: PropTypes.string,
  date: PropTypes.string,
  isHomeTeam: PropTypes.bool,
};

export default CompetitionBlock;
