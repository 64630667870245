import React from 'react';
import PropTypes from 'prop-types';

import { GeneralText, Group } from 'atoms';

const NumEntriesVsMaxEntries = ({ activeParticipants, totalParticipants }) => (
  <Group
    data-guided-tour="participants-vs-max-entries"
    isVertical
    isCentered
    isGrown>
    <GeneralText isMedium>
      <strong>
        {activeParticipants} of {totalParticipants}
      </strong>
    </GeneralText>
    <GeneralText color="black">
      Participant{totalParticipants > 1 ? 's' : null}
    </GeneralText>
  </Group>
);

NumEntriesVsMaxEntries.defaultProps = {
  activeParticipants: 0,
  totalParticipants: 0,
};

NumEntriesVsMaxEntries.propTypes = {
  activeParticipants: PropTypes.number.isRequired,
  totalParticipants: PropTypes.number.isRequired,
};

export default NumEntriesVsMaxEntries;
