const INITIAL_STATE = {
  type: null,
  modality: null,
  sport: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case 'SELECT_GAME_TYPE':
      return (state = { ...state, ...action.payload });

    case 'SELECT_SPORT':
      return (state = { ...state, ...action.payload });

    case 'RESET_GAME':
      return (state = INITIAL_STATE);

    default:
      return state;
  }
};
