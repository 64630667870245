import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { InfoCircle } from 'assets/icons';
import { GeneralText, Group, Icon, Space } from 'atoms';

const ErrorMessage = ({ message, touched }) => (
  <>
    {message && touched && (
      <GeneralText
        color={colors.alert}
        isSmall
        style={{
          marginTop: '-12px',
          marginLeft: '5px',
        }}>
        <Group isCentered>
          <Icon
            color={colors.alert}
            size={12}
            style={{
              zIndex: 1,
            }}>
            <InfoCircle />
          </Icon>
          <Space />
          {message}
        </Group>
      </GeneralText>
    )}
  </>
);

ErrorMessage.propTypes = {
  message: PropTypes.object,
  touched: PropTypes.object,
};

export default ErrorMessage;
