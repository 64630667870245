/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { GeneralText, Group, Link } from 'atoms';

const Wrapper = styled(Group)`
  background-color: ${colors.white};
  min-height: 100%;
`;

const About = () => (
  <Wrapper isVertical spaceSize={20} padding={20}>
    <GeneralText isMedium>
      The team at Meteor Fantasy is not new to the world of sports. With our
      over a decade of experience in the sports world, we wanted to take our
      experience to the next level bringing sports fans another way to get
      closer to the events, athletes and fandom.
      <br />
      <br />
      Our goal with Meteor Fantasy is to take out the complexities of Fantasy
      Sports and bring it back to the basics. No diving into player stats, no
      choosing lineups, just straight to the point. This type of game play is be
      appealing to both long-term Daily Fantasy Sports enthusiasts, as well as
      new comers, wanting to dive-in and play just like their friends and family
      had been telling them.
      <br />
      <br />
      Daily Fantasy Sports has become far too complex and overwhelming to
      players, especially individuals who would like to start playing. We
      provide a simple and easy to understand process from entering a contest to
      making informed decisions giving you only the necessary athlete's stats
      you need to enter a contest.
      <br />
      <br />
      Enter a contest, watch the games, and repeat.
      <br />
      <br />
      Have more questions? Contact us at{' '}
      <Link isMedium to="mailto:help@meteorfantasy.com">
        help@meteorfantasy.com
      </Link>{' '}
    </GeneralText>
  </Wrapper>
);

export default About;
