import React from 'react';

export default {
  name: 'gamePlayTour',
  steps: [
    {
      target: '[data-guided-tour="entry-fee-selection"]',
      content: (
        <>
          Shown are the number of current entries with the total maximum
          entries. Note: Free contests always run when not full.
          <br />
          <br />
          <small>
            For free contests, Top 25 means that the 25% of participants win.
            Our free contests pay M$0.25, our very own Meteor Dollars. Meteor
            Dollars can be used to enter our Paid Contests and win real money!
            <br />
            (Paid contests coming soon.)
          </small>
          <br />
          <br />
          <small>
            Currently we have free contests, but when we add paid contests, you
            can select the desired entry fees here: $5, $10, etc.
          </small>
        </>
      ),
      placement: 'auto',
    },
    {
      target: '[data-guided-tour="overunder-mechanics"]',
      content:
        'You will be shown a stat. Just click on the thumbs up (over) or thumbs down (under) if you predict that the player will go over or under the provided stat.',
      placement: 'auto',
    },
    {
      target: '[data-guided-tour="statpicks-mechanics"]',
      content:
        'For a provided stat, input your prediction by clicking on the --- at the bottom of the player card. Using the + and - will increase or decrease your input.',
      placement: 'top-center',
    },
  ],
};
