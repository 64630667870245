export const toggleGameType = {
  overunder: 'statpicks',
  statpicks: 'overunder',
};

export const findEmptyPrediction = (predictions, competitors, callback) => {
  let nextEmptyPrediction = null;
  Object.keys(predictions).forEach(playerId => {
    if (predictions[playerId] === null && nextEmptyPrediction === null) {
      nextEmptyPrediction = [...competitors].filter(p => p.id == playerId)[0];
    }
  });
  if (nextEmptyPrediction) callback(nextEmptyPrediction);
};
