import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'atoms';
import styled from '@emotion/styled';

import { Badge, Button, Icon, ImageWrapper } from 'atoms';
import { Plus } from 'assets/icons';

import colors from 'assets/themes/colors';

const Wrapper = styled.div`
  width: auto;
  display: inline-block;
  position: relative;
  flex-shrink: 0;
`;

const PlayerAvatar = ({
  flagLabel,
  hasInfo,
  onClick,
  source,
  style,
  teamLogo,
  ...rest
}) => {
  const hasSmallSize = rest.isMedium || rest.isSmall;

  const badgeStyles = {
    position: 'absolute',
    left: '50%',
    bottom: hasSmallSize ? 8 : -6,
    transform: 'translateX(-50%)',
  };

  const infoStyles = {
    position: 'absolute',
    right: -2,
    top: 10,
    border: '2px solid white',
  };

  const badgeLabel = flagLabel.toUpperCase();

  return (
    <Wrapper onClick={onClick} style={style}>
      <Avatar source={source} {...rest} />

      {teamLogo && (
        <ImageWrapper
          source={teamLogo}
          size={36}
          style={{
            position: 'absolute',
            top: 70,
            left: -10,
          }}
        />
      )}

      {flagLabel && (
        <Badge
          isSmall={true}
          style={badgeStyles}
          isDanger={badgeLabel == 'BACKUP'}>
          {badgeLabel}
        </Badge>
      )}

      {hasInfo && !rest.isMedium && !rest.isSmall && (
        <Button style={infoStyles} isRounded size={30}>
          <Icon size={22} color={colors.white}>
            <Plus />
          </Icon>
        </Button>
      )}
    </Wrapper>
  );
};

PlayerAvatar.defaultProps = {
  flagLabel: '',
};

PlayerAvatar.propTypes = {
  flagLabel: PropTypes.string,
  hasInfo: PropTypes.bool,
  isMedium: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  onClick: PropTypes.func,
  source: PropTypes.string,
  style: PropTypes.object,
  teamLogo: PropTypes.string,
};

export default PlayerAvatar;
