import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { toArrayWithIds } from './utilities';

const db = firebase.firestore;

export default {
  competitionLineups: (filter, callback) =>
    new Promise((resolve, reject) => {
      const { sport } = filter || {};
      db()
        .collection(process.env.firestoreCompetitionsLineups)
        .where('timeBlock.status', '==', 2)
        .where('sport', '==', String(sport || '').toUpperCase())
        .onSnapshot(snapshot => {
          return (callback || resolve)(toArrayWithIds(snapshot));
        }, reject);
    }),
  entryFeesInstances: ({ timeBlockId, gameType, modality }, callback) =>
    new Promise((resolve, reject) => {
      if (!timeBlockId || !gameType || !modality) reject();
      const operator = modality === 'free' ? '==' : '>';
      db()
        .collection(process.env.firestoreEntryFessInstances)
        .where('timeBlockId', '==', timeBlockId)
        .where('gameType', '==', gameType)
        .where('entryFee', operator, 0)
        .onSnapshot(
          snapshot => (callback || resolve)(toArrayWithIds(snapshot)),
          reject
        );
    }),
};
