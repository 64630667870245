/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { GeneralText, Group } from 'atoms';

const Wrapper = styled(Group)`
  background-color: ${colors.white};
  min-height: 100%;
`;

const RefundPolicy = () => (
  <Wrapper isVertical spaceSize={20} padding={20}>
    <GeneralText isMedium>
      Users may cancel entries up to 30 minutes after submitting the entry.
      Cancellation is not allowed if there are less than 90 minutes between the
      submission and the start of the earliest scheduled competition within that
      contest group (early, afternoon, or late). If you timely cancel an entry,
      your entry fee will be refunded back into your Meteor Fantasy account.
      Meteor Fantasy has no obligation to honor cancellation requests received
      30 minutes after submitting the entry or after the game starts. Similarly,
      contests lock 15 minutes before the earliest scheduled competition within
      that contest group (early, afternoon, or late), and no more entries or
      prediction changes may be made at that time.
      <br />
      <br />
      Deposits made on our site will appear on your statement as METEORFANTASY.{' '}
      <b>All payments are final and no refunds will be issued.</b> In the event
      of a dispute regarding the identity of the person submitting an entry, the
      entry will be deemed submitted by the person in whose name the account was
      registered.
      <br />
      <br />
      You may withdraw prize winnings and deposits (excluding bonuses, free
      credits and promotions, subject to the terms of said bonuses) from your
      Account Balances subject to the following terms. Any amount deposited can
      be withdrawn in full, with a minimum of $20 USD. Meteor Fantasy cannot,
      per US Anti Money Laundering Laws, facilitate a withdrawal for an amount
      equal to the deposit made until such time as a one (1) paid contest entry
      has been submitted and settled.
    </GeneralText>
  </Wrapper>
);

export default RefundPolicy;
