import React from 'react';
import PropTypes from 'prop-types';

import { GeneralText } from 'atoms';

const Description = ({ children }) => {
  return (
    <GeneralText isMedium>
      <b>{children}</b>
    </GeneralText>
  );
};

Description.propTypes = {
  children: PropTypes.any,
};

export default Description;
