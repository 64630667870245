import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { largeFontSize, regularFontSize, smallFontSize } from 'assets/settings';

import colors from 'assets/themes/colors';

import GeneralText from 'atoms/GeneralText';

// TODO: Update this component to be nice with light and dark backgrounds

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const Container = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  position: relative;
`;

const Token = styled.span`
  border-radius: 2px;
  border: 2px solid ${colors.tertiary};
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  display: flex;
  padding: 1px;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  ~ ${Token} {
    /* background-color: transparent; */
    path {
      fill: transparent;
    }
  }
  &:checked ~ ${Token} {
    /* background-color: ${colors.primary}; */
    path {
      fill: ${colors.white};
    }
  }
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 4px;
`;

const LoadingContainer = styled.span`
  margin-left: 4px;
`;

const Checkbox = ({
  isSmall,
  isMedium,
  isLarge,
  label,
  isLoading,
  onChange,
  specSelector,
  ...rest
}) => {
  let size = regularFontSize;

  if (isSmall) size = smallFontSize;
  if (isMedium) size = regularFontSize;
  if (isLarge) size = largeFontSize;

  return (
    <Wrapper>
      <Container>
        <Input
          data-spec-selector={specSelector}
          type="checkbox"
          onChange={onChange}
          {...rest}
        />
        {label && (
          <Label>
            <GeneralText
              isSmall={isSmall}
              isMedium={isMedium}
              isLarge={isLarge}
              color={colors.gray2}>
              {label}
            </GeneralText>
            {isLoading && <LoadingContainer></LoadingContainer>}
          </Label>
        )}
        <Token size={size}>
          <svg
            style={{ marginTop: 0, width: size, height: size }}
            viewBox="0 0 32 32">
            <path d="M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z" />
          </svg>
        </Token>
      </Container>
    </Wrapper>
  );
};

Checkbox.defaultProps = {
  label: null,
  isLoading: false,
};

Checkbox.propTypes = {
  isLarge: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  specSelector: PropTypes.string,
};

export default Checkbox;
