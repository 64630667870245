import React from 'react';

import useModal from 'hooks/useModal';

import ContentResend from './components/ContentResend';

const callEmailVerification = () => {
  const [Modal, openModal] = useModal();

  const resend = () => {
    openModal();
  };

  const Render = () => {
    return (
      <Modal customHeader={() => null} isCovering={false}>
        <ContentResend />
      </Modal>
    );
  };

  return [Render, resend];
};

export default callEmailVerification;
