import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Container from './Container';

import LoadingScreen from '../';

const ContainerWithLoading = ({ children, isHidden, onHide }) => {
  const [isLoaded, setLoaded] = useState(false);

  return (
    <Container {...{ isLoaded: isHidden }}>
      {!isLoaded && (
        <LoadingScreen
          isHidden={isHidden}
          onHide={() => {
            if (onHide && typeof onHide === 'function') onHide();
            setLoaded(true);
          }}
        />
      )}
      {children}
    </Container>
  );
};

ContainerWithLoading.propTypes = {
  children: PropTypes.any,
  isHidden: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ContainerWithLoading;
