import React from 'react';
import PropTypes from 'prop-types';

import { Group, Checkbox, GeneralText } from 'atoms';

import colors from 'assets/themes/colors';

const RefundsPolicy = ({ onChange }) => {
  return (
    <>
      <Group isVertical isCentered spaceSize={10}>
        <GeneralText>
          <b>Refunds Policy</b>
        </GeneralText>
        <GeneralText
          isSmall
          color={colors.tertiary}
          style={{ textAlign: 'justify' }}>
          Users may cancel entries up to 15 minutes prior to game start. If you
          timely cancel an entry, your entry fee will be refunded back into your
          Meteor Fantasy account. Meteor Fantasy has no obligation to honor
          cancellation requests received within 15 minutes of or after game
          start. Similarly, contests lock when the first game starts and no more
          entries or lineup changes may be made at that time. Deposits made on
          our site will appear on your statement as METEORFANTASY. All payments
          are final and no refunds will be issued. In the event of a dispute
          regarding the identity of the person submitting an entry, the entry
          will be deemed submitted by the person in whose name the account was
          registered. You may withdraw prize winnings and deposits (excluding
          bonuses, free credits and promotions, subject to the terms of said
          bonuses) from your Account Balances subject to the following terms.
          Any amount deposited can be withdrawn in full, with a minimum of $20
          USD. Meteor Fantasy cannot, per US Anti Money Laundering Laws,
          facilitate a withdrawal for an amount equal to the deposit made until
          such time as a one (1) paid contest entry has been submitted and
          settled.
        </GeneralText>
        <Checkbox
          label="Click here to accept our refund policy"
          onChange={onChange}
        />
      </Group>
    </>
  );
};

RefundsPolicy.propTypes = {
  onChange: PropTypes.func,
};

export default RefundsPolicy;
