import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'submit',
  storage,
  blacklist: ['isSubmitting', 'error', 'submitStatus'],
  stateReconciler: autoMergeLevel2,
};

const INITIAL_STATE = {
  submitStatus: null, // 'submitting/sent/error'
  isSubmitting: null,
  error: null,
};

export default persistReducer(
  persistConfig,
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case 'SUBMIT_CONTEST':
        return (state = {
          ...state,
          submitStatus:
            state.submitStatus !== 'sent' ? 'submitting' : state.submitStatus,
          isSubmitting: true,
          error: null,
        });

      case 'SUBMIT_CONTEST_SUCCESS':
        return (state = {
          ...state,
          submitStatus: 'sent',
          isSubmitting: false,
          error: null,
        });

      case 'SUBMIT_CONTEST_ERROR':
        return (state = {
          ...state,
          submitStatus: 'error',
          isSubmitting: false,
          error: action.payload,
        });

      case 'RESET_SUBMIT_STATUS':
        return (state = INITIAL_STATE);

      default:
        return state;
    }
  }
);
