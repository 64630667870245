import Dexie from 'dexie';

const db = new Dexie('mfCachedData');

db.version(1).stores({
  draftContest:
    '++id, timestamp, sport, gameType, timeBlock, lineups, entryFee, predictions',
});

export default db;
