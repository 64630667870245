/* eslint-disable no-unused-vars */
// TODO remove disable eslint rule when #238 and #239 are done
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import colors from 'assets/themes/colors';
import { GeneralText, Group, Button } from 'atoms';
import { StatsRow } from 'molecules';
import { ResultsCard, ModalHeader } from 'organisms';
// import ModalHeader from 'organisms/ModalHeader';

import useUserData from 'hooks/useUserData';
import useUserResults from './hooks/useUserResults';

import { navigate } from '@reach/router';

const AccountResults = () => {
  //TODO: remove disable lint rule when #238 and #239 are done
  // eslint-disable-next-line no-unused-vars
  const [
    {
      numEntriesWon,
      finishedEntries,
      currentEntries,
      promoCash,
      isAnonymous,
      status,
      uid,
    },
  ] = useUserData();

  const [userResults, { isReady }] = useUserResults();

  //sort by timeblock week descending to show latest week results at top
  userResults &&
    userResults.sort((a, b) => b.timeBlock.week - a.timeBlock.week);

  const numEntriesLost = finishedEntries - numEntriesWon;
  const numCurrentEntries = (currentEntries && currentEntries.length) || 0;
  const hasEntries = numCurrentEntries > 0 || finishedEntries > 0;

  const accountStats = [
    { label: 'WINS', value: numEntriesWon || 0 },
    { label: 'LOSSES', value: numEntriesLost },
    {
      label: 'Total Winnings',
      value: `${promoCash ? `M$${promoCash.toFixed(2)}` : 'M$0.00'}`,
    },
  ];

  const pathname = location.pathname.replace('/', '');

  const [resultToShow, selectResultToShow] = useState();

  useEffect(() => {
    if (status === 'success' && isAnonymous)
      navigate(`/auth${pathname ? `?returnTo=${pathname}` : ''}`);
  }, [status, isAnonymous]);

  if (!isReady) return 'Loading...';

  return (
    <>
      <StatsRow stats={accountStats} />

      <Group isCentered>
        <GeneralText color={colors.black}>
          {userResults && userResults.length > 0 ? (
            'The contest has ended and here are your results:'
          ) : (
            <Group isCentered isVertical spaceSize={5}>
              <GeneralText>You have no results to show yet</GeneralText>
              <Button
                isSmall
                onClick={() => {
                  navigate('/teams');
                }}>
                {hasEntries ? 'KEEP PLAYING!' : "LET'S PLAY"}
              </Button>
            </Group>
          )}
        </GeneralText>
      </Group>

      <Group isVertical isCentered padding={15} spaceSize={20}>
        {/* TODO: This is messy but will be refactored after issue #243 (admin) is completed  so leaving as is*/}
        {userResults &&
          userResults.map(result => (
            <ResultsCard
              result={result}
              uid={uid}
              key={result.entryFeeInstance.id}
            />
          ))}
      </Group>
    </>
  );
};

AccountResults.defaultProps = {};

AccountResults.propTypes = {};

export default AccountResults;
