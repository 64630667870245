//TODO: Remove next line after unhiding credit card and add funds
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { navigate } from '@reach/router';

import { callAddFunds, useAlert, useModal, useUserData } from 'hooks';
import { Group } from 'atoms';
import { StatsRow } from 'molecules';
import { CreditCard } from 'organisms';

import AddFundsButton from './components/AddFundsButton';
import CardHeader from './components/CardHeader';
import CreditCardCardContent from './components/CreditCardCardContent';
import Profile from './components/Profile';

import { users } from 'services/firebase';

const AccountOverview = () => {
  const [AddFunds, toggleAddFunds] = callAddFunds();
  const [AddCreditCard, toggleAddCreditCard] = useModal();
  const [cardToBeDeleted, setCardToBeDeleted] = useState();
  const [ConfirmCreditCardDelete, toggleConfirmCreditCardDelete] = useAlert();
  const [isEditModeProfile, setIsEditModeProfile] = useState(false);

  const [userData] = useUserData();
  const {
    // maskedCreditCard, TODO: enable after launch
    createdAt,
    isReady,
    level,
    isAnonymous,
    status,
    finishedEntries,
    // uid, TODO: enable after launch
  } = userData;

  const pathname = location.pathname.replace('/', '');

  useEffect(() => {
    if (status === 'success' && isAnonymous)
      navigate(`/auth${pathname ? `?returnTo=${pathname}` : ''}`);
  }, [status, isAnonymous]);

  if (!isReady) return `Loading...`;

  //TODO: enable after launch
  // useEffect(() => {
  //   users.set(uid, { maskedCreditCard: cardToBeDeleted });
  // }, [cardToBeDeleted]);

  function handleCreditCardDeletion() {
    setCardToBeDeleted(null);
  }

  const userStats = [
    {
      label: 'Contests Played',
      value: `${finishedEntries ? finishedEntries : 0}`,
    },
    {
      label: 'EXPERIENCE LEVEL',
      value: level > 0 ? 'Experienced' : 'Beginner',
    },
    {
      label: 'MEMBER SINCE',
      value: `${moment.utc(Number(createdAt)).format('MMM Do, YYYY')}`,
    },
  ];

  // const contactInfo = [
  //   { label: 'Display Name', value: displayName ? displayName : `NONE` },
  //   {
  //     label: 'Phone',
  //     value: phoneNumber ? phoneMask.resolve(phoneNumber) : `NONE`,
  //   },
  //   { label: 'Email', value: email },
  // ];

  return (
    <>
      <StatsRow stats={userStats} spaceSize={0} />

      <Group isVertical isCentered padding={20} spaceSize={20}>
        <CardHeader
          title={'Profile'}
          onEdit={setIsEditModeProfile}
          isEditMode={isEditModeProfile}>
          <Profile isEdit={isEditModeProfile} onSubmit={setIsEditModeProfile} />
        </CardHeader>

        {/* TODO: Hide paid functionality -- see #271 */}
        {/*
        <CardHeader title={'MY CREDIT CARD'} onEdit={toggleAddCreditCard}>
          <CreditCardCardContent
            maskedCreditCard={maskedCreditCard}
            setCardToBeDeleted={setCardToBeDeleted}
            toggleConfirmCreditCardDelete={toggleConfirmCreditCardDelete}
            toggleAddCreditCard={toggleAddCreditCard}
          />
        </CardHeader>

        <AddFundsButton onClick={toggleAddFunds} />

        <AddFunds /> */}
      </Group>

      {/* TODO: Hide paid functionality -- see #271 */}
      {/*
      <ConfirmCreditCardDelete onclickToProceed={handleCreditCardDeletion}>
        Are you sure you want to delete the card on file:{' '}
        {` ${maskedCreditCard}`}?
      </ConfirmCreditCardDelete>

      <AddCreditCard isSecondary customHeader={() => null} isCovering={false}>
        <CreditCard onClick={toggleAddCreditCard} />
      </AddCreditCard> */}
    </>
  );
};

AccountOverview.defaultProps = {};

AccountOverview.propTypes = {};

export default AccountOverview;
