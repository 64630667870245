import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Button, GeneralText, Group } from 'atoms';

const TabsNav = ({ routes }) => (
  <nav>
    <Group isCentered style={{ backgroundColor: colors.white }}>
      {routes.map(({ route, name, isActive, onClick }) => (
        <Group
          isGrown
          isCentered
          isFullWidth
          key={route}
          css={{
            borderBottom: `2px solid ${
              isActive ? colors.accent : 'transparent'
            }`,
            '& *': {
              color: `${isActive ? colors.accent : colors.primary}`,
            },
          }}>
          <Button
            isBare
            onClick={() => onClick({ route, name, isActive })}
            style={{ width: '100%', height: '100%', padding: '10px' }}>
            <GeneralText isMedium>
              <b>{name}</b>
            </GeneralText>
          </Button>
        </Group>
      ))}
    </Group>
  </nav>
);

TabsNav.defaultProps = {
  // onClick: () => { },
};

TabsNav.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.exact({
      route: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default TabsNav;
