import React from 'react';

import { Account as AccountPage } from 'pages';
import Wrapper from './Wrapper';

const Account = () => (
  <Wrapper>
    <AccountPage />
  </Wrapper>
);

export default Account;
