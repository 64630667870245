export default function(key) {
  const names = {
    overunder: 'Over/Under',
    statpicks: 'Statpicks',
  };
  return names[key];
}

export const positions = {
  // NFL
  nfl: {
    QB: 'Quarterback',
    RB: 'Running Back',
    WR: 'Wide Receiver',
    TE: 'Tight End',
    K: 'Kicker',
    OLB: 'Linebacker',
    ILB: 'Linebacker',
    DE: 'Defensive End',
  },

  // NBA
  nba: {
    PF: 'Power Forward',
    C: 'Center',
    SF: 'Small Forward',
    PG: 'Point Guard',
    SG: 'Shooting Guard',
  },
};
