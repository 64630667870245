import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ProgressBar, Space } from 'atoms';
import CompetitionBlock from 'organisms/CompetitionBlock';
import CardsSwiperStatpicks from 'organisms/CardsSwiperStatpicks';

const Statpicks = ({
  competitors,
  predictions,
  currentPlayer,
  onSelectPrediction,
  onChangeCard,
  onClickLastCard,
}) => {
  const players = [...competitors].map(item => {
    return {
      ...item,
      cardLabel: 'Upcoming Game Projections',
      cardDescription: `Can you predict ${item.firstName}'s performance?`,
      onClickPlayerInfo: () => {},
    };
  });

  useEffect(() => {
    onChangeCard(players[0]);
  }, []);

  let currentIndex = null;
  players.forEach((item, index) => {
    if (currentPlayer.id === item.id) currentIndex = index;
  });

  const validPredictionsLength = Object.keys(predictions).filter(
    p => predictions[p] != null
  ).length;

  const isHome = currentPlayer.homeTeam;

  return (
    <>
      <CompetitionBlock
        selectedTeam={{
          name: currentPlayer.teamName,
          logo: isHome
            ? currentPlayer.homeTeamLogoUrl
            : currentPlayer.awayTeamLogoUrl,
        }}
        competitorTeam={{
          name: !isHome
            ? currentPlayer.homeTeamName
            : currentPlayer.awayTeamName,
          logo: !isHome
            ? currentPlayer.homeTeamLogoUrl
            : currentPlayer.awayTeamLogoUrl,
        }}
        homeTeamName={currentPlayer.homeTeamName}
        startTime={currentPlayer.competitionStart}
        date={currentPlayer.competitionStartDate}
      />

      <Space size={5} isVertical />

      <div style={{ margin: '0 16px' }}>
        <ProgressBar
          width={100}
          percentage={(100 / 8) * validPredictionsLength}
        />
      </div>

      <Space size={4} isVertical />

      <CardsSwiperStatpicks
        currentIndex={currentIndex}
        data={players.map(item => ({
          ...item,
          selected: predictions[item.id],
        }))}
        onSelectOption={onSelectPrediction}
        onChangeCard={(index, currentCardData) =>
          currentCardData && onChangeCard(currentCardData)
        }
        onClickLastCard={onClickLastCard}
      />
    </>
  );
};

Statpicks.defaultProps = {
  competitors: [],
  predictions: {},
  onChangeCard: () => {},
  onSelectPrediction: () => {},
};

Statpicks.propTypes = {
  competitors: PropTypes.array,
  currentPlayer: PropTypes.object,
  onChangeCard: PropTypes.func,
  onClickLastCard: PropTypes.func,
  onSelectPrediction: PropTypes.func,
  predictions: PropTypes.object,
};

export default Statpicks;
