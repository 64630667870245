const commons = {
  p: 2,
  bg: 'white',
  borderRadius: 5,
  boxShadow: 'card',
  overflow: 'hidden',
};

export default {
  ...commons,
  bare: {
    ...commons,
    padding: 0,
  },
};
