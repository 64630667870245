//TODO: Remove line after re-enabling per issue #273
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Avatar, Button, GeneralText, Group, Icon, Space } from 'atoms';
import { Pencil, ShieldCheck } from 'assets/icons';
import { Fill } from 'atoms/Group';
import { PersonHeading, StatsRow, ValueAndLabel } from 'molecules';
import { callAddFunds, useUserData } from 'hooks';

//TODO: Remove after launch. Just a placeholder for profile image for launch
import { gravatar, isRealNumber } from 'services/utilities';

import assets from 'assets/themes/variants/assets';

const AvatarContainer = styled(Group)`
  background-image: linear-gradient(${colors.primary} 50%, ${colors.white} 50%);
`;

const AvatarWrapper = styled.span`
  position: relative;
`;

const Wrapper = styled(Group)`
  background-color: ${colors.white};
`;

const MainTitleWithAvatar = ({ onClickEdit }) => {
  const [userData] = useUserData();
  const [AddFunds, toggleAddFunds] = callAddFunds();

  const {
    displayName,
    email,
    finishedEntries,
    firstName,
    isAnonymous,
    isReady,
    lastName,
    numEntriesWon,
    photoURL,
    promoCash,
    verified,
    withdrawableFunds,
  } = userData;

  if (!isReady) return `Loading...`;

  const avatarPlaceholder = assets['avatarPlaceholder'];

  const isVerified = verified && !isAnonymous;

  let avgWinPercentage = ((numEntriesWon / finishedEntries) * 100).toFixed(2);
  avgWinPercentage = isRealNumber(avgWinPercentage) ? avgWinPercentage : 0;

  const balance = (promoCash + withdrawableFunds).toFixed(2);
  const availableFunds = withdrawableFunds.toFixed(2);

  let avatarSource =
    photoURL || (email && (avatarPlaceholder || gravatar(email)));

  return (
    <Wrapper isCentered isVertical>
      <AvatarContainer isVertical isCentered isFullWidth>
        <Space isMedium isVertical isCentered />

        <Group isFullWidth isCentered isVerticalCentered>
          <Space isMedium />

          <Group
            isVertical
            isVerticalCentered
            spaceSize={20}
            style={{ width: 80 }}>
            <Space isLarge />
            <ValueAndLabel
              value={numEntriesWon ? numEntriesWon : 0}
              label={`WIN${numEntriesWon !== 1 ? 'S' : ''}`}
            />
          </Group>

          <Fill />

          <AvatarWrapper>
            <Avatar source={avatarSource} isSecondary />
            {/* TODO: Re-enable for launch and remove temporary Avatar w/ gravatar above */}
            {/* <Avatar source={photoURL} isSecondary />
            <Button
              isRounded
              size={40}
              onClick={onClickEdit}
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
              }}>
              <Icon color={colors.white} size={18}>
                <Pencil />
              </Icon>
            </Button> */}
          </AvatarWrapper>

          <Fill />

          <Group
            isVertical
            isVerticalCentered
            spaceSize={20}
            style={{ width: 80 }}>
            <Space isLarge />
            <ValueAndLabel value={`${avgWinPercentage}%`} label="WIN AVG" />
          </Group>
          <Space isMedium />
        </Group>

        <Space isSmall isVertical />
      </AvatarContainer>

      <PersonHeading
        subtitle={email}
        style={{
          display: 'flex',
          padding: '5px 10px',
        }}>
        <Group isVerticalCentered>
          {displayName}
          <Space isSmall />
          {isVerified && (
            <Icon size={12}>
              <ShieldCheck />
            </Icon>
          )}
        </Group>
      </PersonHeading>

      {/* TODO: Remove space and reenable commented lines when we have paid games */}
      <Space isVertical />
      {/* <Group isVerticalCentered spaceSize={5}>
        <StatsRow
          style={{ marginLeft: '-10px', maxWidth: '200px' }}
          stats={[
            {
              label: 'BALANCE',
              value: `$${balance}`,
            },
            {
              label: `AVAILABLE`,
              value: `$${availableFunds}`,
            },
          ]}
        />

        <Button isFullWidth onClick={toggleAddFunds}>
          <GeneralText
            isSmall
            color={colors.white}
            style={{ whiteSpace: 'nowrap' }}>
            ADD FUNDS
          </GeneralText>
        </Button>

        <AddFunds />
      </Group> */}
    </Wrapper>
  );
};

MainTitleWithAvatar.defaultProps = {
  onClickEdit: () => {},
};

MainTitleWithAvatar.propTypes = {
  onClickEdit: PropTypes.func,
};

export default MainTitleWithAvatar;
