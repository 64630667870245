import React from 'react';

import { Router } from '@reach/router';
import MetFanRoutes from '../MeteorFantasy';

import Home from 'containers/Home';

import GameTypeSelection from 'containers/GameTypeSelection';
import TeamsSelection from 'containers/TeamsSelection';
import Gameplay from 'containers/Gameplay';
import SubmitGate from 'containers/SubmitGate';
import SubmitGame from 'containers/SubmitGame';
import Account from 'containers/Account';
import AccountVerification from 'containers/AccountVerification';

const Routes = () => (
  <Router>
    <Home path="/" />

    {/* <GameTypeSelection path="/" /> */}

    <GameTypeSelection path="gametype" />
    <TeamsSelection path="teams" />
    <Gameplay path="gameplay" />
    <SubmitGate path="submit-gate" />
    <SubmitGame path="submit" />
    <Account path="account/*" />

    <AccountVerification path="account-verification/:isNew" />
    <AccountVerification path="account-verification" />

    <MetFanRoutes path="/*" />
  </Router>
);

export default Routes;
