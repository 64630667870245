import React, { useEffect, useState } from 'react';

import useAlert from 'hooks/useAlert';

import localforage from 'localforage';

import { api } from 'services/firebase';

const useEntryCancellation = (currentListOfIds = []) => {
  const [Alert, toggleAlert] = useAlert();
  const [Error, toggleError] = useAlert();

  const [inCancellation, setInCancellation] = useState(null);
  const [cancellationList, setCancellationList] = useState({});
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const ids = {};
    if (Array.isArray(currentListOfIds))
      currentListOfIds.forEach(i => {
        if (typeof i === 'string') ids[i] = false;
      });
    localforage.setItem('inCancellationEntries', ids).then(setCancellationList);
    return () => {
      localforage.removeItem('inCancellationEntries');
    };
  }, []);

  const confirmCancellation = entryId => {
    setInCancellation(entryId);
    toggleAlert();
  };

  const handleError = message => {
    setErrorMessage(message);
    localforage
      .setItem('inCancellationEntries', {
        ...cancellationList,
        [inCancellation]: false,
      })
      .then(value => {
        toggleError();
        setStatus('error');
        setInCancellation(null);
        setCancellationList(value);
      });
  };

  const call = () => {
    return new Promise((resolve, reject) => {
      setStatus('processing');

      localforage
        .setItem('inCancellationEntries', {
          ...cancellationList,
          [inCancellation]: true,
        })
        .then(setCancellationList);

      api
        .cancelEntry({ entryId: inCancellation })
        .then(({ success, feedback }) => {
          if (!success) {
            handleError(feedback);
            reject();
            return;
          }
          setStatus('success');
          setInCancellation(null);
          resolve();
        })
        .catch(({ feedback }) => {
          handleError(feedback);
          reject();
        });
    });
  };

  const Render = () => {
    return (
      <>
        <Alert
          specSelector="entry-cancellation-confirm"
          title="Leave the contest?"
          proceedCancelLabel="Nevermind"
          onClickToCancel={() => setInCancellation(null)}
          onClickToClose={() => setInCancellation(null)}
          onClickToProceed={call}>
          Are you sure to proceed with the cancellation and leave the contest?
        </Alert>
        <Error
          specSelector="entry-cancellation-error"
          title="An error occurred"
          proceedButtonLabel="Ok"
          hideCancelButton
          onClickToProceed={() => setErrorMessage(null)}>
          {errorMessage ||
            'You cannot cancel this contest, please contact support or try again.'}
        </Error>
      </>
    );
  };

  return [
    Render,
    {
      confirmCancellation,
      inCancellation,
      cancellationList,
      status,
    },
  ];
};

export default useEntryCancellation;
