import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { GeneralText } from 'atoms';
import EmptyState from 'molecules/EmptyState';

import Alert from 'molecules/Alert';
import useGameSteps from 'hooks/useGameSteps';

import { MainContext } from 'containers/MainContainer';

import { toggleGameType } from '../helpers';
import useFriendlyName from 'services/friendlyNames';
import { useAppContainer } from 'organisms/AppContainer';
import { useDraftContest } from 'organisms/ContestBuilder';

import { ContainerWithLoading } from 'molecules/LoadingScreen';

const Wrapper = ({ children, isLoading, gameType, onChangeGameType }) => {
  const { resetGame } = useContext(MainContext);

  const { reload } = useAppContainer({
    header: {
      title: gameType === 'statpicks' ? 'Statpicks' : 'Over/Under',
    },
  });

  const { update } = useDraftContest();

  useEffect(() => {
    reload();
  }, [gameType]);

  const isAvailableToPlay =
    useGameSteps(['timeBlock', 'entryFees']).isReadyByValidator &&
    isLoading !== null &&
    !isLoading;

  const [showAlert, setAlertState] = useState(false);

  const currentGameType = useFriendlyName(gameType);
  const nextGameType = useFriendlyName(toggleGameType[gameType]);

  return (
    <>
      <Alert
        isOpen={showAlert}
        specSelector="alert-gametype-change"
        title="Important!"
        onClickToCancel={() => setAlertState(false)}
        onClickToProceed={() => {
          setAlertState(false);
          onChangeGameType();
        }}
        onClickToClose={() => setAlertState(false)}>
        <GeneralText>
          You are about to change the type of the game from{' '}
          <b>{currentGameType}</b> to <b>{nextGameType}</b> and will lose your
          current predictions.
        </GeneralText>
      </Alert>

      <Alert
        isOpen={!isAvailableToPlay && isLoading !== null && !isLoading}
        specSelector="alert-contest-unavailable"
        disableClose
        title="Contest unavailable!"
        cancelButtonLabel="Restart"
        proceedButtonLabel={'Try ' + nextGameType}
        onClickToCancel={() => resetGame(true)}
        onClickToProceed={() => {
          update({ type: nextGameType });
          onChangeGameType();
        }}>
        <GeneralText>
          It looks like you’ve already entered this contest.
        </GeneralText>
      </Alert>

      <ContainerWithLoading
        isHidden={isLoading === null || isLoading ? null : isAvailableToPlay}>
        <div style={{ maxWidth: 450, margin: 'auto', overflow: 'hidden' }}>
          {children}
        </div>
      </ContainerWithLoading>

      {!isAvailableToPlay && (
        <EmptyState description={'Content not available :('} />
      )}
    </>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
  gameType: PropTypes.string,
  isAvailableToPlay: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChangeGameType: PropTypes.func,
};

export default Wrapper;
