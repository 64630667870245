import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Group } from 'atoms';

import colors from 'assets/themes/colors';

const StyledGroup = styled(Group)`
  /* Fallback to default layout content height */
  height: ${({ height }) => {
    const isNumber = typeof height === 'number';
    const showPx = isNumber ? 'px' : '';
    return height ? height + showPx : 'calc(100vh - 68px)';
  }};
  border-bottom: 10px solid ${colors.primary};
`;

const Wrapper = ({ children, ...rest }) => {
  return (
    <StyledGroup
      padding={'80px 20px'}
      spaceSize={15}
      isVertical
      isCentered
      {...rest}>
      {children}
    </StyledGroup>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export default Wrapper;
