import React from 'react';
import PropTypes from 'prop-types';

import { navigate } from '@reach/router';

import { connect } from 'react-redux';
import * as actions from './actions';

import { Group } from 'atoms';

import GameTypeSelection from 'organisms/GameTypeSelection';

import Wrapper from './components/Wrapper';

import BackgroundImage from 'assets/media/images/you-entered-1.png';

import callCheckRegion from 'hooks/callCheckRegion';
import useAnalytics from 'hooks/useAnalytics';

import useGuidedTour from 'hooks/guidedTour';
import { gameTypeSelectionTour } from 'hooks/guidedTour/tours';

import { useDraftContest } from 'organisms/ContestBuilder';

export const GameType = ({ selectGameType }) => {
  const [Alert, { isAvailable, toggleModal }] = callCheckRegion();
  const [Tour] = useGuidedTour({
    tourName: gameTypeSelectionTour.name,
    autoStart: true,
  });

  const { logEvent } = useAnalytics();

  const { draft, isReady, update } = useDraftContest();

  if (!draft.sport && isReady) {
    navigate('/');
    return null;
  }

  return (
    <Wrapper {...{ isReady }}>
      <Alert />

      <Tour steps={gameTypeSelectionTour.steps} disableScrolling />

      <Group
        isVertical
        spaceSize={10}
        padding={'10px 0'}
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          height: 'calc(100vh - 120px)',
        }}>
        <GameTypeSelection
          title="Over Under"
          gametype="overunder"
          description={
            'Will individual players from two teams that you select go "Over" or "Under" for pre-determined stats?'
          }
          onSelect={isFree => {
            if (!isFree && !isAvailable) {
              toggleModal();
              return;
            }

            logEvent('paid_free', { item_name: isFree ? 'Free' : 'Paid' });
            logEvent('gametype', { item_name: 'Over/Under' });

            selectGameType('overunder', isFree);

            update({
              type: 'overunder',
              modality: isFree ? 'free' : 'paid',
            }).then(() => navigate('/teams'));
          }}
          css={{ minWidth: '100%', height: '50%' }}
        />

        <GameTypeSelection
          title="Statpicks"
          gametype="statpicks"
          description={"Select two teams and predict the players' stats"}
          onSelect={isFree => {
            if (!isFree && !isAvailable) {
              toggleModal();
              return;
            }

            logEvent('paid_free', { item_name: isFree ? 'Free' : 'Paid' });
            logEvent('gametype', { item_name: 'Statpicks' });

            selectGameType('statpicks', isFree);

            update({
              type: 'statpicks',
              modality: isFree ? 'free' : 'paid',
            }).then(() => navigate('/teams'));
          }}
          css={{ minWidth: '100%', height: '50%' }}
        />
      </Group>
    </Wrapper>
  );
};

GameType.propTypes = {
  selectGameType: PropTypes.func,
};

export default connect(
  store => ({
    ...store.gameType,
  }),
  { ...actions }
)(GameType);
