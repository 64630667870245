import React, { useState, useEffect } from 'react';

import { object, string } from 'yup';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import {
  Button,
  FormContainer,
  GeneralText,
  Group,
  Icon,
  Input,
  Title,
  Space,
  Checkbox,
} from 'atoms';
import { ModalHeader } from 'organisms';

import { MeteorFantasyLogo } from 'molecules';

import { Fill } from 'atoms/Group';
import Alert from 'molecules/Alert';
import useModal from 'hooks/useModal';
import { Envelope, LockAlt, UserAlt } from 'assets/icons';

import { Terms as TermsPage } from 'pages';

import { auth } from 'services/firebase';

import { returnTo } from 'services/utilities';

import useAnalytics from 'hooks/useAnalytics';

import useUserData from 'hooks/useUserData';

const validationSchema = object().shape({
  email: string().email('E-mail is not valid'),
  password: string().min(6, 'Password needs at least 6 characters'),
  firstName: string(),
  lastName: string(),
});

const SignUp = () => {
  const [feedback, setFeedback] = useState('');
  const [Terms, toggleTerms] = useModal();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { logEvent } = useAnalytics();

  const timeout = setTimeout;

  // eslint-disable-next-line no-unused-vars
  const [session, reload] = useUserData();

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Group
      isVertical
      isFullWidth
      isCentered
      isVerticalCentered
      style={{ margin: 'auto', maxWidth: 415 }}>
      <Group isVertical isFullWidth isCentered spaceSize={20} padding={30}>
        <Space isLarge isVertical />
        <MeteorFantasyLogo size={180} />
        <Title color={colors.white}>Create Account</Title>

        <FormContainer
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async ({ email, password, firstName, lastName }) => {
            setIsLoading(true);
            try {
              auth()
                .createUserWithEmailAndPassword(email, password)
                .then(
                  async ({ user }) => {
                    if (user) {
                      await user.updateProfile({
                        displayName: firstName + ' ' + lastName,
                      });
                    }
                    logEvent('join_group', { group_id: 2 });
                    logEvent('signup');
                    logEvent('login');
                    reload().then(() => {
                      navigate(`/${returnTo() || 'gametype'}`);
                    });
                    // TODO: Uncomment after launch
                    // navigate(returnTo('/account-verification/new'))
                  },
                  ({ message }) => {
                    setIsLoading(false);
                    setFeedback(message);
                  }
                );
            } catch ({ feedback }) {
              setIsLoading(false);
              setFeedback(feedback);
            }
          }}
          render={({ values, isValid, handleChange, handleSubmit }) => {
            const { firstName, lastName, password, email } = values;
            const isEmpty = !(firstName && lastName && password && email);
            const allowSubmit = isValid && !isEmpty && isChecked;
            return (
              <Group isFullWidth isVertical spaceSize={20} isCentered>
                <Input
                  specSelector="signup-input-firstname"
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <UserAlt />
                    </Icon>
                  }
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder="First name"
                />
                <Input
                  specSelector="signup-input-lastname"
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <UserAlt />
                    </Icon>
                  }
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  placeholder="Last name"
                />
                <Input
                  specSelector="signup-input-email"
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <Envelope />
                    </Icon>
                  }
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <Input
                  specSelector="signup-input-password"
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <LockAlt />
                    </Icon>
                  }
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Password"
                />

                <Group spaceSize={5}>
                  <Checkbox
                    specSelector="signup-input-accept-terms"
                    onChange={({ target }) => setIsChecked(target.checked)}
                    checked={isChecked}
                  />
                  <GeneralText
                    onClick={() => setIsChecked(!isChecked)}
                    color={colors.white}>
                    Yes, I agree to
                  </GeneralText>
                  <GeneralText onClick={toggleTerms} color={colors.accent}>
                    Terms and Conditions
                  </GeneralText>
                </Group>

                <Button
                  specSelector="signup-submit-button"
                  type="submit"
                  isFullWidth
                  disabled={!allowSubmit}
                  onClick={handleSubmit}>
                  CREATE ACCOUNT
                </Button>
              </Group>
            );
          }}
        />

        <Group isCentered isFullWidth>
          <Button isBare onClick={() => navigate('/auth/signin')}>
            <GeneralText color={colors.white}>
              Already have account?
            </GeneralText>
          </Button>
        </Group>
      </Group>

      <Group isCentered isFullWidth>
        <Button isBare onClick={() => navigate('/')}>
          <GeneralText color={colors.mattedGray}>
            GO BACK TO THE GAME!
          </GeneralText>
        </Button>
      </Group>

      <Space isVertical isLarge />

      <Fill />

      <Terms
        onClickToClose={toggleTerms}
        customHeader={() => (
          <ModalHeader
            onClickToClose={toggleTerms}
            title="Terms & Conditions"
          />
        )}>
        <TermsPage />
      </Terms>

      <Alert
        isOpen={!!feedback}
        disableClose
        title="Something happened"
        cancelButtonLabel="Sign in"
        proceedButtonLabel={'Try again'}
        onClickToCancel={() => navigate('/auth/signin')}
        onClickToProceed={() => setFeedback('')}>
        <GeneralText>{feedback}</GeneralText>
      </Alert>

      <Alert
        isOpen={isLoading}
        disableClose
        title="Creating account"
        hideCancelButton
        hideProceedButton>
        <GeneralText>Please wait...</GeneralText>
      </Alert>
    </Group>
  );
};

SignUp.propTypes = {};

export default SignUp;
