import * as firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.firebaseApiKey,
  authDomain: process.env.firebaseAuthDomain,
  databaseURL: process.env.firebaseDatabaseURL,
  projectId: process.env.firebaseProjectId,
  storageBucket: process.env.firebaseStorageBucket,
  messagingSenderId: process.env.firebaseMessagingSenderId,
  appId: process.env.firebaseAppId,
  measurementId: process.env.measurementId,
};

export const initFirebase = () => {
  firebase.initializeApp(firebaseConfig);

  // firebase.firestore().enablePersistence({
  //   synchronizeTabs: true
  // });

  if (firebase.messaging.isSupported()) {
    // TODO: Move to env vars
    firebase
      .messaging()
      .usePublicVapidKey(
        'BJXDH6dSzHYEk2AkeacPAdIr6FsGKijcErtT2yZD9PgPmyyjQcu9Tbfm4zF3ja4oA5yMgdEJeghg8s9Yd8jTEgM'
      );
  }
};

export { default as auth } from './auth';
export { default as fetch } from './fetch';
export { default as messaging } from './messaging';
export { default as api } from './api';
export { default as users } from './users';
