import React from 'react';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Group } from 'atoms';

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 10px solid ${colors.freshGreen};
`;
const TopRectangle = styled.div`
  width: 30px;
  height: 8px;
  background-color: ${colors.freshGreen};
  border-radius: 2px 2px 0 0;
`;
const MiddleRectangle = styled.div`
  width: 35px;
  height: 15px;
  background-color: ${colors.freshGreen};
  margin: 1px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SSLBadge = () => (
  <Group isVertical isCentered spaceSize={0}>
    <TopRectangle>&nbsp;</TopRectangle>
    <MiddleRectangle>SSL</MiddleRectangle>
    <DownArrow>&nbsp;</DownArrow>
  </Group>
);

export default SSLBadge;
