import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import useUserData from 'hooks/useUserData';

const db = firebase.firestore;

const useUserResults = () => {
  /**
   * hook that gets all contest entries, with results for a logged in user
   * @return {Array} - entries with results as an array
   * **/

  const [{ uid }] = useUserData();
  const [userResults, setUserResults] = useState();
  const [status, setStatus] = useState('waiting');

  let unsubscribe;

  function getUserResults() {
    try {
      setStatus('loading');
      unsubscribe = db()
        .collection(process.env.firestoreEntries)
        .where('processingStatus', '==', 2)
        .where('userId', '==', uid)
        .onSnapshot(querySnapshot => {
          setStatus('loading');
          let results = [];

          querySnapshot.forEach(doc => {
            results.push(doc.data());
          });

          setUserResults(results);
          setStatus('success');
        });
    } catch (error) {
      setStatus('error');
    }
  }

  useEffect(() => {
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (uid) {
      getUserResults();
    }
  }, [uid]);

  return [userResults, { isReady: status === 'success' }];
};

export default useUserResults;
