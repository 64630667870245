import request from 'axios';

import { auth } from 'services/firebase';

export default {
  createEntry: async body => {
    const token = await auth().currentUser.getIdToken();
    return new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/createEntry`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  cancelEntry: async body => {
    const token = await auth().currentUser.getIdToken();
    return new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/cancelEntry`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  setUser: async body => {
    const token = await auth().currentUser.getIdToken();
    return new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/upsertUser`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  readNotification: async notificationId => {
    const token = await auth().currentUser.getIdToken();
    return new Promise((resolve, reject) => {
      request
        .post(
          `${process.env.firebaseCloudFunctionsURL}/notifications/read`,
          { notificationId },
          {
            headers: { authorization: 'Bearer ' + token },
          }
        )
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  sendFCMToken: async (registrationToken, topic) => {
    return new Promise((resolve, reject) => {
      request
        .post(
          `${process.env.firebaseCloudFunctionsURL}/notifications/register`,
          { registrationToken, topic }
        )
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  sendVerificationLink: async () => {
    const token = await auth().currentUser.getIdToken();
    return new Promise((resolve, reject) => {
      request
        .post(
          `${process.env.firebaseCloudFunctionsURL}/sendUserVerificationEmail`,
          {},
          {
            headers: { authorization: 'Bearer ' + token },
          }
        )
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  sendPasswordResetEmail: async email => {
    return new Promise((resolve, reject) => {
      request
        .post(
          `${process.env.firebaseCloudFunctionsURL}/sendPasswordResetEmail`,
          { email }
        )
        .then(({ data }) => resolve({ ...data }))
        .catch(reject);
    });
  },
  updateEmailVerifiedStatus: async uid => {
    return new Promise((resolve, reject) => {
      request
        .post(
          `${process.env.firebaseCloudFunctionsURL}/updateUserEmailVerifiedStatus`,
          { uid }
        )
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response.data));
    });
  },
  verifyAccount: (body, token, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/verifyAccount`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(callback || resolve)
        .catch(error => reject(error.response.data));
    }),
  saveCreditCard: (body, token, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/saveCreditCard`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(callback || resolve)
        .catch(error => reject(error.response.data));
    }),

  getVaultData: token =>
    new Promise((resolve, reject) => {
      request
        .get(`${process.env.firebaseCloudFunctionsURL}/getVaultData`, {
          // TODO: We gonna finished that after launch
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response));
    }),

  addFunds: (body, token) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/addFunds`, body, {
          // TODO: We gonna finished that after launch
          headers: { authorization: 'Bearer ' + token },
        })
        .then(({ data }) => resolve({ ...data }))
        .catch(error => reject(error.response));
    }),

  withdraw: (body, token, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(`${process.env.firebaseCloudFunctionsURL}/withdraw`, body, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then(callback || resolve)
        .catch(error => reject(error.response));
    }),
  sendEmail: (body, callback) =>
    new Promise((resolve, reject) => {
      request
        .post(
          `${process.env.firebaseCloudFunctionsURL}/createSupportEmail`,
          body
        )
        .then(callback || resolve)
        .catch(error => reject(error.response.data));
    }),
};
