import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import colors from 'assets/themes/colors';

const leftmostColumnStyles = css`
  position: sticky;
  left: 0;
  text-align: left;
`;

const headerStyles = css`
  border-top: 1px solid ${colors.primary};
  white-space: nowrap;
  text-align: center;
`;

const applyCellStyles = ({ isFirst, isHeader, color, backgroundColor }) => css`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid ${colors.primary};
  border-right: 1px solid ${colors.primary};
  color: ${color};
  background-color: ${backgroundColor};
  ${isFirst ? leftmostColumnStyles : ''};
  ${isHeader ? headerStyles : ''};
`;

const CellWrapper = styled.td`
  ${applyCellStyles}
`;

const RowWrapper = styled.tr`
  th:first-child,
  td:first-child {
    border-left: 1px solid ${colors.primary};
    white-space: nowrap;
  }
`;

const TableRow = ({ backgroundColor, textColor, isHeader, children }) => (
  <RowWrapper>
    {children.map((child, i) => (
      <CellWrapper
        key={i}
        isFirst={i === 0}
        isHeader={isHeader}
        as={isHeader ? 'th' : 'td'}
        color={textColor}
        backgroundColor={backgroundColor}>
        {child}
      </CellWrapper>
    ))}
  </RowWrapper>
);

TableRow.defaultProps = {
  textColor: colors.primary,
  backgroundColor: colors.white,
};

TableRow.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  isHeader: PropTypes.bool,
};

export default TableRow;
