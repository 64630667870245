import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import TimeBlockSelector from 'molecules/TimeBlockSelector';

import useAnalytics from 'hooks/useAnalytics';

import { useDraftContest } from 'organisms/ContestBuilder';

import moment from 'moment';

const DayDivisor = ({
  onChange,
  defaultSelected,
  availableTeams,
  dayDivisors,
}) => {
  const { logEvent } = useAnalytics();

  const { draft } = useDraftContest();

  useEffect(() => {
    if (defaultSelected) {
      logEvent('day_divisor', { item_name: defaultSelected });
    }
  }, [defaultSelected]);

  let sortedDayDivisors = dayDivisors;

  if (draft && draft.sport === 'nba') {
    sortedDayDivisors = dayDivisors.sort();
  }

  const items = sortedDayDivisors.map(item => {
    let label = item;
    if (draft && draft.sport === 'nba') {
      label = moment(item, 'YYYY-MMM-DD').format('MMM DD, YYYY');
    }
    const teams = (availableTeams || {})[item] || [];
    const timeBlockLabel = teams.length > 0 ? teams[0].timeBlock.label : null;
    return {
      label: timeBlockLabel || label,
      value: item,
      size: teams.length,
    };
  });

  return (
    <div
      data-guided-tour="day-divisor-select"
      style={{
        width: '100%',
        maxWidth: 445,
        margin: 'auto',
        display: 'flex',
        padding: '20px 15px 10px',
      }}>
      <TimeBlockSelector
        initialSelected={defaultSelected}
        onChange={selection => {
          logEvent('day_divisor', { item_name: selection });
          onChange(selection);
        }}
        items={items}
      />
    </div>
  );
};

DayDivisor.propTypes = {
  availableTeams: PropTypes.object,
  dayDivisors: PropTypes.array,
  defaultSelected: PropTypes.string,
  onChange: PropTypes.func,
};

export default DayDivisor;
