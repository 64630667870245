// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import { useAppContainer } from 'organisms/AppContainer';

import { ContainerWithLoading } from 'molecules/LoadingScreen';

const Wrapper = ({ children, isReady }) => {
  useAppContainer({
    header: {
      title: 'Select Lineups',
    },
  });
  return (
    <ContainerWithLoading isHidden={isReady}>
      <div style={{ maxWidth: 450, margin: 'auto' }}>{children}</div>
    </ContainerWithLoading>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
  isReady: PropTypes.bool,
};

export default Wrapper;
