//TODO Remove this line when done with paid games and credit cards
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import * as Yup from 'yup';

import { auth } from 'services/firebase';

import colors from 'assets/themes/colors';
import {
  Button,
  FormContainer,
  GeneralText,
  Group,
  Icon,
  Input,
  Title,
} from 'atoms';
import ErrorDisplay from './ErrorDisplay';
import { Envelope, PhoneAlt, UserCircle } from 'assets/icons';
import { displayNameMask, phoneMask } from 'services/inputMasks';
import { useUserData } from 'hooks';

const FormFields = ({
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  touched,
  values,
}) => {
  const displayName = displayNameMask.resolve(values.displayName || '');
  const phoneNumber = phoneMask.resolve(values.phoneNumber || '');

  return (
    <Group isFullWidth isVertical spaceSize={20} padding={20}>
      <Group isFullWidth isVertical spaceSize={5}>
        <Title>Update Your Account Info</Title>
        {/* TODO: To be re-enabled as part of issue #287 */}
        {/* <GeneralText>
          Changing your info here means you will be asked to verify your account
          again after you enter a paid contest.
        </GeneralText> */}
      </Group>
      <Input
        leftIcon={
          <Icon color={colors.accent} size={18}>
            <UserCircle />
          </Icon>
        }
        type="text"
        name="displayName"
        value={displayName}
        onBlur={handleBlur}
        onChange={event => {
          handleChange(event);
        }}
        placeholder={'Display Name'}
      />
      {errors.displayName && touched.displayName ? (
        <GeneralText isSmall color={colors.alert}>
          {errors.displayName}
        </GeneralText>
      ) : null}
      {/* TODO: Re-enable post launch and handle in issue #287 */}
      {/* <Input
        leftIcon={
          <Icon
            color={colors.accent}
            size={18}
            style={{
              zIndex: 1,
            }}>
            <PhoneAlt />
          </Icon>
        }
        type="tel"
        name="phoneNumber"
        value={phoneNumber}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={'(###) ###-####'}
      />

      <Input
        leftIcon={
          <Icon
            color={colors.accent}
            size={18}
            style={{
              zIndex: 1,
            }}>
            <Envelope />
          </Icon>
        }
        type="email"
        name="email"
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={'Email'}
      />
      <ErrorDisplay message={errors.email} touched={touched.email} /> */}
      <Button
        type="submit"
        isFullWidth
        onClick={handleSubmit}
        disabled={isSubmitting || Object.keys(errors).length > 0}
        isSmall>
        SUBMIT
      </Button>
    </Group>
  );
};

FormFields.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setSubmitting: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const ProfileForm = ({ onSubmit }) => {
  const [userData, reloadUserData] = useUserData();
  let { displayName, email, phoneNumber } = userData;

  const user = auth().currentUser;

  return (
    <FormContainer
      initialValues={{
        displayName,
        email,
        phoneNumber,
      }}
      validationSchema={Yup.object().shape({
        displayName: Yup.string()
          .min(3, 'Too short!')
          .max(23, 'Too long!')
          .required('Required'),
        email: Yup.string()
          .email()
          .required('Required'),
      })}
      onSubmit={({ displayName, email, phoneNumber }, { setSubmitting }) => {
        const profileData = {
          displayName,
          phoneNumber,
        };

        //TODO: Move this code to hook - see issue #297
        user
          .updateProfile({
            ...profileData,
          })
          .then(() => {
            reloadUserData();
            setSubmitting(false);
          })
          .then(() => {
            onSubmit(false);
          });
        //TODO: Handle as part of issue #287
        // .then(() => {
        //   if (email !== userData.email) {
        //     user.updateEmail(email).then(() => {
        //       console.log('email updated!');
        //       reloadUserData();
        //     });
        //   }
        // })
      }}
      render={formProps => {
        return <FormFields {...formProps} />;
      }}
    />
  );
};

ProfileForm.defaultProps = {
  onSubmit: () => {},
};

ProfileForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default ProfileForm;
