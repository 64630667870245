import { css } from '@emotion/core';

import colors, { convertHexToRGB } from './themes/colors';

export const baseFontSize = 14;
export const smallFontSize = 12;
export const largeFontSize = 18;
export const regularFontSize = 16;

export const smallLineHeight = 1.2;
export const baseLineHeight = 1.5;

export const borderWidth = 1;
export const borderRadius = 2;

export const inputStyles = css`
  border-radius: 2px;
  border: 1px solid ${colors.gray1};
  color: ${colors.darkGray2};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${({ small }) => (small ? 13 : 15)}px;
  justify-content: flex-start;
  min-height: ${({ small }) => (small ? 28 : 32)}px;
  padding-bottom: calc(.375em - 1px);
  padding-left: calc(.625em - 1px);
  padding-right: calc(.625em - 1px);
  padding-top: calc(.375em - 1px);
  ${() => inputFocus}
  ${() => inputHover}
  ${() => inputPlaceholder}
  ${() => inputDisabled}
`;

export const inputFocus = css`
  &:focus {
    outline: none;
    border: 1px solid ${convertHexToRGB(colors.primary, 0.8)};
    box-shadow: 0 0 3px 2px ${convertHexToRGB(colors.secondary, 0.6)};
  }
`;

export const inputHover = css`
  &:hover {
    border: 1px solid ${convertHexToRGB(colors.primary, 0.8)};
  }
`;

export const elevation = css`
  box-shadow: 1px 1px 4px ${convertHexToRGB(colors.black, 0.3)};
`;

export const inputReset = css`
  ${() => elementReset}
  font-size: ${baseFontSize}px;
`;

export const elementReset = css`
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  display: inline;
  line-height: 1.2;
  margin: 0;
  outline: none;
  padding: 0;
`;

export const inputDisabled = css`
  &:disabled {
    background-color: ${colors.light};
    border-color: ${colors.disabled};
  }
`;

export const absoluteFill = css`
  flex: 1;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const truncate = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const truncateReverse = css`
  direction: rtl;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const inputPlaceholder = css`
  &::-moz-placeholder {
    color: ${colors.dark};
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark};
  }
  &:-moz-placeholder {
    color: ${colors.dark};
  }
  &:-ms-input-placeholder {
    color: ${colors.dark};
  }
`;
