import React, { useState } from 'react';

import { object, string } from 'yup';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import {
  Button,
  FormContainer,
  GeneralText,
  Group,
  Icon,
  Input,
  Title,
  Space,
} from 'atoms';

import { MeteorFantasyLogo } from 'molecules';

import { Fill } from 'atoms/Group';
import Alert from 'molecules/Alert';
import { SecurityHeader } from 'molecules';
import { Envelope } from 'assets/icons';

import { api } from 'services/firebase';

const validationSchema = object().shape({
  email: string().email('E-mail is not valid'),
});

const PasswordRecovery = () => {
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Group
      isVertical
      isFullWidth
      isCentered
      isVerticalCentered
      style={{ height: '100vh', margin: 'auto', maxWidth: 415 }}>
      <Group isVertical isFullWidth isCentered spaceSize={20} padding={30}>
        <Space isLarge isVertical />
        <MeteorFantasyLogo size={180} />
        <Title color={colors.white}>Password Recovery</Title>

        <FormContainer
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{ email: '' }}
          onSubmit={({ email }) => {
            setIsLoading(true);
            api
              .sendPasswordResetEmail(email)
              .then(() => {
                setIsLoading(false);
                setFeedback('Email sent!');
              })
              .catch(({ message }) => {
                setIsLoading(false);
                setFeedback(message);
              });
          }}
          render={({ values, isValid, handleChange, handleSubmit }) => {
            const { email } = values;
            const isEmpty = !email;
            const allowSubmit = isValid && !isEmpty;
            return (
              <Group isFullWidth isVertical spaceSize={20} isCentered>
                <Input
                  leftIcon={
                    <Icon
                      color={colors.accent}
                      size={18}
                      style={{ zIndex: 10 }}>
                      <Envelope />
                    </Icon>
                  }
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <Button
                  disabled={!allowSubmit}
                  type="submit"
                  isFullWidth
                  onClick={handleSubmit}>
                  REQUEST
                </Button>
              </Group>
            );
          }}
        />

        <Group isFullWidth>
          <Button isBare onClick={() => navigate('/auth/signin')}>
            <GeneralText color={colors.white}>Sign In</GeneralText>
          </Button>
          <Fill />
          <Button isBare onClick={() => navigate('/auth/signup')}>
            <GeneralText color={colors.accent}>Create account</GeneralText>
          </Button>
        </Group>
      </Group>

      <Group isCentered isFullWidth>
        <Button isBare onClick={() => navigate('/')}>
          <GeneralText color={colors.mattedGray}>
            GO BACK TO THE GAME!
          </GeneralText>
        </Button>
      </Group>

      <Space isVertical isLarge />

      <Fill />

      <div style={{ width: '100vw', marginBottom: 20 }}>
        <SecurityHeader left="SECURE CONNECTION" right="SSL ENCRYPTION" />
      </div>

      <Alert
        isOpen={!!feedback}
        disableClose
        title="Check your email"
        cancelButtonLabel="Sign in"
        proceedButtonLabel={'Try again'}
        onClickToCancel={() => navigate('/auth/signin')}
        onClickToProceed={() => setFeedback('')}>
        <GeneralText>{feedback}</GeneralText>
      </Alert>

      <Alert
        isOpen={isLoading}
        disableClose
        title="Requesting"
        hideCancelButton
        hideProceedButton>
        <GeneralText>Please wait...</GeneralText>
      </Alert>
    </Group>
  );
};

PasswordRecovery.propTypes = {};

export default PasswordRecovery;
