import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const slideUpSoft = keyframes`
  0% {
    opacity: .1;
    transform-origin: center;
    transform: translateY(4%);
  }
  100% {
    opacity: .99;
    transform-origin: center;
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: ${slideUpSoft} 0.4s ease-in-out;
`;

export default Wrapper;
