import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'accountVerification',
  storage,
  blacklist: ['isVerifying', 'error', 'verificationStatus'],
  stateReconciler: autoMergeLevel2,
};

const INITIAL_STATE = {
  verificationStatus: null,
  error: null,
};

export default persistReducer(
  persistConfig,
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case 'VERIFY_ACCOUNT':
        return (state = {
          ...state,
          verificationStatus:
            state.verificationStatus !== 'verified'
              ? 'verifying'
              : state.verificationStatus,
          error: null,
        });

      case 'VERIFY_ACCOUNT_SUCCESS':
        return (state = {
          ...state,
          verificationStatus: 'verified',
          error: null,
        });

      case 'VERIFY_ACCOUNT_ERROR':
        return (state = {
          ...state,
          verificationStatus: 'error',
          error: action.payload,
        });

      case 'RESET_VERIFICATION_STATUS':
        return (state = INITIAL_STATE);

      default:
        return state;
    }
  }
);
