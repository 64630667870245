/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import theme from 'assets/themes/default';
import { GeneralText, Group, Space, Title } from 'atoms';
import Table from 'organisms/Table';

const standardScoreNums = [150, 50, 25, 15, 10, 5, 4, 3, 2, 1];

const Wrapper = styled(Group)`
  background-color: ${theme.colors.white};
  min-height: 100%;
`;

const HowToPlay = () => (
  <Wrapper isVertical spaceSize={20} padding={20}>
    <Title>Over/Under</Title>
    <GeneralText isMedium>
      1. Select the two teams you would like to use for your contest.
      <br />
      <br />
      2. Determine if the provided players will perform “over” or “under” for
      the shown stat.
      <br />
      <br />
      3. Note: Backup player selections will be used in the event that a player
      in the regular lineup is unable to play in the given game.
      <br />
      <br />
      4. 1 point will be awarded for each correct prediction for players in the
      lineup (Total of 6 points are up for grabs in a given contest.)
      <br />
      <br />
      5. The users with the most points will be determined the winner(s) --
      based on payout percentage.
      <br />
      <br />
      6. In the event that 2 or more players in the lineup for a single selected
      team are unable to play, your entry will be canceled.
    </GeneralText>

    <Title>StatPicks</Title>
    <GeneralText isMedium>
      1. Select the two teams you would like to use for your contest.
      <br />
      <br />
      2. Determine how the provided players will perform for the shown stat in
      the upcoming game. (Input the prediction, or use (+/-) to increase or
      decrease prediction.)
      <br />
      <br />
      3. Note: Backup player selections will be used in the event that a player
      in the regular lineup is unable to play in the given game.
      <br />
      <br />
      4. Points will be awarded based on position and stat and proximity to the
      actual performance, according to the chart below.
      <br />
      <br />
      5. In the event that 2 or more players in the lineup for a single selected
      team are unable to play, your entry will be canceled.
      <br />
      <br />
      6. The users with the most points will be determined the winner(s) --
      based on payout percentage.
    </GeneralText>

    <Table
      rows={[
        [
          'Metric',
          'Per Stat Scoring',
          'Exact Bonus',
          '+/- 5',
          '+/- 10',
          '+/- 15',
          '+/- 20',
          '+/- 25',
          '+/- 30',
          '+/- 35',
          '+/- 40',
          '+/- 45',
        ],
        ['Passing Yards', 0.04, ...standardScoreNums],
        ['Rushing Yards', 0.1, ...standardScoreNums],
        ['Receiving Yards', 0.1, ...standardScoreNums],
      ]}
    />

    <Space isSmall />

    <Table
      rows={[
        [
          'Metric',
          'Per Stat Scoring',
          'Exact Bonus',
          '+/- 1',
          '+/- 2',
          '+/- 3',
          '+/- 4',
        ],
        [
          'Touchdowns',
          6,
          ...standardScoreNums.slice(0, 2),
          ...Array(3).fill(''),
        ],
        ['Receptions', 0.5, ...standardScoreNums.slice(0, 5)],
        ['Rushing Attempts', 0.5, ...standardScoreNums.slice(0, 5)],
        [
          'Extra Points Made',
          1,
          ...standardScoreNums.slice(0, 3),
          ...Array(2).fill(''),
        ],
        [
          'Field Goals Made',
          3,
          ...standardScoreNums.slice(0, 3),
          ...Array(2).fill(''),
        ],
        [
          'Solo Tackles',
          1,
          ...standardScoreNums.slice(0, 4),
          ...Array(1).fill(''),
        ],
        [
          'Assisted Tackles',
          0.5,
          ...standardScoreNums.slice(0, 4),
          ...Array(1).fill(''),
        ],
        [
          'Quarterback Hits',
          0.5,
          ...standardScoreNums.slice(0, 4),
          ...Array(1).fill(''),
        ],
        [
          'Interceptions',
          3,
          ...standardScoreNums.slice(0, 2),
          ...Array(3).fill(''),
        ],
      ]}
    />
  </Wrapper>
);

Table.propTypes = {
  rows: PropTypes.array,
};

export default HowToPlay;
