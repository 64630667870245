/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

export default {
  name: 'gameTypeSelectionTour',
  steps: [
    {
      target: 'body',
      title: 'Welcome to Meteor Fantasy!',
      content: (
        <>
          You can learn more about any aspect of Meteor Fantasy game play by
          just clicking on these beacons and an explanation will show. Good
          Luck!
          <video
            autoPlay
            loop
            style={{ width: '100%', height: 200, marginTop: 10 }}>
            <source
              src={require('assets/videos/beacon-demo.mp4')}
              type="video/mp4"
            />
          </video>
        </>
      ),
      placement: 'center',
    },
    {
      target: '[data-guided-tour="game-type"]',
      title: 'Contest Types',
      content:
        'Paid games are coming soon, but you can earn Meteor Dollars, our promotional currency, with our free contests that you can use in our paid contests when they go live!',
      placement: 'auto',
    },
  ],
};
