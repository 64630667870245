import { css } from '@emotion/core';

export const GLOBAL_FONT_SIZE = 16;

export const px2Rem = pxValues =>
  pxValues.map(value => `${value / GLOBAL_FONT_SIZE}rem`);

export default css`
  @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700,900,900i&display=swap');

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${GLOBAL_FONT_SIZE}px;
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
`;
