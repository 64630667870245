import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import { Button, GeneralText, Group, Icon } from 'atoms';

import { Google, Facebook } from 'assets/icons';

import { auth } from 'services/firebase';

import { returnTo } from 'services/utilities';

import useAnalytics from 'hooks/useAnalytics';
import useAlert from 'hooks/useAlert';

const SocialSignIn = ({ onSignIn, onStateChange }) => {
  const { logEvent } = useAnalytics();
  const [Feedback, toggleAlert] = useAlert();
  const [message, setMessage] = useState();

  const handleSignIn = provider => {
    onSignIn();
    auth()
      .signInWithPopup(provider)
      .then(() => {
        logEvent('join_group', { group_id: 4 });
        navigate(`/${returnTo() || 'gametype'}`);
        // TODO: Uncomment when we restart with paid games
        // navigate(returnTo('/account-verification/new'))
        onStateChange({ success: true });
      })
      .catch(error => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          onStateChange({ success: false, error });
          setMessage(
            `You already have signed in with ${error.email} using another method`
          );
          toggleAlert();
          return;
        }
        onStateChange({ success: false, error });
        setMessage(error.message);
        toggleAlert();
      });
  };

  return (
    <Group isFullWidth spaceSize={4}>
      <Button
        onClick={() => handleSignIn(new auth.GoogleAuthProvider())}
        isFullWidth
        isXSmall
        style={{ backgroundColor: '#D93024' }}>
        <Icon color={colors.white}>
          <Google />
        </Icon>
        <GeneralText color={colors.white}>Google</GeneralText>
      </Button>

      <Button
        onClick={() => handleSignIn(new auth.FacebookAuthProvider())}
        isFullWidth
        isXSmall
        style={{ backgroundColor: '#4266B2' }}>
        <Icon color={colors.white}>
          <Facebook />
        </Icon>
        <GeneralText color={colors.white}>Facebook</GeneralText>
      </Button>

      <Feedback
        title="Something happened"
        hideCancelButton
        proceedButtonLabel="Ok">
        {message}
      </Feedback>
    </Group>
  );
};

SocialSignIn.defaultProps = {
  onSignIn: () => {},
  onStateChange: () => {},
};

SocialSignIn.propTypes = {
  onSignIn: PropTypes.func,
  onStateChange: PropTypes.func,
};

export default SocialSignIn;
