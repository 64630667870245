import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiblePlay as ResponsiblePlayPage } from 'pages';
import Wrapper from './Wrapper';

const ResponsiblePlay = ({ navigate }) => (
  <Wrapper title="Responsible Play">
    <ResponsiblePlayPage navigate={navigate} />
  </Wrapper>
);

ResponsiblePlay.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default ResponsiblePlay;
