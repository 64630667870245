import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import {
  Icon,
  Button,
  VerticalDivider,
  Group,
  GeneralText,
  Badge,
} from 'atoms';
import { Coins, Medal, TrophyAlt } from 'assets/icons';

import { Fill } from 'atoms/Group';
import { CardWrapper } from 'molecules';
import { ModalHeader } from 'organisms';
import { deepKey } from 'services/utilities';

import ImageWrapper from 'atoms/ImageWrapper';
import useModal from 'hooks/useModal';

import ResultsContainer from './components/ResultsContainer';

import { SportLogo } from 'organisms/ContestBuilder';

const Header = ({
  gameType,
  isFreeGame,
  isWinner,
  timeRange,
  status,
  sport,
}) => (
  <Group isFullWidth isVerticalCentered spaceSize={6}>
    <SportLogo selected={sport} height={28} />
    <GeneralText color={colors.white}>
      <b>{gameType}</b>
    </GeneralText>
    {isFreeGame && (
      <Badge isSmall color={colors.accent}>
        FREE
      </Badge>
    )}
    <Fill />
    <GeneralText color={colors.lightGray}>
      <b>{timeRange}</b>
    </GeneralText>
    {status === 'pending' && (
      <Badge isSmall color={colors.attention}>
        Pending
      </Badge>
    )}
    {status !== 'pending' && (
      <Icon size={14} color={isWinner ? colors.positive : colors.mattedGray}>
        {isWinner ? <TrophyAlt /> : <Medal />}
      </Icon>
    )}
  </Group>
);

const ResultsCard = ({ result, uid }) => {
  const gameType =
    result.entryFeeInstance.gameType === 1 ? `Over & Under` : `Statpicks`;

  const sport = String(result.timeBlock.sport).toLowerCase();

  const entryFeeInstanceWinners =
    deepKey(result, 'entryFeeInstance.results.winners') || [];
  const isUserWinner = entryFeeInstanceWinners.includes(uid);
  const promoCashWon = isUserWinner
    ? result.entryFeeInstance.promoCashPrizePerUser
    : 0;
  const isFreeGame = result.entryFeeInstance.entryFee === 0;
  const isOverUnder = result.entryFeeInstance.gameType === 1;

  const predictions = result.predictions;

  let timeRange = `Week: ${result.timeBlock.week}`;

  if (sport === 'nba') {
    timeRange = result.timeBlock.dayDivisor;
  }

  const [Modal, toggleModal] = useModal();

  const isHome = team => team && team.homeTeam;

  const selectedTeam = team => ({
    name: team.teamName,
    logo: isHome(team) ? team.homeTeamLogoUrl : team.awayTeamLogoUrl,
  });

  const versusTeam = team => ({
    name: !isHome(team) ? team.homeTeamName : team.awayTeamName,
    logo: !isHome(team) ? team.homeTeamLogoUrl : team.awayTeamLogoUrl,
  });

  let totalPoints = 0;
  Object.keys(predictions).forEach(pk => {
    const metricName = Object.keys(predictions[pk])[0];
    totalPoints =
      totalPoints + (predictions[pk][metricName].predictionPoints || 0);
  });

  return (
    <CardWrapper
      topLabel={
        <Header
          gameType={gameType}
          isFreeGame={isFreeGame}
          isWinner={isUserWinner}
          timeRange={timeRange}
          sport={sport}
        />
      }>
      <Group isCentered isVertical padding={15}>
        <Group isVerticalCentered spaceSize={10}>
          <ImageWrapper
            source={selectedTeam(result.competitionLineups[0]).logo}
            height={28}
            alt={selectedTeam(result.competitionLineups[0]).name}
          />
          <GeneralText isSmall color={colors.tertiary}>
            <b>x</b>
          </GeneralText>
          <ImageWrapper
            source={versusTeam(result.competitionLineups[0]).logo}
            height={28}
            alt={versusTeam(result.competitionLineups[0]).name}
          />
          <Fill />
          <VerticalDivider height={20} />
          <Fill />
          <ImageWrapper
            source={selectedTeam(result.competitionLineups[1]).logo}
            height={28}
            alt={selectedTeam(result.competitionLineups[1]).name}
          />
          <GeneralText isSmall color={colors.tertiary}>
            <b>x</b>
          </GeneralText>
          <ImageWrapper
            source={versusTeam(result.competitionLineups[1]).logo}
            height={28}
            alt={versusTeam(result.competitionLineups[1]).name}
          />
        </Group>
      </Group>

      <Group
        isFullWidth
        padding={'10px 15px'}
        isVertical
        style={{
          borderTop: `1px solid ${colors.mattedGray}`,
        }}>
        <Group isFullWidth isVerticalCentered padding={'0 0 4px'} spaceSize={5}>
          <GeneralText isSmall color={colors.tertiary}>
            <b>{isOverUnder ? `Total points` : `Total points earned`}</b>
          </GeneralText>
          <Fill />
          <Icon color={colors.tertiary} size={12}>
            <Coins />
          </Icon>
          <GeneralText isSmall color={colors.tertiary}>
            <b>{Number(totalPoints.toFixed(2))}</b>
          </GeneralText>
        </Group>

        <Group
          isFullWidth
          padding={'5px 0 0'}
          spaceSize={5}
          style={{
            borderTop: `1px solid ${colors.quaternary}`,
          }}>
          <GeneralText color={colors.primary}>
            <b>{isFreeGame ? `Promo Cash Won` : `Money Won`}</b>
          </GeneralText>
          <Fill />
          <GeneralText color={colors.primary}>
            <b>
              {/* TODO: Implement real money */}
              {(promoCashWon && `M$ ${promoCashWon.toFixed(2)}`) || `M$ 0.00`}
            </b>
          </GeneralText>
        </Group>

        <Group
          isFullWidth
          padding={'6px 0 0'}
          style={{
            borderTop: `1px solid ${colors.quaternary}`,
          }}>
          <Fill />
          <Button isSecondary isXSmall onClick={() => toggleModal()}>
            Check it out
          </Button>
        </Group>
      </Group>

      <Modal
        customHeader={() => (
          <ModalHeader
            onClickToClose={() => {
              toggleModal();
            }}
            title="Result details"
          />
        )}>
        <ResultsContainer
          uid={uid}
          data={result}
          onClickToClose={toggleModal}
        />
      </Modal>
    </CardWrapper>
  );
};

ResultsCard.defaultProps = {
  result: {},
};

ResultsCard.propTypes = {
  result: PropTypes.object,
  uid: PropTypes.string,
};

Header.propTypes = {
  gameType: PropTypes.string,
  isFreeGame: PropTypes.bool,
  isWinner: PropTypes.bool,
  sport: PropTypes.string,
  status: PropTypes.string,
  timeRange: PropTypes.string,
};

export default ResultsCard;
