import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { GeneralText, Group } from 'atoms';
import { Fill } from 'atoms/Group';
import CardWrapper from 'molecules/CardWrapper';

const TitleWrapper = styled.b`
  letter-spacing: 5px;
`;

const TransactionCard = ({ transaction }) => {
  const isEarning = typeof transaction.entryFee == 'number';
  let amountColor = colors.primary;
  let amountDisplay = '';
  if (transaction.amount > 0) {
    amountDisplay = `+ $${transaction.amount.toFixed(2)}`;
    amountColor = colors.positive;
  } else if (transaction.amount < 0) {
    amountDisplay = `- $${(transaction.amount * -1).toFixed(2)}`;
    amountColor = colors.alert;
  }

  return (
    <CardWrapper
      topLabel={
        <Group isFullWidth>
          <GeneralText isMedium color={colors.white}>
            <TitleWrapper>{transaction.type.toUpperCase()}</TitleWrapper>
          </GeneralText>
          <Fill />
          <GeneralText isMedium color={colors.white}>
            <b>#{transaction.num}</b>
          </GeneralText>
        </Group>
      }>
      <Group padding={20} isVertical isFullWidth spaceSize={20}>
        <Group isFullWidth>
          <Group isVertical>
            <GeneralText>
              <b>{transaction.label}</b>
            </GeneralText>
            <GeneralText color={colors.black}>
              {transaction.dateTime}
            </GeneralText>
          </Group>
          <Fill />
        </Group>
        {isEarning ? (
          <Group isFullWidth>
            <GeneralText color={colors.black}>Entry Fee:</GeneralText>
            <Fill />
            <GeneralText color={colors.black}>
              ${transaction.entryFee.toFixed(2)}
            </GeneralText>
          </Group>
        ) : null}

        <Group isFullWidth>
          <GeneralText>
            <b>Amount{isEarning ? ' Won:' : ':'}</b>
          </GeneralText>
          <Fill />
          <Group isVertical isCentered>
            <GeneralText color={amountColor}>
              <b>{amountDisplay}</b>
            </GeneralText>
            <GeneralText color={colors.black}>
              {transaction.isPromo ? 'Promo Cash' : 'Real Cash'}
            </GeneralText>
          </Group>
        </Group>
      </Group>
    </CardWrapper>
  );
};

TransactionCard.propTypes = {
  transaction: PropTypes.exact({
    type: PropTypes.oneOf(['Withdrawal', 'Entry Fee', 'Deposit', 'Earning']),
    num: PropTypes.string,
    label: PropTypes.string,
    dateTime: PropTypes.string,
    entryFee: PropTypes.number,
    amount: PropTypes.number,
    isPromo: PropTypes.bool,
  }),
};

export default TransactionCard;
