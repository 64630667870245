import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import { MainContext } from 'containers/MainContainer';

import useGameSteps from 'hooks/useGameSteps';

import Wrapper from './components/Wrapper';

import colors from 'assets/themes/colors';
import { Group, GeneralText, Icon, Spinner } from 'atoms';

import Trophy from 'assets/icons/trophy.svg';

import Error from './components/Error';
import NothingToDoHere from './components/NothingToDoHere';

import useAnalytics from 'hooks/useAnalytics';
import Success from './components/Success';
import { useDraftContest } from 'organisms/ContestBuilder';

export const SubmitGame = ({
  submitContest,
  isSubmitting,
  submitStatus,
  resetSubmitStatus,
  error,
}) => {
  const { resetGame } = useContext(MainContext);

  const { logEvent } = useAnalytics();

  const { isReadyToSubmit } = useGameSteps();

  const { clear } = useDraftContest();

  const isSubmitted = submitStatus === 'sent';

  useEffect(() => {
    resetSubmitStatus();
  }, []);

  useEffect(() => {
    if (submitStatus === 'sent')
      logEvent('submit', { item_name: 'Successful' });
    if (submitStatus === 'error')
      logEvent('submit', { item_name: 'Not_successful' });
  }, [isSubmitted]);

  useEffect(() => {
    if (isReadyToSubmit) {
      submitContest(() => {
        resetGame(true);
        resetSubmitStatus();
        clear();
      });
    }
  }, [isReadyToSubmit]);

  if (error && submitStatus === 'error' && !isSubmitting) {
    return <Error message={error} />;
  }

  if (!isSubmitted && !isSubmitting) {
    return <NothingToDoHere />;
  }

  if (isSubmitted) return <Success />;

  return (
    <Wrapper>
      <Group
        specSelector="submit-contest-feedback"
        isVertical
        isCentered
        isVerticalCentered
        padding={15}
        spaceSize={60}
        style={{
          width: '100%',
          maxWidth: 450,
          margin: 'auto',
        }}>
        <div style={{ height: 100 }}>
          <Spinner size={100}>
            <Icon color={colors.lightGray} size={30} style={{ marginTop: 8 }}>
              <Trophy />
            </Icon>
          </Spinner>
        </div>
        <Group isFullWidth isVertical isCentered>
          <GeneralText
            color={colors.primary}
            style={{
              fontSize: 36,
              fontWeight: 900,
              padding: '5px 10px',
              backgroundColor: 'white',
              borderRadius: 4,
            }}>
            <em>Almost there</em>
          </GeneralText>
          <GeneralText
            color={colors.white}
            style={{ fontSize: 14, fontWeight: 900 }}>
            We are submitting your contest
          </GeneralText>
        </Group>
      </Group>
    </Wrapper>
  );
};

SubmitGame.propTypes = {
  error: PropTypes.string,
  isSubmitting: PropTypes.bool,
  resetSubmitStatus: PropTypes.func,
  submitContest: PropTypes.func,
  submitStatus: PropTypes.string,
};

export default connect(
  store => ({
    ...store.submit,
  }),
  { ...actions }
)(SubmitGame);
