import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { GeneralText, Group, Space, List } from 'atoms';
import { Fill } from 'atoms/Group';
import CardWrapper from 'molecules/CardWrapper';

const BasicTextCard = ({ title, data, isAnimated, additionalData }) => {
  return (
    <CardWrapper
      topLabel={
        typeof title === 'string' ? (
          <GeneralText isLarge color={colors.white}>
            {title}
          </GeneralText>
        ) : (
          title
        )
      }>
      <Group padding={20} isVertical>
        {additionalData && (
          <>
            <Group isFullWidth>
              {additionalData.map((item, index) => (
                <Fragment key={index}>
                  {index > 0 && <Fill />}
                  <Group spaceSize={5}>
                    <GeneralText>
                      <b>{item.label}</b>
                    </GeneralText>
                    <GeneralText>{item.value}</GeneralText>
                  </Group>
                </Fragment>
              ))}
            </Group>
            <Space isMedium isVertical />
          </>
        )}
        <List hasAnimation={isAnimated}>
          {data &&
            data.map((item, index) => (
              <Group key={index}>
                <GeneralText>
                  <b>{item.label}</b>
                </GeneralText>
                <Fill />
                <GeneralText>{item.value}</GeneralText>
              </Group>
            ))}
        </List>
      </Group>
    </CardWrapper>
  );
};

BasicTextCard.propTypes = {
  additionalData: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  isAnimated: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BasicTextCard;
