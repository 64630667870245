import { persistor } from 'core/store';
import { navigate } from '@reach/router';

// eslint-disable-next-line no-unused-vars
import localforage from 'localforage';

import { auth } from 'services/firebase';

export const resetGame = () => {
  persistor.purge();
  return { type: 'RESET_GAME' };
};

export const signOut = () => {
  const pathname = location.pathname.replace('/', '');
  persistor.purge().then(() => {
    auth()
      .signOut()
      .then(() => {
        // TODO: Commented until we have sure that is not causing any issue
        // localforage
        //   .clear()
        //   .then(() =>
        navigate(`/auth${pathname ? `?returnTo=${pathname}` : ''}`);
        // );
      });
  });
  return { type: 'RESET_GAME' };
};
