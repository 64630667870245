import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import PlayerAvatar from 'molecules/PlayerAvatar';
import Group from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Icon from 'atoms/Icon';
import { Check, Times } from 'assets/icons';

import colors from 'assets/themes/colors';

const Container = styled.div`
  padding: 10px 15px;
  background-color: #f2f2f2;
  display: flex;
  border-radius: 4px;
  position: relative;
  ${({ isBordered }) =>
    isBordered &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 2px dotted ${colors.secondary};
      }
    `}
`;

const ResultsSectionInlinePlayerCard = ({
  isBordered,
  isBackup,
  onClickPlayerInfo,
  playerName,
  playerPosition,
  playerPicture,
  stat,
  label,
  wasWin,
}) => (
  <Container isBordered={isBordered}>
    <Group
      spaceSize={15}
      isFullWidth
      isVerticalCentered
      style={{ marginTop: -15, marginBottom: -15 }}>
      <PlayerAvatar
        source={playerPicture}
        flagLabel={isBackup ? 'BACKUP' : ''}
        onClick={onClickPlayerInfo}
        isMedium
        isSecondary
      />
      <Group isVertical isGrown>
        <GeneralText>
          <strong>{playerName}</strong>
        </GeneralText>
        <GeneralText isSmall>{playerPosition}</GeneralText>
      </Group>
      <Group isVertical isGrown>
        <GeneralText isSmall>
          <strong>{stat}</strong>
        </GeneralText>

        <GeneralText isSmall color={colors.black}>
          {label}
        </GeneralText>
      </Group>
      {wasWin ? (
        <Icon size={24} color={colors.positive}>
          <Check />
        </Icon>
      ) : (
        <Icon size={24} color={colors.alert}>
          <Times />
        </Icon>
      )}
    </Group>
  </Container>
);

ResultsSectionInlinePlayerCard.defaultProps = {
  stat: '---',
  label: '---',
  playerName: '---',
  playerPicture: null,
  playerPosition: '---',
  onClickPlayerInfo: () => {},
};

ResultsSectionInlinePlayerCard.propTypes = {
  label: PropTypes.string,
  stat: PropTypes.string,
  isBackup: PropTypes.bool,
  isBordered: PropTypes.bool,
  onClickPlayerInfo: PropTypes.func,
  playerName: PropTypes.string,
  playerPicture: PropTypes.string,
  playerPosition: PropTypes.string,
  wasWin: PropTypes.bool,
};

export default ResultsSectionInlinePlayerCard;
