/* eslint-disable no-unused-vars */
import MFLogo from 'assets/media/images/meteor-logo-2@2x.png';
import MFLogoSmall from 'assets/media/logos/meteor-fantasy-logo.svg';

import YouEntered1 from 'assets/media/images/you-entered-1.png';

export default {
  // SubmitGate
  submitGate: YouEntered1,
  submitGateLandscape: YouEntered1,

  // SideDrawer
  anonymousAvatarPlaceholder: MFLogoSmall,
  avatarPlaceholder: MFLogoSmall,

  // MF Logo
  meteorFantasyLogo: MFLogo,
};
