import { useEffect, useState } from 'react';

import { api } from 'services/firebase';

import useUserData from 'hooks/useUserData';

const checkoutAddFunds = () => {
  const [session] = useUserData();
  const isVerified = session.verified;

  const [status, setStatus] = useState('waiting');

  useEffect(() => {
    return () => {
      setStatus('waiting');
    };
  }, []);

  if (!isVerified)
    return [
      "Uh oh! We couldn't verify your personal information.",
      null,
      'error',
    ];

  const call = ({ amount, useVault, cc }) => {
    return new Promise((resolve, reject) => {
      setStatus('processing');
      session.state.getIdToken().then(token => {
        api
          .addFunds({ amount, useVault, cc }, token)
          .then(() => {
            setStatus('success');
            resolve();
          })
          .catch(() => {
            setStatus('error');
            reject();
          });
      });
    });
  };

  return [call, status];
};

export default checkoutAddFunds;
