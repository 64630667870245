import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { navigate } from '@reach/router';

import { Group, Icon, GeneralText, Button } from 'atoms';
import { Times } from 'assets/icons';

import colors from 'assets/themes/colors';

import { MainContext } from 'containers/MainContainer';

import useDraft from './useDraft';

import Sport from './components/Sport';
import Wrapper from './components/Wrapper';
import Container from './components/Container';

const ContestBuilder = ({ isHidden }) => {
  const { draft, clear } = useDraft();

  const { resetGame } = useContext(MainContext);

  const { sport, type, predictions, timestamp, lineups } = draft || {};

  const hasPredictions = predictions && Object.keys(predictions).length > 0;

  const alreadyStarted = sport && type && lineups && hasPredictions;

  const defaultVisible = !isHidden && alreadyStarted;

  const [isVisible, setVisibility] = useState(defaultVisible);

  useEffect(() => {
    setVisibility(defaultVisible);
  }, [defaultVisible]);

  if (!isVisible) return null;

  const createdAt = moment(timestamp);

  return (
    <Wrapper>
      <Container>
        <Button
          isBare
          onClick={() => setVisibility(false)}
          style={{
            position: 'absolute',
            top: 16,
            right: 18,
            color: 'white',
          }}>
          <Icon size={14} color={colors.mattedGray}>
            <Times />
          </Icon>
        </Button>

        <GeneralText color={colors.white}>
          It is looks like you have an active draft contest
        </GeneralText>

        <GeneralText isSmall color={colors.lightGray}>
          created {moment(createdAt).calendar()}
        </GeneralText>

        <GeneralText isXSmall color={colors.alert}>
          *If you modify something your draft will be cleared.
        </GeneralText>

        <Group spaceSize={4} isFullWidth>
          <Button
            isXSmall
            isFullWidth
            onClick={() => navigate('/gameplay')}
            style={{ marginTop: 10 }}>
            Finish your contest
          </Button>
          <Button
            isXSmall
            isOutlined
            isNeutral
            onClick={() => {
              resetGame().then(() => {
                clear().then(() => {
                  navigate('/');
                });
              });
            }}
            style={{ marginTop: 10 }}>
            Clear draft
          </Button>
        </Group>
      </Container>
    </Wrapper>
  );
};

ContestBuilder.propTypes = {
  isHidden: PropTypes.bool,
};

export const useDraftContest = useDraft;
export const SportLogo = Sport;

export default ContestBuilder;
