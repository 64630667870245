function parseLocationComponentsByKey(components, key) {
  if (
    components.length === 0 ||
    (Object.keys(components).length === 0 && typeof components === 'object')
  ) {
    return {};
  }
  let component =
    components.find(
      component => component.types && component.types.find(type => type === key)
    ) || {};
  return {
    short: component.short_name,
    long: component.long_name,
  };
}

const useGeocode = config => {
  const { latitude, longitude, apiKey, cached } = config || {};

  const queryType = '';

  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}${queryType}&key=${apiKey}`;

  return new Promise((resolve, reject) => {
    if (cached) {
      let geocode = localStorage.getItem('geocode');
      if (geocode) {
        geocode = JSON.parse(geocode);
        const startTime = geocode.timestamp;
        const endTime = Date.now();
        const diff = endTime - startTime;
        const diffTime = diff / 1000 / 60 / 60;
        if (diffTime < 24) {
          resolve(geocode);
          return;
        }
      }
    }

    fetch(url, { method: 'GET' })
      .then(response => {
        response.json().then(({ results }) => {
          if (!results) return null;
          const result = {};
          results.find(item => {
            result.region = parseLocationComponentsByKey(
              item.address_components,
              'administrative_area_level_1'
            );
            result.country = parseLocationComponentsByKey(
              item.address_components,
              'country'
            );
            return result;
          });
          if (cached) {
            localStorage.setItem(
              'geocode',
              JSON.stringify({
                timestamp: Date.now(),
                latitude,
                longitude,
                ...result,
              })
            );
          }
          resolve(result);
        });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default useGeocode;
