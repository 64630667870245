import { useEffect, useState } from 'react';

import EventEmitter from 'eventemitter3';

import offlineDb from 'services/offlineDb';

const emitter = new EventEmitter();

const useDraft = () => {
  const defaultValue = { empty: true };

  const [draftContest, updateDraft] = useState(defaultValue);
  const [status, setStatus] = useState('waiting');

  const errorHandler = error => {
    if (!error) return;
    if (error.name === 'InvalidStateError') {
      location.reload();
      return;
    }
    setStatus('error');
  };

  const create = async sport => {
    try {
      await offlineDb.draftContest.clear();
      const newDraft = {
        id: 1,
        timestamp: Date.now(),
        sport,
      };
      await offlineDb.draftContest.add(newDraft);
      emitter.emit('draft-changes', newDraft);
    } catch (error) {
      errorHandler(error);
    }
  };

  const clear = async () => {
    try {
      await offlineDb.draftContest.clear();
      emitter.emit('draft-changes', defaultValue);
    } catch (error) {
      errorHandler(error);
    }
  };

  const update = async data => {
    try {
      await offlineDb.draftContest.update(1, data);
      const newData = await offlineDb.draftContest.get(1);
      if (newData) emitter.emit('draft-changes', newData);
      return Promise.resolve(newData);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setStatus('loading');

    emitter.on('draft-changes', updateDraft);

    offlineDb.draftContest
      .toArray()
      .then(response => {
        const data = response[0] || defaultValue;
        if (data) updateDraft(data);
        setStatus('success');
      })
      .catch(() => {
        setStatus('error');
      });

    return () => {
      emitter.off('draft-changes', updateDraft);
    };
  }, []);

  return {
    isReady: status === 'success' || status === 'error',
    draft: draftContest,
    status,
    create,
    clear,
    update,
  };
};

export default useDraft;
