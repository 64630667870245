import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Group, Icon, GeneralText } from 'atoms';
import { PaperPlane, Envelope } from 'assets/icons';
import colors from 'assets/themes/colors';

import useUserData from 'hooks/useUserData';

import { api } from 'services/firebase';

const Content = ({ onClose }) => {
  const [session] = useUserData();

  const [status, setStatus] = useState('waiting');

  const isVerified = session.emailIsVerified;

  useEffect(() => {
    if (!isVerified) {
      setStatus('processing');
      api
        .sendVerificationLink()
        .then(() => {
          setStatus('success');
        })
        .catch(() => {
          setStatus('error');
        });
    }
    return onClose;
  }, []);

  let message = '';
  let color = null;
  let icon = <PaperPlane />;

  if (status === 'processing') message = 'Sending verification link...';
  if (status === 'error')
    message = 'Something happened! Please, wait few minutes to retry.';

  if (status === 'success') {
    message = 'Verification email sent, please check your inbox!';
    color = colors.positive;
    icon = <Envelope />;
  }

  return (
    <Group
      isVertical
      isVerticalCentered
      isCentered
      spaceSize={15}
      padding={5}
      style={{ minHeight: 90, maxWidth: 210 }}>
      <Icon size={46}>{icon}</Icon>
      <GeneralText color={color}>
        <b>{message}</b>
      </GeneralText>
    </Group>
  );
};

Content.propTypes = {
  onClose: PropTypes.func,
};

export default Content;
