import { useState, useEffect } from 'react';

import moment from 'moment';

// TODO: Implement other sports date range
export const dateRange = () => {
  const start = moment()
    .subtract(1, 'day')
    .isoWeekday(2)
    .startOf('day');

  return {
    start: start.toDate(),
    end: moment(start)
      .isoWeekday(1)
      .endOf('day')
      .add(1, 'week')
      .toDate(),
    range: 'week',
  };
};

const initialState = {
  start: null,
  end: null,
  range: null,
  diff: {},
  labels: {},
};

const useSportDate = () => {
  const [dateInfo, setDateInfo] = useState(initialState);

  useEffect(() => {
    const range = dateRange();

    let dateToCompare = moment(range.end);

    const isStartDate = moment(range.start).diff(moment(), 'days') === 0;
    let isUsingReadyLimitRange = false;

    if (isStartDate) {
      const startTime = moment(range.start).add(13, 'hours');
      const currentTime = moment();
      const isLaunched = currentTime.diff(startTime);
      if (isLaunched < 0) {
        isUsingReadyLimitRange = true;
        dateToCompare = startTime;
      }
    }

    const hoursDiff = dateToCompare.diff(moment(), 'seconds');
    const daysDiff = dateToCompare.diff(moment(), 'days');

    const hours = new Date(hoursDiff * 1000).toISOString().substr(11, 2);
    const minutes = new Date(hoursDiff * 1000).toISOString().substr(14, 2);

    const plural = t => (parseInt(t) > 1 ? 's' : '');

    setDateInfo({
      ...range,
      isStartDate,
      isUsingReadyLimitRange,
      diff: {
        days: daysDiff,
        hours,
        minutes,
      },
      labels: {
        days: `${daysDiff} day${plural(daysDiff)}`,
        hours: `${hours} hour${plural(hours)}`,
        minutes: `${minutes} minute${plural(minutes)}`,
      },
    });
  }, []);

  return dateInfo;
};

export default useSportDate;
