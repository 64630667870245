import React from 'react';

import Routes from './Routes';
import Splash from 'pages/Splash';

import FieldPic from 'assets/media/images/field.png';

import { Fill } from 'atoms/Group';

import { SecurityHeader } from 'molecules';

const SignInFlow = () => {
  return (
    <Splash
      backgroundImage={FieldPic}
      style={{ display: 'flex', flexDirection: 'column' }}>
      <Routes />

      <Fill />

      <div
        style={{
          width: '100vw',
          marginLeft: 0,
          marginTop: 10,
          marginBottom: 20,
        }}>
        <SecurityHeader left="SECURE CONNECTION" right="SSL ENCRYPTION" />
      </div>
    </Splash>
  );
};

export default SignInFlow;
