import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';

import { Select } from '@rebass/forms';

import { Icon, Group, Title, Space } from 'atoms';
import { AngleDown } from 'assets/icons';

const Label = styled.label`
  position: relative;
  height: 100%;
  color: ${colors.white};
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
`;

const DayDivisor = ({ defaultDayDivisor, divisors, onSelect }) => {
  const [selection, setSelection] = useState(defaultDayDivisor);

  useEffect(() => {
    setSelection(defaultDayDivisor);
  }, [defaultDayDivisor]);

  return (
    <Group isCentered isVertical>
      <Label data-guided-tour="day-divisor" htmlFor="DayDivisor">
        <Title color={colors.white}>{selection}</Title>
        <Select
          id="DayDivisor"
          value={selection}
          onChange={event => {
            onSelect(event);
            setSelection(event.target.value);
          }}
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            border: 'none',
            width: '100%',
            height: '100%',
            fontSize: '1.3rem',
            color: 'transparent',
            bg: 'transparent',
            appearance: 'none',
            '& option': {
              color: 'primary',
            },
            '& ~ svg': {
              display: 'none',
            },
          }}>
          {divisors.map(divisor => (
            <option key={divisor} value={divisor}>
              {divisor}
            </option>
          ))}
        </Select>
        <Space isVertical />
        <Icon color={colors.white} size={16}>
          <AngleDown />
        </Icon>
      </Label>
    </Group>
  );
};

DayDivisor.defaultProps = {};

DayDivisor.propTypes = {
  defaultDayDivisor: PropTypes.string.isRequired,
  divisors: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
};

export default DayDivisor;
