import React from 'react';

import { Router } from '@reach/router';

import Home from './containers/Home';
import SignIn from './containers/SignIn';
import PasswordRecovery from './containers/PasswordRecovery';
import SignUp from './containers/SignUp';
import Actions from './containers/Actions';

const Routes = () => (
  <Router>
    <Home path="/" />
    <SignIn path="signin" />
    <PasswordRecovery path="password-recovery" />
    <SignUp path="signup" />

    <Actions path="actions" />
  </Router>
);

export default Routes;
