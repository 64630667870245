import { useEffect, useContext } from 'react';

import { AppContainerContext } from './';

const useAppContainer = config => {
  const { setHeader, toggleDrawer } = useContext(AppContainerContext);

  const { header } = config || {};

  useEffect(() => {
    setHeader(header);
    return () => {
      setHeader();
    };
  }, []);

  return {
    toggleDrawer,
    reload: () => {
      setHeader(header);
    },
  };
};

export default useAppContainer;
