import { useState, useEffect } from 'react';

const useAppVersion = config => {
  const [isUpToDate, setState] = useState(true);

  const { onChange } = config || {};

  useEffect(() => {
    let version = process.env.npm_package_version;
    let appVersion = localStorage.getItem('app_version');
    if (!appVersion) {
      appVersion = version;
      localStorage.setItem('app_version', version);
    }
    if (appVersion) {
      version = version.split('.');
      appVersion = appVersion.split('.');
      const changes = {
        major: version[0] != appVersion[0],
        minor: version[1] != appVersion[1],
        patch: version[2] != appVersion[2],
      };
      if (changes.major || changes.minor || changes.patch) {
        setState(false);
        const newVersion = version.join('.');
        localStorage.setItem('app_version', version.join('.'));
        if (onChange && typeof onChange === 'function') {
          onChange({ newVersion, oldVersion: appVersion });
        }
      }
    }
  }, []);

  return {
    isUpToDate,
    checkVersion: () => localStorage.getItem('app_version') || null,
  };
};

export default useAppVersion;
