import React from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  Space,
  GeneralText,
  VerticalDivider,
  Button,
  Badge,
  Group,
} from 'atoms';
import { SignOut, LockAlt } from 'assets/icons';
import { Fill } from 'atoms/Group';
import { Pennant } from 'assets/icons';

import useUserData from 'hooks/useUserData';

import { SportLogo } from 'organisms/ContestBuilder';

import colors from 'assets/themes/colors';

import useEntryCancellation from './useEntryCancellation';

import moment from 'moment';
import { navigate } from '@reach/router';

import { timeDiff } from 'services/utilities';

const CurrentEntries = ({ hideDrawer }) => {
  const [session] = useUserData();
  const isAnonymous = session && session.isAnonymous;

  const entries = session.currentEntries || [];

  const [
    CancelEntry,
    { confirmCancellation, cancellationList },
  ] = useEntryCancellation([...entries].map(e => e.id));

  if (isAnonymous || !entries) return null;

  const goto = path => {
    hideDrawer();
    navigate(path);
  };

  return (
    <Group
      specSelector="side-drawer-active-entries"
      isVertical
      isFullWidth
      isVerticalCentered>
      <Group isFullWidth isVerticalCentered spaceSize={8}>
        <Group isFullWidth isVerticalCentered>
          <Icon>
            <Pennant />
          </Icon>
          <GeneralText>
            <b>Active Contests</b>
          </GeneralText>
          <Fill />
          <GeneralText>
            <b>({entries.length})</b>
          </GeneralText>
        </Group>
      </Group>

      <Space isVertical size={10} />

      <Group isFullWidth isCentered isVertical spaceSize={16}>
        {entries.length === 0 && (
          <GeneralText color={colors.quaternary}>
            <b>You have no contests submitted yet</b>
          </GeneralText>
        )}

        {entries.map(entry => {
          if (!entry.timeBlock) return null;
          const isFree = entry.entryFeeInstance.entryFee === 0;
          const maxEntries = entry.entryFeeInstance.maxEntries;
          const payoutType = entry.entryFeeInstance.payout == 0 ? 50 : 25;
          const totalPrice =
            ((entry.entryFeeInstance.entryFee * maxEntries) / 100) * 90;
          let payoutValue = totalPrice / ((maxEntries / 100) * payoutType);

          if (maxEntries < 1) payoutValue = 0;

          const isInCancellation = cancellationList[entry.id];

          let timeUntilStart = timeDiff(
            moment.unix(entry.timeBlock.start.seconds)
          );

          timeUntilStart = timeUntilStart * 60; // In minutes

          const canCancel = timeUntilStart > 90;

          return (
            <Group
              specSelector="side-drawer-active-entry"
              isFullWidth
              isCentered
              isVerticalCentered
              spaceSize={6}
              style={{ opacity: isInCancellation ? 0.4 : 1 }}
              key={entry.id}>
              <SportLogo selected={entry.timeBlock.sport} size={22} />
              <VerticalDivider height={6} />

              {isFree ? (
                <Badge isSmall>
                  <b>F</b>
                </Badge>
              ) : (
                <Badge isSecondary isSmall>
                  <b>P</b>
                </Badge>
              )}

              <VerticalDivider height={6} />
              <GeneralText isSmall color={colors.tertiary}>
                {entry.entryFeeInstance.gameType === 1 ? (
                  <b>Over/Under</b>
                ) : (
                  <b>Statpicks</b>
                )}
              </GeneralText>
              <GeneralText isTruncated isSmall color={colors.tertiary}>
                ({entry.timeBlock.dayDivisor})
              </GeneralText>
              <Fill />
              {isFree ? (
                <GeneralText
                  color={colors.tertiary}
                  style={{ whiteSpace: 'nowrap' }}>
                  <b>TOP {payoutType}</b>
                </GeneralText>
              ) : (
                <>
                  <GeneralText
                    color={colors.tertiary}
                    style={{ whiteSpace: 'nowrap' }}>
                    Payout&nbsp;
                  </GeneralText>
                  <GeneralText
                    isLarge
                    color={colors.tertiary}
                    style={{ whiteSpace: 'nowrap' }}>
                    <b>${payoutValue}</b>
                  </GeneralText>
                </>
              )}
              <Button
                specSelector="side-drawer-entry-cancellation"
                isBare
                disabled={isInCancellation || !canCancel}
                onClick={() => confirmCancellation(entry.id)}>
                <Icon color={colors.mattedGray} size={16}>
                  {canCancel ? <SignOut /> : <LockAlt />}
                </Icon>
              </Button>
            </Group>
          );
        })}
      </Group>

      <Space isVertical size={15} />
      <Button isBare onClick={() => goto('/refunds')}>
        <GeneralText
          isSmall
          color={colors.accent}
          style={{ textAlign: 'center' }}>
          Please visit our Refund and Cancellation Policy
        </GeneralText>
      </Button>

      <Space isVertical size={10} />

      <Button
        isFullWidth
        isXSmall
        isSecondary
        onClick={() => goto('/account/results')}>
        All contests
      </Button>

      <CancelEntry />
    </Group>
  );
};

CurrentEntries.propTypes = {
  hideDrawer: PropTypes.func,
};

export default CurrentEntries;
