import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Group, GeneralText } from 'atoms';

const ValueAndLabel = ({ value, label, isTruncated, ...rest }) => (
  <Group isVertical isCentered {...rest}>
    <GeneralText isMedium>
      <b>{value}</b>
    </GeneralText>
    <GeneralText isSmall color={colors.black} isTruncated={isTruncated}>
      {label}
    </GeneralText>
  </Group>
);

ValueAndLabel.propTypes = {
  value: PropTypes.node,
  label: PropTypes.node,
  isTruncated: PropTypes.bool,
};

export default ValueAndLabel;
