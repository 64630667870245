import React from 'react';

import { PrivacyPolicy as PrivacyPage } from 'pages';
import Wrapper from './Wrapper';

const PrivacyPolicy = () => (
  <Wrapper title="Privacy Policies">
    <PrivacyPage />
  </Wrapper>
);

export default PrivacyPolicy;
