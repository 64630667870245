import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import colors from 'assets/themes/colors';

const fadeOut = keyframes`
  0% { opacity: .99; }
  100% { opacity: .1; }
`;

const hideAnimation = css`
  animation: ${fadeOut} 0.2s ease-out;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black};
  opacity: 0.99;
  ${({ isHidden }) => {
    return isHidden && hideAnimation;
  }}
`;

export default Wrapper;
