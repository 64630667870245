import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Button, GeneralText } from 'atoms';

const Link = ({ link }) => (
  <Button
    isSmall
    isBare
    onClick={link.onClick}
    style={{ fontWeight: 'normal', height: '40px' }}>
    <GeneralText isSmall color={colors.white}>
      <u>{link.name}</u>
    </GeneralText>
  </Button>
);

Link.propTypes = {
  link: PropTypes.exact({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export default Link;
