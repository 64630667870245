import { useState, useEffect } from 'react';

let serviceWorkerInstance = null;
let registeredServiceWorker = null;

const useServiceWorker = (fileName, callback) => {
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (!serviceWorkerInstance) {
      serviceWorkerInstance = new Promise((resolve, reject) => {
        if ('serviceWorker' in navigator) {
          const serviceWorkerFileName = fileName || 'service-worker.js';
          navigator.serviceWorker
            .register(serviceWorkerFileName)
            .then(registered => {
              if (callback && typeof callback === 'function')
                callback(registered);
              registeredServiceWorker = registered;
              setReady(true);
              registered.onupdatefound = () => {
                const installingWorker = registered.installing;
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  }
                };
              };
            })
            .catch(error => {
              reject(error);
            });
        }
      });
    }
  }, []);

  return {
    hasServiceWorker: serviceWorkerInstance !== null,
    serviceWorkerInstance: serviceWorkerInstance,
    registeredServiceWorker: registeredServiceWorker,
    isReady,
  };
};

export default useServiceWorker;
