/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import colors from 'assets/themes/colors';

import Button from 'atoms/Button';
import Icon from 'atoms/Icon';
import { Times } from 'assets/icons';

const slideUpSoft = keyframes`
  0% {
    opacity: .1;
    transform-origin: center;
    transform: translateY(4%);
  }
  100% {
    opacity: .99;
    transform-origin: center;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  0% { opacity: .1; }
  100% { opacity: .99; }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
  ${({ isOpen }) =>
    isOpen
      ? css`
          height: 100%;
          opacity: 1;
          width: 100%;
          ${Background} {
            opacity: 1;
          }
          ${Content} {
            animation: ${slideUpSoft} 0.3s ease-in-out;
            opacity: 1;
          }
        `
      : css`
          height: 0;
          opacity: 0;
          width: 0;
        `}
`;

const Header = styled.div`
  align-items: center;
  z-index: 100;
  background-color: ${colors.white};
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 10px 15px 0;
  border-radius: 3px;
`;

const Content = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  width: ${({ isCovering }) => (isCovering ? '100%' : 'auto')};
  height: ${({ isCovering }) => (isCovering ? '100%' : 'auto')};
  opacity: 0;
  position: absolute;
  z-index: 100;
`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const Background = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const ModalBody = ({
  isLightbox,
  isCovering,
  customHeader,
  isOpen,
  children,
  disableClose,
  onClickToClose,
  specSelector,
}) => (
  <Wrapper data-spec-selector={specSelector} isOpen={isOpen}>
    {isLightbox ? (
      children
    ) : (
      <Content isCovering={isCovering}>
        {customHeader
          ? customHeader({ isOpen, onClickToClose })
          : !disableClose && (
              <Header>
                <Button
                  onClick={onClickToClose}
                  style={{ padding: 0, background: 'none' }}>
                  <Icon size={20}>
                    <Times />
                  </Icon>
                </Button>
              </Header>
            )}
        <Body>{children}</Body>
      </Content>
    )}
    <Background onClick={!disableClose ? onClickToClose : null} />
  </Wrapper>
);

ModalBody.defaultProps = {
  isCovering: true,
};

ModalBody.propTypes = {
  children: PropTypes.any,
  customHeader: PropTypes.func,
  disableClose: PropTypes.bool,
  isCovering: PropTypes.bool,
  isLightbox: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClickToClose: PropTypes.func,
  specSelector: PropTypes.string,
};

const body = document.body;

class Portal extends React.PureComponent {
  componentDidMount() {
    body.appendChild(this.element);
  }
  componentWillUnmount() {
    body.removeChild(this.element);
  }
  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return ReactDOM.createPortal(this.props.children, this.element);
  }
  element = document.createElement('div');
}

const Modal = props => (
  <Portal>
    <ModalBody {...props} />
  </Portal>
);

Modal.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

Modal.Content = styled.div`
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px;
`;

Modal.Footer = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
`;

export default Modal;
