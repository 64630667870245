import React from 'react';
import PropTypes from 'prop-types';
import Group, { Fill } from 'atoms/Group';
import colors from 'assets/themes/colors';

const HeaderContainer = ({
  leftComponent,
  centerComponent,
  rightComponent,
}) => {
  return (
    <Group
      isFullWidth
      isCentered
      isVertical
      isVerticalCentered
      padding="0 20px 0 18px"
      style={{
        backgroundColor: colors.primary,
        height: 68,
        width: '100%',
      }}>
      <Group
        isFullWidth
        isCentered
        isVerticalCentered
        spaceSize={15}
        css={{ height: '40px', justifyContent: 'flex-start' }}>
        <Group
          isVertical
          isVerticalCentered
          data-guided-tour="header-left-component"
          style={{ alignItems: 'flex-start' }}>
          {leftComponent}
        </Group>
        <Fill style={{ textAlign: 'left' }}>{centerComponent}</Fill>
        <Group
          isVertical
          isVerticalCentered
          data-guided-tour-emulate-click="header-right-component"
          data-guided-tour="header-right-component"
          style={{ alignItems: 'flex-end' }}>
          {rightComponent}
        </Group>
      </Group>
    </Group>
  );
};

HeaderContainer.propTypes = {
  leftComponent: PropTypes.element,
  centerComponent: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  rightComponent: PropTypes.element,
  componentSpacing: PropTypes.number,
  isGrownLeft: PropTypes.bool,
  isGrownCenter: PropTypes.bool,
  isGrownRight: PropTypes.bool,
};

HeaderContainer.defaultProps = {
  isGrownLeft: false,
  isGrownCenter: true,
  isGrownRight: false,
};

export default HeaderContainer;
