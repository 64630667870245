import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Button, Group, Icon, Space } from 'atoms';
import { Fill } from 'atoms/Group';

const OuterWrapper = styled(Group)`
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
`;
const OptionContainerWrapper = styled(Group)`
  background-color: ${colors.white};
`;
const OptionWrapper = styled(Button)`
  width: 100%;
  :focus,
  :active {
    background-color: ${colors.lightGray};
  }
`;

const IconWrapper = styled(Group)`
  width: 20px;
`;

const AccountMenu = ({ options, onClickBackground }) => (
  <OuterWrapper isVertical isFullWidth onClick={onClickBackground}>
    <Fill />
    <OptionContainerWrapper isVertical isFullWidth>
      {options.map(option => (
        <OptionWrapper
          isNeutral
          isFullWidth
          key={option.name}
          onClick={option.onClick}>
          <IconWrapper isCentered isVerticalCentered>
            <Icon color={colors.primary} size={20}>
              {option.icon}
            </Icon>
          </IconWrapper>
          <Space isSmall />
          {option.name}
          <Fill />
          <Space isMedium />
        </OptionWrapper>
      ))}
    </OptionContainerWrapper>
  </OuterWrapper>
);

AccountMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
    })
  ),
  onClickBackground: PropTypes.func.isRequired,
};

export default AccountMenu;
