import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import colors from 'assets/themes/colors';
import { ContainerWithBackground } from 'molecules';

const applyColor = ({ backgroundColor }) =>
  css`
    background-color: ${backgroundColor};
  `;

const OuterWrapper = styled.div`
  ${applyColor};
  position: relative;
  min-height: 100%;
  height: 100vh;
  overflow: auto;
  width: 100%;
  max-width: 100%;
`;

const ForegroundWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

const Splash = ({ backgroundColor, backgroundImage, children, ...rest }) => (
  <OuterWrapper backgroundColor={backgroundColor}>
    <ContainerWithBackground source={backgroundImage} height="300px">
      &nbsp;
    </ContainerWithBackground>
    <ForegroundWrapper {...rest}>{children}</ForegroundWrapper>
  </OuterWrapper>
);

Splash.defaultProps = {
  backgroundColor: colors.primary,
  backgroundImage: '',
};

Splash.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
};

export default Splash;
