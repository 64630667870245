export const selectGameType = (type, isFree) => ({
  type: 'SELECT_GAME_TYPE',
  payload: {
    type,
    modality: isFree ? 'free' : 'paid',
  },
});

export const selectSport = sport => ({
  type: 'SELECT_SPORT',
  payload: {
    sport,
  },
});
