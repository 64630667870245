import card from './card';

export default {
  avatar: {
    width: 'avatar',
    height: 'avatar',
    borderRadius: 'circle',
  },

  card,

  link: {
    color: 'primary',
    ':hover': {
      color: 'primaryHover',
    },
    cursor: 'pointer',
  },
};
