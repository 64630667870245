//TODO: Remove line after re-enabling (see issue: #271)
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Router, navigate } from '@reach/router';

import colors from 'assets/themes/colors';
import { Group } from 'atoms';
import { MainTitleWithAvatar, Tab, TabBar } from 'molecules';
import {
  AccountTransactions,
  AccountOverview,
  AccountResults,
} from 'organisms';

import { TabLabel, Shadow, Wrapper } from './components';
// import { getTabNameFromPath } from 'services/utilities';

const activeTabStyles = {
  borderBottom: `2px solid ${colors.accent}`,
  color: `${colors.accent}`,
};

const tabStyles = {
  flexBasis: '100%',
  color: `${colors.primary}`,
};

const pathWhitelist = ['profile', 'results', 'transactions'];

const UserAccount = ({ transactions }) => {
  const [tabName, setTabName] = useState(
    location.pathname.replace('/account/', '')
  );
  useEffect(() => {
    if (!tabName || !pathWhitelist.includes(tabName)) {
      setTabName('profile');
      navigate('/account/profile');
    }
  }, [tabName]);

  return (
    <Wrapper>
      <MainTitleWithAvatar />

      <TabBar initialTab={tabName}>
        <nav>
          <Group
            style={{
              backgroundColor: colors.white,
              justifyContent: 'space-around',
            }}>
            <Tab
              target="profile"
              isActiveStyles={activeTabStyles}
              style={tabStyles}
              onClick={target => navigate(`/account/${target}`)}>
              <TabLabel label="Profile" />
            </Tab>
            <Tab
              target="results"
              isActiveStyles={activeTabStyles}
              style={tabStyles}
              onClick={target => navigate(`/account/${target}`)}>
              <TabLabel label="Results" />
            </Tab>
            {/* <Tab
              target="transactions"
              isActiveStyles={activeTabStyles}
              style={tabStyles}
              onClick={navigate}>
              <TabLabel label="Transactions" />
            </Tab> */}
          </Group>
        </nav>
        <Shadow />
      </TabBar>

      <Router>
        <AccountOverview path="profile" />
        <AccountResults path="results" />
      </Router>
    </Wrapper>
  );
};

UserAccount.defaultProps = {
  filePath: '',
};

UserAccount.propTypes = {
  transactions: PropTypes.array,
};

export default UserAccount;
