import { useEffect, useState } from 'react';

import Rollbar from 'rollbar';

import { auth } from 'services/firebase';

let rollbar = null;

const useRollbar = () => {
  if (location.hostname === 'localhost') return;
  const [instance, setInstance] = useState(null);
  useEffect(() => {
    if (!rollbar) {
      rollbar = new Rollbar({
        accessToken: '97bd6423ce004500828c6d0cb7e694ae',
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.NODE_ENV,
        },
      });
    }
    setInstance(rollbar);
  }, []);

  useEffect(() => {
    if (auth().currentUser && rollbar) {
      rollbar.configure({
        payload: {
          person: {
            id: auth().currentUser.uid,
            email: auth().currentUser.email,
          },
        },
      });
    }
  }, [auth().currentUser]);

  return instance;
};

export default useRollbar;
