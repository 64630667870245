import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'main',
  storage,
  blacklist: ['isLoading', 'error'],
};

const INITIAL_STATE = {
  userData: null,
  isLoading: null,
  error: null,
};

export default persistReducer(
  persistConfig,
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case 'FETCH_USER_DATA':
        return (state = {
          ...state,
          isLoading: true,
          error: null,
        });

      case 'FETCH_USER_DATA_SUCCESS':
        return (state = {
          ...state,
          userData: action.payload,
          isLoading: false,
          error: null,
        });

      case 'FETCH_USER_DATA_ERROR':
        return (state = {
          ...state,
          userData: null,
          isLoading: false,
          error: action.payload,
        });

      default:
        return state;
    }
  }
);
