import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const applyHeight = ({ height }) =>
  css`
    height: ${height};
  `;

const Wrapper = styled.div`
  ${applyHeight}
`;

const Container = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
);

Container.defaultProps = {
  height: 100,
};

Container.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
};

export default Container;
