import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Button, GeneralText, Group } from 'atoms';

const StyledButton = styled(Button)`
  color: inherit;
  flex-basis: 100%;
  height: 100%;
  padding: 10px;
`;

const TabLabel = ({ label, style }) => (
  <Group isGrown isCentered isFullWidth>
    <StyledButton isBare style={style}>
      <GeneralText isMedium color="inherit">
        <b>{label}</b>
      </GeneralText>
    </StyledButton>
  </Group>
);

TabLabel.defaultProps = {
  label: 'TabLabel',
  style: {},
};

TabLabel.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default TabLabel;
