import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Icon, Button, Group, Title, GeneralText, Space, Badge } from 'atoms';

import { Coins } from 'assets/icons';

import { Fill } from 'atoms/Group';
import { deepKey, sortBy } from 'services/utilities';

import ImageWrapper from 'atoms/ImageWrapper';

import PlayerResultsCard from './PlayerResultsCard';

import { SportLogo } from 'organisms/ContestBuilder';

const ResultsContainer = ({ data, uid, onClickToClose }) => {
  if (!data) return null;

  const gameType =
    data.entryFeeInstance.gameType === 1 ? `Over & Under` : `Statpicks`;

  const sport = String(data.timeBlock.sport).toLowerCase();

  const entryFeeInstanceWinners =
    deepKey(data, 'entryFeeInstance.results.winners') || [];
  const isWinner = entryFeeInstanceWinners.includes(uid);
  const promoCashWon = isWinner
    ? data.entryFeeInstance.promoCashPrizePerUser
    : 0;
  const isFreeGame = data.entryFeeInstance.entryFee === 0;
  const isOverUnder = data.entryFeeInstance.gameType === 1;

  const predictions = data.predictions;

  const competitors = [];

  competitors.push({
    ...data.competitionLineups[0],
  });

  competitors.push({
    ...data.competitionLineups[1],
  });

  let timeRange = `Week: ${data.timeBlock.week}`;

  if (sport === 'nba') {
    timeRange = data.timeBlock.dayDivisor;
  }

  let totalPoints = 0;
  Object.keys(predictions).forEach(pk => {
    const metricName = Object.keys(predictions[pk])[0];
    totalPoints =
      totalPoints + (predictions[pk][metricName].predictionPoints || 0);
  });

  return (
    <Group isVertical isCentered isFullWidth>
      <Group
        isFullWidth
        isVerticalCentered
        spaceSize={6}
        padding={'0 0 10px'}
        style={{
          borderBottom: `2px solid ${
            isWinner ? colors.positive : colors.mattedGray
          }`,
        }}>
        <SportLogo selected={sport} height={28} />
        <GeneralText isMedium color={colors.primary}>
          <b>{gameType}</b>
        </GeneralText>
        {isFreeGame && (
          <Badge isSmall color={colors.accent}>
            FREE
          </Badge>
        )}
        <Fill />
        <GeneralText isMedium color={colors.primary}>
          <b>{timeRange}</b>
        </GeneralText>
      </Group>

      <Space isVertical size={10} />

      <Group isVertical isFullWidth spaceSize={25}>
        {competitors.map((item, index) => {
          const isHome = item.homeTeam;
          const logo = isHome ? item.homeTeamLogoUrl : item.awayTeamLogoUrl;

          return (
            <Group isVertical isCentered isFullWidth key={index}>
              <Group isVerticalCentered isFullWidth spaceSize={6}>
                <GeneralText isLarge>Team</GeneralText>
                <Fill />
                <Title>{item.teamName}</Title>
                <ImageWrapper source={logo} height={24} alt={item.teamName} />
              </Group>
              <Space isVertical isMedium />
              <Group isVertical isFullWidth spaceSize={20}>
                {sortBy(item.competitors, 'isBackupCompetitor').map(player => {
                  return (
                    <PlayerResultsCard
                      data={player}
                      key={player.id}
                      isOverUnder={isOverUnder}
                      predictions={predictions}
                    />
                  );
                })}
              </Group>
            </Group>
          );
        })}
      </Group>

      <Space isVertical isMedium />

      <Fill />

      <Group
        isFullWidth
        padding={'10px 0'}
        isVertical
        style={{
          borderTop: `1px solid ${colors.mattedGray}`,
        }}>
        <Group isFullWidth isVerticalCentered padding={'0 0 4px'} spaceSize={5}>
          <GeneralText color={colors.tertiary}>
            <b>{isOverUnder ? `Total points` : `Total points earned`}</b>
          </GeneralText>
          <Fill />
          <Icon color={colors.tertiary} size={12}>
            <Coins />
          </Icon>
          <GeneralText color={colors.tertiary}>
            <b>{Number(totalPoints.toFixed(2))}</b>
          </GeneralText>
        </Group>

        <Group
          isFullWidth
          padding={'5px 0 0'}
          spaceSize={5}
          style={{
            borderTop: `1px solid ${colors.quaternary}`,
          }}>
          <GeneralText isMedium color={colors.primary}>
            <b>{isFreeGame ? `Promo Cash Won` : `Money Won`}</b>
          </GeneralText>
          <Fill />
          <GeneralText isMedium color={colors.primary}>
            <b>
              {/* TODO: Implement real money */}
              {(promoCashWon && `M$ ${promoCashWon.toFixed(2)}`) || `M$ 0.00`}
            </b>
          </GeneralText>
        </Group>
      </Group>

      <Space isVertical />
      <Button onClick={onClickToClose} isFullWidth>
        Close
      </Button>
    </Group>
  );
};

ResultsContainer.propTypes = {
  data: PropTypes.object,
  onClickToClose: PropTypes.func,
  uid: PropTypes.string,
};

export default ResultsContainer;
