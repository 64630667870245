import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'gameplay',
  storage,
  blacklist: ['isLoading', 'error'],
  stateReconciler: autoMergeLevel2,
};

const INITIAL_STATE = {
  entryFees: [],
  selectedEntryFee: null,
  predictions: {},
  isLoading: null,
  error: null,
};

export default persistReducer(
  persistConfig,
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case 'FETCH_ENTRY_FEES':
        return (state = { ...state, isLoading: true });

      case 'FETCH_ENTRY_FEES_SUCCESS':
        return (state = {
          ...state,
          isLoading: false,
          entryFees: action.payload,
        });

      case 'FETCH_ENTRY_FEES_ERROR':
        return (state = {
          ...state,
          isLoading: false,
          error: action.payload,
        });

      case 'SELECT_ENTRY_FEE':
        return (state = { ...state, selectedEntryFee: action.payload });

      case 'SETUP_PREDICTIONS':
        return (state = {
          ...state,
          predictions: action.payload,
        });

      case 'UPDATE_GAME':
        return (state = {
          ...state,
          predictions: {
            ...state.predictions,
            [action.payload.cardId]: action.payload.selection,
          },
        });

      case 'SELECT_GAME_TYPE':
        return (state = { ...state });

      case 'RESET_PREDICTIONS':
        return (state = { ...state, predictions: {} });

      case 'RESET_GAME':
        return (state = INITIAL_STATE);

      default:
        return state;
    }
  }
);
