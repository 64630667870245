import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { GeneralText, Group, Icon } from 'atoms';
import { CaretDownSolid } from 'assets/icons';

const CompetitionVersus = ({ startTime, date, ...rest }) => {
  return (
    <Group isVertical isCentered spaceSize={0} {...rest}>
      <Icon color={colors.accent} size={24}>
        <CaretDownSolid />
      </Icon>
      <GeneralText>
        <strong>{startTime}</strong>
      </GeneralText>
      <GeneralText>{date}</GeneralText>
    </Group>
  );
};

CompetitionVersus.propTypes = {
  startTime: PropTypes.string,
  date: PropTypes.string,
};

export default CompetitionVersus;
