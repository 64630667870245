import { takeLatest, put, call, all } from 'redux-saga/effects';

import { api } from 'services/firebase';

function* verifyAccount({ body, token }) {
  try {
    const response = yield call(api.verifyAccount, body, token);
    yield put({ type: 'VERIFY_ACCOUNT_SUCCESS', payload: response });
  } catch (error) {
    yield put({ type: 'VERIFY_ACCOUNT_ERROR', payload: error });
  }
}

export default function* root() {
  yield all([takeLatest('VERIFY_ACCOUNT', verifyAccount)]);
}
