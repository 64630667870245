import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TabBarProvider } from './TabBarContext';

const TabBar = ({ initialTab, children }) => {
  const [tab, setTab] = useState(initialTab);

  useEffect(() => {
    setTab(initialTab);
  }, [initialTab]);

  return (
    <TabBarProvider value={{ activeTab: tab, setTab }}>
      {children}
    </TabBarProvider>
  );
};

TabBar.defaultProps = {};

TabBar.propTypes = {
  initialTab: PropTypes.string,
  children: PropTypes.node,
};

export default TabBar;
