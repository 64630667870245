import { eventChannel } from 'redux-saga';
import { takeLatest, put, take, call, all } from 'redux-saga/effects';

import { users } from 'services/firebase';

function* fetchUserData({ uid }) {
  const createChannel = () =>
    eventChannel(emit => {
      users.get(uid, emit).catch(error => emit(new Error(error)));
      return () => {};
    });
  const channel = yield call(createChannel);
  while (true) {
    try {
      let response = yield take(channel);
      yield put({ type: 'FETCH_USER_DATA_SUCCESS', payload: response });
    } catch (error) {
      yield put({ type: 'FETCH_USER_DATA_ERROR', payload: error });
    }
  }
}

export default function* root() {
  yield all([takeLatest('FETCH_USER_DATA', fetchUserData)]);
}
