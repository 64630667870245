import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from 'rebass';

export const Col = ({ children, ...rest }) => <Flex {...rest}>{children}</Flex>;

export const Row = ({ children, isGrown, ...rest }) => (
  <Box sx={{ flexGrow: isGrown ? 1 : 0 }} {...rest}>
    {children}
  </Box>
);

Col.propTypes = {
  children: PropTypes.element,
};

Row.propTypes = {
  isGrown: PropTypes.bool,
  children: PropTypes.any,
};
