import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { GeneralText, Group } from 'atoms';
import { StatsRow } from 'molecules';
import { TransactionCard } from 'organisms';

const AccountTransactions = ({ transactions, summaryInfo }) => (
  <>
    <StatsRow stats={summaryInfo} spaceSize={0} />
    <Group isCentered style={{ backgroundColor: colors.white }}>
      <GeneralText color={colors.black}>
        {transactions.length > 0
          ? 'The contest has ended and here are your results:'
          : 'No cash transactions yet!'}
      </GeneralText>
    </Group>
    <div css={{ backgroundColor: colors.white, height: '51px' }} />
    <Group
      isVertical
      isCentered
      padding={20}
      spaceSize={20}
      css={{
        position: 'relative',
        top: '-51px',
      }}>
      {transactions.map(transaction => (
        <TransactionCard key={transaction.num} transaction={transaction} />
      ))}
    </Group>
  </>
);

AccountTransactions.defaultProps = {
  transactions: [],
};

AccountTransactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.exact({
      type: PropTypes.string,
      num: PropTypes.number,
      label: PropTypes.string,
      dateTime: PropTypes.string,
      entryFee: PropTypes.number,
      amount: PropTypes.number,
      isPromo: PropTypes.bool,
    })
  ),
  summaryInfo: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default AccountTransactions;
