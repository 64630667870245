import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import Group from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Icon from 'atoms/Icon';
import { Clock, Sun, SunHaze, Moon, CoffeeTogo } from 'assets/icons';

import colors from 'assets/themes/colors';

const Item = styled.button`
  position: relative;
  outline: none;
  flex: 1;
  height: 34px;
  appearance: none;
  border: none;
  border-radius: 4px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-basis: auto;
  background: ${({ theme }) => theme};
`;

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  top: 2px;
  right: 2px;
  z-index: 1;
  background-color: ${colors.accent};
`;

const TimeBlockSelector = ({ initialSelected, items, onChange }) => {
  const [selected, select] = useState();

  useEffect(() => {
    select(initialSelected);
  }, [initialSelected]);

  // TODO: Change themes array to object

  const themes = [
    [
      `linear-gradient(180deg, #3984cb 70%, #539de2 70%)`,
      colors.white,
      CoffeeTogo,
      '#539de2',
    ],
    [
      `linear-gradient(180deg, #ffc841 70%, #ffb421 70%)`,
      colors.black,
      Sun,
      '#ff9221',
    ],
    [
      `linear-gradient(180deg, #ffa541 70%, #ff9221 70%)`,
      colors.black,
      SunHaze,
      '#ffc841',
    ],
    [
      `linear-gradient(180deg, #272861 70%, #313278 70%)`,
      colors.white,
      Moon,
      '#40419a',
    ],
  ];

  const disabled = [
    `linear-gradient(180deg, ${colors.secondary} 70%, ${colors.quaternary} 70%)`,
    colors.tertiary,
    colors.quaternary,
  ];

  return (
    <Group isFullWidth style={{ zIndex: 0 }}>
      {items.map((block, index) => {
        let themeStyles = themes[index];
        if (!themeStyles) themeStyles = themes[0];

        let IconName = themeStyles[2];

        let background = themeStyles[0];
        let textColor = themeStyles[1];
        let iconColor = themeStyles[3];

        if (block.value !== selected) {
          background = disabled[0];
          textColor = disabled[1];
          iconColor = disabled[2];
        }

        return (
          <Item
            onClick={() => {
              select(block.value);
              onChange(block.value);
            }}
            theme={background}
            key={index}>
            <GeneralText
              isSmall
              color={textColor}
              style={{
                zIndex: 10,
              }}>
              <b>{block.label}</b>
            </GeneralText>

            <Badge>
              {block.size ? (
                <GeneralText
                  isXSmall
                  color={colors.white}
                  style={{ marginBottom: 2 }}>
                  <b>{block.size}</b>
                </GeneralText>
              ) : (
                <Icon size={10} color={colors.white}>
                  <Clock />
                </Icon>
              )}
            </Badge>

            <Icon
              color={iconColor}
              size={17}
              style={{
                position: 'absolute',
                zIndex: 0,
                left: 5,
                top: 5,
              }}>
              <IconName />
            </Icon>
          </Item>
        );
      })}
    </Group>
  );
};

TimeBlockSelector.propTypes = {
  initialSelected: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
};

export default TimeBlockSelector;
