import React from 'react';

import { RefundPolicy as RefundPage } from 'pages';
import Wrapper from './Wrapper';

const RefundPolicy = () => (
  <Wrapper title="Refund Policies">
    <RefundPage />
  </Wrapper>
);

export default RefundPolicy;
