import React from 'react';
import PropTypes from 'prop-types';

import PlayerAvatar from 'molecules/PlayerAvatar';
import { Group, Badge, GeneralText } from 'atoms';
import { Fill } from 'atoms/Group';

import colors from 'assets/themes/colors';

import { positions } from 'services/friendlyNames';
import { camelCaseToSpace } from 'services/utilities';

const PlayerResultsCard = ({ data, isOverUnder, predictions }) => {
  const {
    firstName,
    lastName,
    headshotUrl,
    position,
    id,
    metricsAvgsCY,
  } = data.competitor;

  const uncountedBackupCompetitor = data.isBackupCompetitor && !data.played;
  const overOrUnder = metricsAvgsCY
    ? data.metricResult > metricsAvgsCY[data.metric]
      ? 'Over'
      : 'Under'
    : '-';

  let entryPrediction = predictions[id][data.metric].metricPrediction;
  const points = predictions[id][data.metric].predictionPoints || 0;

  if (isOverUnder) {
    entryPrediction = entryPrediction === 1 ? 'Over' : 'Under';
  }

  const sport = String(data.competitor.sport).toLowerCase();

  return (
    <Group isVertical spaceSize={10} isFullWidth>
      <Group
        spaceSize={6}
        isFullWidth
        isVerticalCentered
        style={{
          opacity: uncountedBackupCompetitor ? 0.6 : 1,
        }}>
        <PlayerAvatar source={headshotUrl} isSmall isSecondary />
        <Group isGrown>
          <Group isVertical>
            <Group isVerticalCentered style={{ maxWidth: 120 }}>
              <GeneralText isTruncated>
                <strong>{firstName + ' ' + lastName}</strong>
              </GeneralText>
              {data.isBackupCompetitor ? (
                <Badge isSmall isDanger>
                  B
                </Badge>
              ) : (
                ''
              )}
            </Group>
            <GeneralText isSmall>{positions[sport][position]}</GeneralText>
          </Group>
          <Fill />
          <Group isVertical style={{ alignItems: 'flex-end' }}>
            <GeneralText style={{ textTransform: 'capitalize' }}>
              <strong>{camelCaseToSpace(data.metric)}</strong>
            </GeneralText>
            {uncountedBackupCompetitor ? (
              <GeneralText color={colors.tertiary} isSmall>
                <b>NOT COUNTED</b>
              </GeneralText>
            ) : (
              <GeneralText isSmall>
                Result:{' '}
                <b>
                  {data.metricResult}
                  {isOverUnder ? ` (${overOrUnder})` : ''}
                </b>
              </GeneralText>
            )}
          </Group>
        </Group>
      </Group>

      {!uncountedBackupCompetitor && (
        <Group
          isFullWidth
          isVerticalCentered
          spaceSize={6}
          padding={6}
          style={{
            backgroundColor: colors.lightGray,
            borderRadius: 4,
          }}>
          <GeneralText>
            Your prediction: <strong>{entryPrediction}</strong>
          </GeneralText>
          <Fill />
          <GeneralText>
            Points: <strong>{Number(points.toFixed(2))}</strong>
          </GeneralText>
        </Group>
      )}
    </Group>
  );
};

PlayerResultsCard.propTypes = {
  data: PropTypes.object,
  isOverUnder: PropTypes.bool,
  predictions: PropTypes.object,
};

export default PlayerResultsCard;
