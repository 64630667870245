import React from 'react';
import PropTypes from 'prop-types';

import { Router } from '@reach/router';

import {
  About,
  Contact,
  HowToPlay,
  PrivacyPolicy,
  RefundPolicy,
  ResponsiblePlay,
  Terms,
} from './components';

import { connect } from 'react-redux';
// import * as actions from './actions';

export const GameType = ({ navigate }) => {
  return (
    <Router>
      <About path="about" />
      <Contact path="contact" />
      <HowToPlay path="how-to-play" />
      <PrivacyPolicy path="privacy" />
      <RefundPolicy path="refunds" />
      <ResponsiblePlay path="responsible-play" navigate={navigate} />
      <Terms path="terms" />
    </Router>
  );
};

GameType.propTypes = {
  navigate: PropTypes.func,
};

export default connect(
  store => ({
    ...store.gameType,
  })
  // { ...actions }
)(GameType);
