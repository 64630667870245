import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'atoms/Spinner';

import Wrapper from './components/Wrapper';

import _ContainerWithLoading from './components/ContainerWithLoading';

const timeout = setTimeout;

const LoadingScreen = ({ isHidden, onHide }) => {
  const [showSpinner, setSpinner] = useState(false);

  useEffect(() => {
    document.body.setAttribute(
      'style',
      `overflow: ${isHidden ? 'initial' : 'hidden'};`
    );
    timeout(() => {
      if (!isHidden) setSpinner(true);
    }, 430);
    return () => {
      clearTimeout(timeout);
    };
  }, [isHidden]);

  return (
    <Wrapper onAnimationEnd={onHide} {...{ isHidden }}>
      {showSpinner && <Spinner size={40} />}
    </Wrapper>
  );
};

LoadingScreen.propTypes = {
  isHidden: PropTypes.bool,
  onHide: PropTypes.func,
};

export const ContainerWithLoading = _ContainerWithLoading;

export default LoadingScreen;
