/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import theme from 'assets/themes/default';
import { AngleRight } from 'assets/icons';
import { Group, Icon } from 'atoms';
import TableRow from 'molecules/TableRow';

const ContainerWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const TableWrapper = styled.table`
  border-spacing: 0;
`;

const RightArrowWrapper = styled(Group)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
  background-image: linear-gradient(
    to right,
    transparent,
    ${theme.colors.primary}
  );
`;

const Table = ({ rows }) => {
  const wrapperRef = useRef(null);
  const tableRef = useRef(null);
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  useEffect(() => setIsComponentMounted(true), []);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ContainerWrapper ref={wrapperRef}>
        <TableWrapper ref={tableRef}>
          <thead>
            <TableRow
              isHeader
              backgroundColor={theme.colors.primary}
              textColor={theme.colors.white}>
              {rows[0]}
            </TableRow>
          </thead>
          <tbody>
            {rows.map((row, i) => {
              const isOddRow = i % 2 === 1 ? true : false;

              const backgroundColor = isOddRow
                ? theme.colors.secondary
                : theme.colors.white;

              const isFirstCellInRow = i === 0 ? true : false;

              return isFirstCellInRow ? null : (
                <TableRow backgroundColor={backgroundColor}>{row}</TableRow>
              );
            })}
          </tbody>
        </TableWrapper>
        {isComponentMounted &&
        tableRef.current.scrollWidth > wrapperRef.current.clientWidth ? (
          <RightArrowWrapper isCentered isVerticalCentered>
            <Icon size={32} color={theme.colors.white}>
              <AngleRight />
            </Icon>
          </RightArrowWrapper>
        ) : null}
      </ContainerWrapper>
    </div>
  );
};

Table.defaultProps = {
  rows: [[]],
};

Table.propTypes = {
  rows: PropTypes.array,
};

export default Table;
