import { px2Rem } from '../globalStyles';

import variants from './variants/misc';
import buttons from './variants/buttons';

import colors from './colors';

export default {
  colors,
  fonts: {
    body: "'Nunito Sans', sans-serif",
    heading: 'inherit',
    text: 'inherit',
  },
  fontSizes: px2Rem([12, 14, 16, 18, 22, 34]),
  sizes: {
    avatar: 48,
  },

  shadows: {
    card: '0 0 4px rgba(0, 0, 0, 0.125)',
  },

  variants,

  /**
   * Text component variants
   */
  text: {
    xSmall: {
      fontSize: '10px',
    },
    small: {
      fontSize: 0,
    },
    regular: {
      fontSize: 1,
    },
    medium: {
      fontSize: 2,
    },
    large: {
      fontSize: 3,
    },
  },

  /**
   * Heading component variants
   */
  heading: {
    small: {
      fontSize: 2,
    },
    regular: {
      fontSize: 3,
    },
    medium: {
      fontSize: 4,
    },
    large: {
      fontSize: 5,
    },
  },

  buttons,
};
