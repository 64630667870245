import React from 'react';
import PropTypes from 'prop-types';

import { navigate } from '@reach/router';

import Wrapper from './Wrapper';

import colors from 'assets/themes/colors';
import { Button, Group, GeneralText, Icon } from 'atoms';

import { UserCircle } from 'assets/icons';

const Error = ({ message }) => {
  return (
    <Wrapper>
      <Group
        isVertical
        isCentered
        isVerticalCentered
        padding={20}
        spaceSize={60}
        style={{
          width: '100%',
          maxWidth: 450,
          margin: 'auto',
        }}>
        <GeneralText color={colors.white} isLarge>
          {message}
        </GeneralText>
        <div style={{ height: 100 }}>
          <Icon color={colors.white} size={110}>
            <UserCircle />
          </Icon>
        </div>
        <GeneralText color={colors.white} isLarge>
          <b>Try again or contact support!</b>
        </GeneralText>
      </Group>
      <div
        style={{
          width: '100%',
          padding: '15px 15px 15px',
          backgroundColor: 'white',
        }}>
        <Button
          isFullWidth
          style={{ maxWidth: 450, margin: 'auto' }}
          onClick={() => navigate('/gameplay')}>
          GO BACK
        </Button>
      </div>
    </Wrapper>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
