import React from 'react';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import { Button, GeneralText, Group, Icon, Space } from 'atoms';

import { CheckCircle } from 'assets/icons';

const AlreadyVerified = () => {
  return (
    <Group isVertical isCentered spaceSize={20} padding={30}>
      <Space isVertical size={40} />
      <Icon size={80} color={colors.positive}>
        <CheckCircle />
      </Icon>
      <GeneralText isMedium>
        <b>ACCOUNT VERIFIED!</b>
      </GeneralText>
      <GeneralText isMedium>
        Your account is ready to play paid games and earn money.
      </GeneralText>
      <Button isSmall onClick={() => navigate('/gameplay')}>
        GO BACK TO CONTESTS
      </Button>
      <Space isVertical size={40} />
    </Group>
  );
};

export default AlreadyVerified;
