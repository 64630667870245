import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './components/Wrapper';
import Attention from './components/Attention';
import Description from './components/Description';

const EmptyState = ({ description, height }) => {
  return (
    <Wrapper {...{ height }}>
      <Attention />
      <Description>{description}</Description>
    </Wrapper>
  );
};

EmptyState.propTypes = {
  description: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmptyState;
