import { useState, useEffect } from 'react';

const checkConnection = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const changeToOnLine = () => setIsOnline(true);
  const changeToOffLine = () => setIsOnline(false);
  useEffect(() => {
    window.addEventListener('online', changeToOnLine);
    window.addEventListener('offline', changeToOffLine);
    return () => {
      window.removeEventListener('online', changeToOnLine);
      window.removeEventListener('offline', changeToOffLine);
    };
  }, []);
  return isOnline;
};
export default checkConnection;
