import IMask from 'imask';

export const phoneMask = IMask.createMask({
  mask: '(000) 000-0000',
  lazy: false, // make placeholder always visible
  placeholderChar: '#', // defaults to '_'
});

export const displayNameMask = IMask.createMask({
  mask: /^[a-zA-Z0-9_ ]{0,23}$/,
});
