import React from 'react';
import styled from '@emotion/styled';

import { Icon } from 'atoms';
import { Exclamation } from 'assets/icons';

import colors from 'assets/themes/colors';

const StyledIcon = styled(Icon)`
  transform: rotate(15deg);
`;

const Attention = () => {
  return (
    <StyledIcon size={80} color={colors.quaternary}>
      <Exclamation />
    </StyledIcon>
  );
};

export default Attention;
