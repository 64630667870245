import React from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from 'molecules/HeaderContainer';

import { Button, Icon, Group, Title } from 'atoms';
import { Bars } from 'assets/icons';

import colors from 'assets/themes/colors';
import { navigate } from '@reach/router';

import { SportLogo } from 'organisms/ContestBuilder';

const AppHeader = ({ title, renderCenter, onClickToOpen }) => {
  return (
    <HeaderContainer
      leftComponent={
        <Group isVerticalCentered spaceSize={6}>
          {/* TODO: Implement a better logic to back functionality */}
          {/* <Button isBare onClick={() => window.history.back()}>
            <Icon size={14} color="#FFF">
              <ArrowLeft />
            </Icon>
          </Button> */}
          <Button isBare onClick={() => navigate('/')}>
            <SportLogo height={40} />
          </Button>
        </Group>
      }
      centerComponent={
        title ? <Title color="#FFFFFF">{title}</Title> : renderCenter
      }
      rightComponent={
        <Group isVerticalCentered spaceSize={20}>
          <Button specSelector="avatar-header" isBare onClick={onClickToOpen}>
            <Icon size={26} color={colors.white}>
              <Bars />
            </Icon>
          </Button>
        </Group>
      }
    />
  );
};

AppHeader.propTypes = {
  title: PropTypes.string,
  renderCenter: PropTypes.any,
  onClickToOpen: PropTypes.func,
};

export default AppHeader;
