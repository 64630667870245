import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import { Button, GeneralText, Group, Title, Space } from 'atoms';

import { MeteorFantasyLogo } from 'molecules';

import { Fill } from 'atoms/Group';
import Alert from 'molecules/Alert';

import { auth, api } from 'services/firebase';

import useAnalytics from 'hooks/useAnalytics';

import useUserData from 'hooks/useUserData';
import useGameSteps from 'hooks/useGameSteps';

const VerifyEmail = ({ actionCode, uid }) => {
  const [feedback, setFeedback] = useState('');
  const [isVerified, setVerified] = useState(false);
  const [session, reload] = useUserData();

  const { logEvent } = useAnalytics();

  const isAnonymous = session.isAnonymous;

  const { isReadyToSubmit } = useGameSteps();

  const timeout = setTimeout;

  useEffect(() => {
    if (!actionCode || !uid) {
      setFeedback(
        'Verification link not found or invalid. Please try to resend a new code in the app.'
      );
      return;
    }
    auth()
      .applyActionCode(actionCode)
      .then(async () => {
        await api.updateEmailVerifiedStatus(uid);
        setVerified(true);
        if (!isAnonymous) {
          reload().then(() => {
            logEvent('join_group', { group_id: 3 });
          });
        }
      })
      .catch(error => {
        setFeedback(error.message);
      });
  }, []);

  useEffect(() => {
    if (isVerified && isReadyToSubmit) {
      timeout(() => {
        navigate('/submit-gate');
      }, 2000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isVerified, isReadyToSubmit]);

  const callToAction = isReadyToSubmit ? (
    <GeneralText color={colors.accent}>Redirecting...</GeneralText>
  ) : (
    <Button
      specSelector="auth-verify-email-go-back"
      onClick={() => navigate('/')}
      isXSmall
      style={{ display: 'inline' }}>
      click here to go back to the game!
    </Button>
  );

  return (
    <Group
      specSelector="auth-verify-email-screen"
      isVertical
      isFullWidth
      isCentered
      isVerticalCentered
      style={{ margin: 'auto', maxWidth: 415 }}>
      <Group isVertical isFullWidth isCentered spaceSize={20} padding={30}>
        <Space isLarge isVertical />

        <MeteorFantasyLogo size={180} />

        <Title color={colors.white}>Email Verification</Title>

        <GeneralText isMedium color={colors.white}>
          {isVerified ? 'DONE!' : 'Almost there!'}
        </GeneralText>

        {isVerified ? (
          <Group isCentered isVertical isFullWidth spaceSize={8}>
            <GeneralText color={colors.white}>
              Congratulations, your email was confirmed!
            </GeneralText>
            {callToAction}
          </Group>
        ) : (
          <GeneralText isLarge color={colors.accent}>
            Verifying...
          </GeneralText>
        )}

        <Space isVertical isLarge />
      </Group>

      <Fill />

      <Alert
        isOpen={!!feedback}
        disableClose
        title="Something happened"
        hideCancelButton
        hideProceedButton>
        <GeneralText>{feedback}</GeneralText>
      </Alert>
    </Group>
  );
};

VerifyEmail.propTypes = {
  actionCode: PropTypes.string,
  uid: PropTypes.string,
};

export default VerifyEmail;
