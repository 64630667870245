// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import { useAppContainer } from 'organisms/AppContainer';

const Wrapper = ({ children, title }) => {
  useAppContainer({
    header: {
      title,
    },
  });

  return children;
};

Wrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};

export default Wrapper;
