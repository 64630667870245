import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'rebass';

const ImageWrapper = ({ height, source, alt, isMuted, ...rest }) => (
  <Image
    src={source}
    alt={alt}
    height={height}
    opacity={isMuted ? 0.5 : 1}
    {...rest}
  />
);

ImageWrapper.defaultProps = {
  alt: 'image',
  isMuted: false,
};

ImageWrapper.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.number,
  isMuted: PropTypes.bool,
  source: PropTypes.string,
};

export default ImageWrapper;
