import { useEffect } from 'react';

import { messaging } from 'services/firebase';

const useFCM = () => {
  useEffect(() => {
    if (messaging()) {
      messaging().sendTokenToServer('default');
    }
    return () => {};
  }, []);
  return {
    registerDevice: messaging() ? messaging().sendTokenToServer : () => {},
  };
};

export default useFCM;
