// Fetch entryFees to the selected timeBlockId and gameType
export const fetchEntryFees = (timeBlockId, gameType, modality) => ({
  type: 'FETCH_ENTRY_FEES',
  timeBlockId,
  gameType,
  modality,
});

// Select an entryFee passing to the store an object
export const selectEntryFee = payload => ({
  type: 'SELECT_ENTRY_FEE',
  payload,
});

// Update game with predictions
export const updateGame = payload => ({
  type: 'UPDATE_GAME',
  payload,
});

export const resetPredictions = () => ({
  type: 'RESET_PREDICTIONS',
});

// Change gameType
export const changeGameType = type => ({
  type: 'SELECT_GAME_TYPE',
  payload: { type },
});
