import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Group, Button, Input, Icon } from 'atoms';
import { Plus, Minus } from 'assets/icons';

import colors from 'assets/themes/colors';

const NumberSteps = ({
  stepSize,
  min,
  max,
  leftSign,
  rightSign,
  defaultValue,
  customInput,
  onChange,
}) => {
  const [innerValue, updateValue] = useState(null);

  useEffect(() => {
    if (defaultValue % stepSize !== 0)
      defaultValue = defaultValue - (defaultValue % stepSize);
    if (defaultValue !== null) handleUpdates(defaultValue);
  }, [defaultValue]);

  const handleChanges = action => {
    let newValue = innerValue;
    if (action === 'add' && innerValue < max) newValue = innerValue + stepSize;
    if (action === 'subtract' && innerValue > min)
      newValue = innerValue - stepSize;
    handleUpdates(newValue);
  };

  function handleUpdates(valueToUse) {
    updateValue(valueToUse * 1);
    onChange(valueToUse * 1);
  }

  return (
    <Group isVerticalCentered>
      <Button
        isOutlined
        isSecondary
        isXSmall
        size={38}
        onClick={() => handleChanges('subtract')}>
        <Icon color={colors.primary} size={24}>
          <Minus />
        </Icon>
      </Button>
      {customInput ? (
        customInput({ innerValue, updateValue })
      ) : (
        <Input
          value={`${leftSign}${innerValue}${rightSign}`}
          style={{ width: 60, textAlign: 'center' }}
        />
      )}
      <Button
        isOutlined
        isSecondary
        isXSmall
        size={38}
        onClick={() => handleChanges('add')}>
        <Icon color={colors.primary} size={24}>
          <Plus />
        </Icon>
      </Button>
    </Group>
  );
};

NumberSteps.defaultProps = {
  stepSize: 1,
  min: 0,
  max: Infinity,
  leftSign: '',
  rightSign: '',
};

NumberSteps.propTypes = {
  customInput: PropTypes.any,
  defaultValue: PropTypes.number,
  leftSign: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  rightSign: PropTypes.string,
  stepSize: PropTypes.number,
};

export default NumberSteps;
