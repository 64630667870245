// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import { useAppContainer } from 'organisms';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      title: 'My Account',
    },
  });
  return children;
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

export default Wrapper;
