/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Terms as TermsPage } from 'pages';
import Wrapper from './Wrapper';

const Terms = () => (
  <Wrapper title="Terms & Conditions">
    <TermsPage />
  </Wrapper>
);

export default Terms;
