import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import lottie from 'lottie-web';

import lottieFile from 'assets/lottie/animated-checkmark.json';

// TODO: Move this to an atom
const AnimatedCheckmark = ({ style }) => {
  const ref = useRef();
  useEffect(() => {
    lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      autoplay: true,
      animationData: lottieFile,
    });
  }, []);
  return <div style={{ width: 80, ...style }} ref={ref} />;
};

AnimatedCheckmark.propTypes = {
  style: PropTypes.object,
};

export default AnimatedCheckmark;
