import React from 'react';
import PropTypes from 'prop-types';

import { useAppContainer, Container } from 'organisms/AppContainer';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      title: 'Submitting a Contest',
    },
  });

  return <Container backgroundSource="submitGate">{children}</Container>;
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export default Wrapper;
