import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: ${({ isVertical }) => (isVertical ? 'flex' : 'inline-flex')};
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  ${({ isSmall, isMedium, isLarge, isVertical, size }) => {
    if (isSmall) size = 10;
    if (isMedium) size = 20;
    if (isLarge) size = 30;
    return isVertical
      ? css`
          height: ${size}px;
        `
      : css`
          width: ${size}px;
        `;
  }}
`;

const Space = props => {
  return <Wrapper {...props}>&nbsp;</Wrapper>;
};

Space.defaultProps = {
  size: 5,
};

Space.propTypes = {
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  isVertical: PropTypes.bool,
  size: PropTypes.number,
};

export default Space;
