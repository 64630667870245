import React from 'react';
import PropTypes from 'prop-types';

import IMask from 'imask';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import {
  Group,
  Button,
  Space,
  Input,
  Icon,
  FormContainer,
  GeneralText,
} from 'atoms';
import ImageWrapper from 'atoms/ImageWrapper';

import { CreditCard as CreditCardIcon } from 'assets/icons';
import creditCardLogos from 'assets/media/logos/credit-cards.svg';

const cardNumberMask = IMask.createMask({ mask: '0000 0000 0000 0000' });
const expiryDateMask = IMask.createMask({ mask: '00/00' });
const cvcMask = IMask.createMask({ mask: '000' });

const CreditCard = ({ onSubmit }) => {
  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  return (
    <Group isFullWidth isVertical isCentered>
      <Space isVertical size={5} />

      <Group isVerticalCentered spaceSize={5}>
        <Icon size={18}>
          <CreditCardIcon />
        </Icon>
        <GeneralText isLarge>
          <b>Add your credit card</b>
        </GeneralText>
      </Group>

      <Space isVertical size={10} />

      <ImageWrapper
        source={creditCardLogos}
        alt={'Available Credit Cards'}
        height={20}
      />

      <Space isVertical size={20} />

      <FormContainer
        initialValues={{
          cardNumber: '',
          expiryDate: '',
          cvc: '',
        }}
        onSubmit={values => {
          if (onSubmit)
            onSubmit({ cardType: meta.cardType.displayName, ...values });
        }}
        validate={() => {
          let errors = {};
          if (meta.erroredInputs.cardNumber) {
            errors.cardNumber = meta.erroredInputs.cardNumber;
          }
          if (meta.erroredInputs.expiryDate) {
            errors.expiryDate = meta.erroredInputs.expiryDate;
          }
          if (meta.erroredInputs.cvc) {
            errors.cvc = meta.erroredInputs.cvc;
          }
          return errors;
        }}
        render={({ values, isValid, handleChange, handleSubmit }) => {
          return (
            <Group isVertical isFullWidth spaceSize={5}>
              <Input
                placeholder="Card Holder Name"
                name="cardHolderName"
                value={values.cardHolderName}
                onChange={event => {
                  event.target.value = event.target.value.toUpperCase();
                  handleChange(event);
                }}
              />

              <PaymentInputsWrapper {...wrapperProps}>
                <svg {...getCardImageProps({ images })} />
                <input
                  {...getCardNumberProps({
                    onChange: event => {
                      event.target.value = cardNumberMask.resolve(
                        event.target.value
                      );
                      handleChange(event);
                    },
                  })}
                  name="cardNumber"
                  value={values.cardNumber}
                />
                <input
                  {...getExpiryDateProps({
                    onChange: event => {
                      event.target.value = expiryDateMask.resolve(
                        event.target.value
                      );
                      handleChange(event);
                    },
                  })}
                  name="expiryDate"
                  value={values.expiryDate}
                />
                <input
                  {...getCVCProps({
                    onChange: event => {
                      event.target.value = cvcMask.resolve(event.target.value);
                      handleChange(event);
                    },
                  })}
                  name="cvc"
                  value={values.cvc}
                />
              </PaymentInputsWrapper>

              <Space isVertical size={10} />

              <Button
                type="submit"
                disabled={!isValid}
                isSmall
                isFullWidth
                onClick={handleSubmit}>
                ADD CARD
              </Button>
            </Group>
          );
        }}
      />
    </Group>
  );
};

CreditCard.propTypes = {
  onSubmit: PropTypes.func,
};

export default CreditCard;
