// eslint-disable-next-line no-unused-vars
import React from 'react';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

import playerCardBg from 'assets/media/images/playerCardBg.png';

const showImage = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-5%) scale(1.4);
    transform-origin: center;
  }
  10% {
    opacity: .6;
    transform: translateY(0%) scale(1.2);
    transform-origin: center;
  }
  100% {
    opacity: 1;
    transform: translateY(0%) scale(1);
    transform-origin: center;
  }
`;

// TODO: Move this to an atom
const AnimatedBackground = styled.div`
  background-image: url(${playerCardBg});
  background-repeat: no-repeat;
  background-position: center 70px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  animation: ${showImage} 0.3s ease-out;
`;

export default AnimatedBackground;
