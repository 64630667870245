import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import PlayerAvatar from 'molecules/PlayerAvatar';
import Group from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Button from 'atoms/Button';
import Icon from 'atoms/Icon';
import { Pencil } from 'assets/icons';

import colors from 'assets/themes/colors';
import OverUnderPrediction from './OverUnderPrediction';
import StatpicksPrediction from './StatpicksPrediction';

const Container = styled.div`
  padding: 8px 10px;
  background-color: #f2f2f2;
  display: flex;
  border-radius: 4px;
  position: relative;
  ${({ isBordered }) =>
    isBordered &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 2px dotted ${colors.secondary};
      }
    `}
`;

const InlinePlayerCard = ({
  isBordered,
  isBackup,
  onClickEdit,
  fullName,
  gameType,
  picture,
  stats,
  projections,
  selected,
  specSelector,
}) => (
  <Container
    data-spec-selector={specSelector}
    data-guided-tour={
      isBackup ? 'inline-player-card-backup' : 'inline-player-card'
    }
    isBordered={isBordered}>
    <Group spaceSize={10} isFullWidth isVerticalCentered>
      <PlayerAvatar
        source={picture}
        flagLabel={isBackup ? 'BACKUP' : ''}
        isMedium
        isSecondary
        style={{ marginTop: -15, marginBottom: -15 }}
      />

      <Group isVertical isGrown>
        <GeneralText isTruncated>
          <strong>{fullName}</strong>
        </GeneralText>
        <Group isVerticalCentered spaceSize={5}>
          <GeneralText isSmall>{stats.label}:</GeneralText>
          {gameType === 'overunder' && projections && (
            <OverUnderPrediction
              stats={
                projections.overunder.filter(m => m.name === stats.name)[0]
              }
              selected={selected}
            />
          )}
          {gameType === 'statpicks' && (
            <StatpicksPrediction stats={stats} selected={selected} />
          )}
        </Group>
      </Group>
      {onClickEdit && (
        <Button
          data-guided-tour="inline-player-card-edit"
          specSelector="inline-player-card-edit"
          isBare
          onClick={onClickEdit}>
          <Icon size={12} color={colors.accent}>
            <Pencil />
          </Icon>
        </Button>
      )}
    </Group>
  </Container>
);

InlinePlayerCard.defaultProps = {
  stats: {
    label: '---',
    value: '---',
  },
  fullName: '---',
  picture: null,
  selected: null,
};

InlinePlayerCard.propTypes = {
  fullName: PropTypes.string,
  gameType: PropTypes.string,
  isBackup: PropTypes.bool,
  isBordered: PropTypes.bool,
  label: PropTypes.string,
  onClickEdit: PropTypes.func,
  picture: PropTypes.string,
  projections: PropTypes.object,
  selected: PropTypes.any,
  specSelector: PropTypes.string,
  stats: PropTypes.object,
  value: PropTypes.string,
};

export default InlinePlayerCard;
