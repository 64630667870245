import React from 'react';
import PropTypes from 'prop-types';

import ImageWrapper from 'atoms/ImageWrapper';
import NFLLogo from 'assets/media/logos/NFL.svg';
import NBALogo from 'assets/media/logos/NBA.svg';
import MFLogo from 'assets/media/logos/meteor-fantasy-logo.svg';

import useDraft from '../useDraft';

const Sport = ({ selected, size }) => {
  const { draft } = useDraft();

  let sportLogo = null;

  const sportSelection = selected ? String(selected).toLowerCase() : null;

  switch (sportSelection || draft.sport) {
    case 'nba':
      sportLogo = NBALogo;
      break;
    case 'nfl':
      sportLogo = NFLLogo;
      break;

    default:
      sportLogo = MFLogo;
      break;
  }

  return <ImageWrapper source={sportLogo} alt={'Sport Logo'} height={size} />;
};

Sport.defaultProps = {
  size: 32,
};

Sport.propTypes = {
  selected: PropTypes.string,
  size: PropTypes.number,
};

export default Sport;
