import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Button, Group, ImageWrapper, Space } from 'atoms';
import { SecurityHeader } from 'molecules';

import MeteorLogo from 'assets/media/images/meteor-logo-2.png';
import PhonesAndFootball from 'assets/media/images/phones-and-football.png';

const ButtonWrapper = styled(Button)`
  color: ${colors.accent};
`;

const SplashForeground = ({ onClickPolicy, onClickTC, ...rest }) => (
  <Group isFullWidth isVertical isCentered spaceSize={20} {...rest}>
    <Space isLarge isVertical />
    <SecurityHeader left="SECURE CONNECTION" right="SSL - ENCRYPTION" />
    <Space isLarge isVertical />

    <ImageWrapper source={MeteorLogo} />

    <ImageWrapper source={PhonesAndFootball} />

    <Group isFullWidth isCentered>
      <Space isSmall />
      <Group isFullWidth isCentered onClick={onClickPolicy}>
        <ButtonWrapper isBare>Our Policy</ButtonWrapper>
      </Group>

      <Group isFullWidth isCentered onClick={onClickTC}>
        <ButtonWrapper isBare>Terms &amp; Conditions</ButtonWrapper>
      </Group>
    </Group>
    <Space isSmall isVertical />
  </Group>
);

SplashForeground.defaultProps = {
  onClickPolicy: () => {},
  onClickTC: () => {},
};

SplashForeground.propTypes = {
  onClickPolicy: PropTypes.func.isRequired,
  onClickTC: PropTypes.func.isRequired,
};

export default SplashForeground;
