import React from 'react';
import PropTypes from 'prop-types';

import { Button as RebassButton } from 'rebass';
import Group from 'atoms/Group';

const Button = ({
  children,
  isDisabled,
  isFullWidth,
  isNeutral,
  isOutlined,
  isPrimary,
  isRounded,
  isSecondary,
  isDanger,
  isSuccess,
  isSmall,
  isXSmall,
  isBare,
  size,
  specSelector,
  ...rest
}) => {
  let variant = 'primary';
  if (isPrimary) variant = 'primary';
  if (isSecondary) variant = 'secondary';
  if (isNeutral) variant = 'neutral';
  if (isDisabled) variant = 'disabled';

  if (isDanger) variant = 'danger';
  if (isSuccess) variant = 'success';

  let override = {};

  if (isFullWidth) override = { ...override, width: '100%' };
  if (isSmall) override = { ...override, padding: '8px 18px', borderRadius: 6 };
  if (isXSmall)
    override = {
      ...override,
      padding: '4px 12px',
      borderRadius: 4,
      borderWidth: 2,
      fontSize: 12,
    };

  if (isRounded) {
    if (!size) size = 30;
    override = {
      ...override,
      alignItems: 'center',
      borderRadius: '100%',
      display: 'flex',
      height: size,
      justifyContent: 'center',
      padding: 0,
      width: size,
    };
  }

  if (isBare)
    override = {
      ...override,
      padding: '0',
      border: 'none',
      backgroundColor: 'transparent !important',
    };

  if (size)
    override = {
      ...override,
      height: size,
      width: size,
    };

  return (
    <RebassButton
      variant={`buttons.${variant}${isOutlined ? 'Outlined' : ''}`}
      sx={override}
      // disabled={disabled}
      data-spec-selector={specSelector}
      {...rest}>
      <Group
        isCentered
        spaceSize={10}
        isFullWidth
        style={{ whiteSpace: 'nowrap' }}>
        {children}
      </Group>
    </RebassButton>
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  isBare: PropTypes.bool,
  isDanger: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isNeutral: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isRounded: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isXSmall: PropTypes.bool,
  size: PropTypes.number,
  specSelector: PropTypes.string,
};

export default Button;
