import { all, fork } from 'redux-saga/effects';

import main from 'containers/MainContainer/sagas';
import teamsSelection from 'containers/TeamsSelection/sagas';
import gameplay from 'containers/Gameplay/sagas';
import submit from 'containers/SubmitGame/sagas';
import accountVerification from 'containers/AccountVerification/sagas';

export default function* root() {
  yield all([
    fork(main),
    fork(teamsSelection),
    fork(gameplay),
    fork(submit),
    fork(accountVerification),
  ]);
}
