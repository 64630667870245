export default {
  name: 'teamSelectionTour',
  steps: [
    {
      target: '[data-guided-tour="day-divisor-select"]',
      content:
        'Based on the time teams are playing, games are separated by Early, Afternoon, or Late game start times.',
      placement: 'auto',
    },
    {
      target: '[data-guided-tour="team-select-1"]',
      content:
        'Teams playing during the timeframe you select show up here! You can toggle between your two teams using these buttons.',
      placement: 'auto',
    },
    {
      target: '[data-guided-tour="header-right-component"]',
      content:
        'Access your side drawer to see your draft contests and any active entries that you have submitted. You can also access your winning/losing history as well as edit your profile.',
      placement: 'auto',
    },
  ],
};
