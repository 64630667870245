import React, { useContext } from 'react';

import { Button, Group } from 'atoms';
import { Fill } from 'atoms/Group';

import MFLogo from 'assets/media/logos/meteor-fantasy-logo.svg';

import useUserData from 'hooks/useUserData';
import { MainContext } from 'containers/MainContainer';

import colors from 'assets/themes/colors';
import { navigate } from '@reach/router';

const Footer = () => {
  const [session] = useUserData();
  const { signOut } = useContext(MainContext);
  const isAnonymous = session && session.isAnonymous;
  const pathname = location.pathname.replace('/', '');
  return (
    <Group
      isFullWidth
      isCentered
      style={{
        alignItems: 'flex-end',
        margin: '-15px -15px -24px',
        backgroundColor: colors.primary,
        borderTop: `4px solid ${colors.accent}`,
        width: 320,
      }}>
      <img
        src={MFLogo}
        alt="MF Logo"
        style={{
          display: 'block',
          margin: 15,
          width: 50,
        }}
      />
      <Fill />
      <Button
        specSelector={`side-drawer-${
          !isAnonymous ? 'signout' : 'signin'
        }-button`}
        isSecondary
        isNeutral
        isXSmall
        isOutlined
        onClick={
          !isAnonymous
            ? () => signOut()
            : () => navigate(`/auth${pathname ? `?returnTo=${pathname}` : ''}`)
        }
        style={{ margin: '15px 15px 33px' }}>
        {!isAnonymous ? 'Sign out' : 'Login'}
      </Button>
    </Group>
  );
};

export default Footer;
