import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { DollarSign } from 'assets/icons';
import { Button, Group, Icon, Space } from 'atoms';
import { Fill } from 'atoms/Group';

const AddFundsButton = ({ onClick }) => (
  <Button isFullWidth onClick={onClick}>
    <Icon color={colors.white} size={18}>
      <DollarSign />
    </Icon>
    <Fill />
    <Group isVerticalCentered>ADD FUNDS</Group>
    <Fill />
    <Space isSmall />
  </Button>
);

export default AddFundsButton;

AddFundsButton.defaultProps = {
  onClick: () => {},
};

AddFundsButton.propTypes = {
  onClick: PropTypes.func,
};
