import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import Routes from './Routes';

import checkConnection from 'hooks/checkConnection';

import AppContainer from 'organisms/AppContainer';

import useAnalytics from 'hooks/useAnalytics';
import useRollbar from 'hooks/useRollbar';
import useAppVersion from 'hooks/useAppVersion';
import useFCM from 'hooks/useFCM';
import useAlert from 'hooks/useAlert';

// import VerifyEmailFlag from 'molecules/VerifyEmailFlag';
import ContestBuilder from 'organisms/ContestBuilder';

export const MainContext = React.createContext({});

import { iPromise } from 'services/utilities';
import { InitUserData } from 'hooks/useUserData';

let resolveResetPromise = null;

export const MainContainer = ({ resetGame, signOut, location }) => {
  const isOnline = checkConnection();

  useAnalytics(); // Set tracker instance for the first time
  useRollbar(); // Set rollbar instance for the first time

  useAppVersion({ onChange: resetGame });

  useFCM();

  const [Alert, toggleAlert] = useAlert();

  const withinRangeRoutes = [
    '/gameplay',
    '/submit',
    '/submit-gate',
    '/account/profile',
    '/account/results',
  ];

  const isHidden = withinRangeRoutes.includes(location.pathname);

  return (
    <MainContext.Provider
      value={{
        isOnline,
        resetGame: now => {
          if (now) {
            resetGame();
            return;
          }
          toggleAlert(true);
          const { promise, resolve } = iPromise();
          resolveResetPromise = resolve;
          return promise;
        },
        signOut,
      }}>
      <AppContainer>
        <Routes />
      </AppContainer>

      <Alert
        title="Clear draft contest?"
        proceedCancelLabel="Nevermind"
        onClickToCancel={() => toggleAlert(false)}
        onClickToClose={() => toggleAlert(false)}
        onClickToProceed={() => {
          resolveResetPromise();
          resetGame();
        }}>
        Do you really want to clear the current contest?
      </Alert>

      <ContestBuilder {...{ isHidden }} />
      <InitUserData />

      {/* <VerifyEmailFlag /> TODO: Commented for free games */}
    </MainContext.Provider>
  );
};

MainContainer.propTypes = {
  location: PropTypes.object,
  resetGame: PropTypes.func,
  signOut: PropTypes.func,
};

export default connect(() => ({}), { ...actions })(MainContainer);
