import React, { useEffect, useState } from 'react';

import { navigate } from '@reach/router';

import useUserData from 'hooks/useUserData';
// import useUserEvents from 'hooks/useUserEvents';
import useGameSteps from 'hooks/useGameSteps';

import AnonymousUser from './components/AnonymousUser';
// import UnverifiedEmail from './components/UnverifiedEmail';
import ContestIsNotReady from './components/ContestIsNotReady';
import ReadyToSubmit from './components/ReadyToSubmit';

export const SubmitGate = () => {
  const [session] = useUserData();
  const [isReadyToGo, setReady] = useState(false);

  // const { keyEvents, saveEvent } = useUserEvents();

  const isAnonymous = session.isAnonymous;
  const userDataIsReady = session.isReady;

  // const hasEmailVerified = session.emailIsVerified;

  const { isReadyToSubmit } = useGameSteps();

  // TODO: Commented because we dont have this function on your remote project yet
  // useEffect(() => {
  //   if (hasEmailVerified && !keyEvents.emailVerified) saveEvent('emailVerified');
  // }, [hasEmailVerified]);

  useEffect(() => {
    if (!isAnonymous) setReady(true);
  }, [userDataIsReady]);

  useEffect(() => {
    if (isReadyToGo && isReadyToSubmit) navigate('/submit');
  }, [isReadyToGo, isReadyToSubmit]);

  if (isReadyToGo && isReadyToSubmit) return null;
  if (isAnonymous) return <AnonymousUser />;
  // if (!hasEmailVerified) return <UnverifiedEmail />;
  if (!isReadyToSubmit) return <ContestIsNotReady />;
  if (!isReadyToGo && isReadyToSubmit) return <ReadyToSubmit />;
  return null;
};

export default SubmitGate;
