import React, { useEffect, useState } from 'react';

import useModal from 'hooks/useModal';
import ModalHeader from 'organisms/ModalHeader';

import useGeolocation from 'hooks/useGeolocation';
import useGeocode from 'hooks/useGeocode';

import { Group, GeneralText } from 'atoms';

import {
  stateBlacklist,
  countryWhitelist,
  stateWaitingList,
} from './checklist';

const callCheckRegion = () => {
  const [Modal, toggleModal] = useModal();

  const [availability, setAvailability] = useState('checking');
  const [location, setLocation] = useState(null);

  const { waiting, timestamp, latitude, longitude } = useGeolocation({
    cached: true,
  });

  const geocoder = useGeocode;

  useEffect(() => {
    if (!waiting)
      geocoder({
        latitude,
        longitude,
        cached: true,
        apiKey: 'AIzaSyBLVH65RWntYT7QCKbWEg-hKMdvQMCrFrw',
      }).then(({ country, region }) => {
        const countryAllowed = countryWhitelist.indexOf(country.short) !== -1;
        const stateAllowed = stateBlacklist.indexOf(region.short) === -1;
        const stateIsWaiting = stateWaitingList.indexOf(region.short) !== -1;

        if (!countryAllowed || !stateAllowed) {
          setAvailability('unavailable');
        }

        if (stateIsWaiting) {
          setAvailability('soon');
        }

        if (countryAllowed && stateAllowed && !stateIsWaiting) {
          setAvailability('available');
        }

        setLocation({ country, region });
      });
    return () => {
      setAvailability('checking');
    };
  }, [waiting, timestamp, latitude, longitude]);

  const isWaiting = waiting || availability === 'checking';

  const Render = () => {
    const region = location ? location.region.long : 'Region Undefined';

    let message = null;
    let instructions = null;

    if (availability === 'unavailable') {
      message = (
        <>
          Sorry! The game is {availability} at <br />
          <b>{region}</b>.
        </>
      );
      instructions = (
        <>
          <b>{region}</b> regulators restrict us from allowing you to play a
          paid game or deposit funds while you in <b>{region}</b>. You can still
          play a free game or attempt a paid game when you leave <b>{region}</b>
          .
        </>
      );
    }
    if (availability === 'soon') {
      message = <>Sorry! The game is unavailable at {region}.</>;
      instructions = (
        <>
          We have not yet launched paid games in <b>{region}</b>! We plan to
          launch there soon! You can still play free games, however.
        </>
      );
    }

    if (isWaiting) return null;

    return (
      <Modal
        customHeader={() => (
          <ModalHeader onClickToClose={toggleModal} title="Bummer!" />
        )}>
        <Group isVertical spaceSize={10} isCentered padding={20}>
          <GeneralText isLarge style={{ textAlign: 'center' }}>
            {message}
          </GeneralText>
          <GeneralText isSmall style={{ textAlign: 'center' }}>
            {instructions}
          </GeneralText>
        </Group>
      </Modal>
    );
  };

  return [
    Render,
    {
      isWaiting,
      isAvailable:
        availability !== 'unavailable' &&
        availability !== 'soon' &&
        availability !== 'checking',
      availability,
      location,
      toggleModal,
    },
  ];
};

export default callCheckRegion;
