import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import colors from 'assets/themes/colors';

const rotate = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(180deg); }
`;

const styles = ({ size }) => css`
  position: relative;
  width: ${size}px;
  height: ${size}px;
  &:before {
    content: '';
    animation: 0.3s ${rotate} linear infinite;
    border-bottom: ${size / 10}px solid rgba(43, 48, 61, 0.6);
    border-left: ${size / 10}px solid ${colors.accent};
    border-radius: 100%;
    border-right: ${size / 10}px solid ${colors.accent};
    border-top: ${size / 10}px solid rgba(43, 48, 61, 0.6);
    display: inline-block;
    height: ${size - (size / 10) * 2}px;
    left: 0;
    position: absolute;
    top: 0;
    width: ${size - (size / 10) * 2}px;
    z-index: 1;
  }
  &:after {
    content: '';
    background-color: rgba(43, 48, 61, 0.1);
    border-radius: 100%;
    height: ${size - (size / 10) * 4}px;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${size - (size / 10) * 4}px;
    z-index: 0;
  }
`;

const Shape = styled.div`
  ${styles}
`;

const ChildrenContainer = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ size }) => size - (size / 10) * 4}px;
`;

const Spinner = ({ size, children }) => {
  return (
    <Shape size={size}>
      {children && (
        <ChildrenContainer size={size}>{children}</ChildrenContainer>
      )}
    </Shape>
  );
};

Spinner.defaultProps = {
  size: 24,
};

Spinner.propTypes = {
  size: PropTypes.number,
  children: PropTypes.any,
};

export default Spinner;
