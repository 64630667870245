import React from 'react';

import { HowToPlay as HowToPlayPage } from 'pages';
import Wrapper from './Wrapper';

const HowToPlay = () => (
  <Wrapper title="How To Play">
    <HowToPlayPage />
  </Wrapper>
);

export default HowToPlay;
