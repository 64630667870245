import React, { useEffect, useState } from 'react';

import useModal from 'hooks/useModal';
import ModalHeader from 'organisms/ModalHeader';

import Content from './components/Content';

import { SecurityHeader } from 'molecules';

const callAddFunds = config => {
  const { defaultOpen } = config || {};

  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const [Modal, toggleModal] = useModal({ defaultOpen: true });

  useEffect(() => {
    return () => setIsOpen(false);
  }, []);

  const toggle = state => {
    if (typeof state !== 'boolean') state = !isOpen;
    toggleModal(state);
    setIsOpen(state);
  };

  const close = () => setIsOpen(false);

  const Render = () => {
    return (
      isOpen && (
        <Modal customHeader={() => <ModalHeader title="Add Funds" />}>
          <Content onClickToClose={close} />
          <div style={{ width: '100vw', marginTop: -10, marginLeft: -15 }}>
            <SecurityHeader left="SECURE CONNECTION" right="SSL ENCRYPTION" />
          </div>
        </Modal>
      )
    );
  };

  return [Render, toggle];
};

export default callAddFunds;
