import React from 'react';
import PropTypes from 'prop-types';

import ProfileCard from './ProfileCard';
import ProfileForm from './ProfileForm';

const Profile = ({ isEdit, onSubmit }) => {
  return isEdit ? <ProfileForm onSubmit={onSubmit} /> : <ProfileCard />;
};

Profile.defaultProps = {};

Profile.propTypes = {
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Profile;
