import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import Link from './components/Link';
import { GeneralText, Group, Space } from 'atoms';
import MeteorLogo from 'assets/media/images/meteor-logo-2.png';

const Wrapper = styled(Group)`
  background-color: ${colors.primary};
  width: 100vw;
`;

const Footer = ({ logo, links }) => (
  <Wrapper isVertical isFullWidth isCentered padding="15px">
    {logo}
    <GeneralText isSmall color={colors.white}>
      All rights reserved Meteor Fantasy.
    </GeneralText>

    <Group data-guided-tour="links" isWrapped isCentered spaceSize={8}>
      {links.map(link => (
        <Fragment key={link.name}>
          <Link link={link} />
        </Fragment>
      ))}
    </Group>

    <GeneralText isSmall color={colors.white} style={{ padding: '5px' }}>
      11112 Downs Road, Pineville, NC 28134, USA
    </GeneralText>
    <Space isSmall isVertical />
    <a href="https://sportsdata.io/" target="_blank" rel="noopener noreferrer">
      <img
        src="https://sportsdata.io/assets/images/badges/sportsdataio_light_100.png?v=1"
        alt="Sports Data Provider"
      />
    </a>
  </Wrapper>
);

Footer.defaultProps = {
  logo: (
    <img
      src={MeteorLogo}
      alt="Meteor Fantasy Logo"
      style={{
        height: '70px',
      }}
    />
  ),
  links: [],
};

Footer.propTypes = {
  logo: PropTypes.node,
  links: PropTypes.arrayOf(
    PropTypes.exact({
      onClick: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default Footer;
