import React from 'react';
import PropTypes from 'prop-types';

import { Group, Icon, Space, GeneralText } from 'atoms';
import { Wallet } from 'assets/icons';

import colors from 'assets/themes/colors';

import useUserData from 'hooks/useUserData';

const BalanceDisplay = ({ newBalance }) => {
  const [{ withdrawableFunds }] = useUserData();
  return (
    <>
      <Space isVertical size={20} />
      <Group isCentered isVertical isVerticalCentered spaceSize={5}>
        <Group isVerticalCentered spaceSize={5}>
          <Icon size={18}>
            <Wallet />
          </Icon>
          <GeneralText isLarge>
            My current <b>balance</b>: <b>${withdrawableFunds}</b>
          </GeneralText>
        </Group>
        <GeneralText isSmall color={colors.tertiary}>
          *My balance after adding funds:{' '}
          <b>${withdrawableFunds + newBalance}</b>
        </GeneralText>
      </Group>
    </>
  );
};

BalanceDisplay.propTypes = {
  newBalance: PropTypes.number,
};

export default BalanceDisplay;
