import React from 'react';
import PropTypes from 'prop-types';

import { useTabBarContext } from './TabBarContext';

const Tab = ({
  children,
  isActiveStyles,
  onClick,
  style,
  tag,
  target,
  ...props
}) => {
  const tabBarContext = useTabBarContext();
  const Tag = tag || 'div';
  const handleClick = () => {
    tabBarContext.setTab(target);
    onClick(target);
  };
  const isActive = tabBarContext.activeTab === target;

  return (
    <Tag
      onClick={handleClick}
      style={isActive ? { ...style, ...isActiveStyles } : { ...style }}
      {...props}>
      {children}
    </Tag>
  );
};

Tab.defaultProps = {
  onClick: () => {},
};

Tab.propTypes = {
  children: PropTypes.node,
  isActiveStyles: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tag: PropTypes.string,
  target: PropTypes.string,
};

export default Tab;
