import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/analytics';

let tracker = null;

const useAnalytics = () => {
  const [instance, setInstance] = useState(tracker);
  const isDevelopment = location.hostname === 'localhost';
  useEffect(() => {
    if (!tracker && !isDevelopment) tracker = firebase.analytics();
    setInstance(tracker);
  }, []);
  const mockFn = () => {};
  const logEvent = instance ? instance.logEvent : mockFn; // TODO: Add other methods when needed
  return { logEvent };
};

export default useAnalytics;
