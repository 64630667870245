import React from 'react';

export default {
  name: 'gamePlayReviewAndSubmitTour',
  steps: [
    {
      target: '[data-guided-tour="inline-player-card"]',
      content: (
        <>
          Oops! See something you want to change? Not a problem - Click on the{' '}
          <b>pencil</b> and you can go back to the Player Card to make a change.
        </>
      ),
      placement: 'auto',
      placementBeacon: 'right',
    },
  ],
};
