import React from 'react';

import { navigate } from '@reach/router';

import Wrapper from './Wrapper';

import colors from 'assets/themes/colors';
import { Button, Group, GeneralText, Icon, Title } from 'atoms';

import { UserCircle } from 'assets/icons';

const AnonymousUser = () => {
  return (
    <Wrapper>
      <Group
        isVertical
        isCentered
        isVerticalCentered
        padding={15}
        spaceSize={60}
        style={{
          width: '100%',
          maxWidth: 450,
          margin: 'auto',
        }}>
        <Title color={colors.white} isLarge>
          Last step!
        </Title>
        <div style={{ height: 100 }}>
          <Icon color={colors.white} size={110}>
            <UserCircle />
          </Icon>
        </div>
        <GeneralText color={colors.white} isLarge>
          <b>Sign in or join before submit!</b>
        </GeneralText>
      </Group>
      <div
        style={{
          width: '100%',
          padding: '15px 15px 15px',
          backgroundColor: 'white',
        }}>
        <Button
          specSelector="submit-contest-signin"
          isFullWidth
          style={{ maxWidth: 450, margin: 'auto' }}
          onClick={() => navigate('/auth?returnTo=submit-gate')}>
          LET`S GO!!!
        </Button>
      </div>
    </Wrapper>
  );
};

export default AnonymousUser;
