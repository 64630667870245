import { createContext, useContext } from 'react';

const TabBarContext = createContext();
const TabBarProvider = TabBarContext.Provider;
const TabBarConsumer = TabBarContext.Consumer;

function useTabBarContext() {
  const context = useContext(TabBarContext);
  if (!context) {
    throw new Error(
      "TabBar components can't be rendered outside TabBar component"
    );
  }

  return context;
}

export { TabBarContext, TabBarProvider, TabBarConsumer, useTabBarContext };
