// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import { useAppContainer } from 'organisms/AppContainer';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      title: 'Account Verification',
    },
  });
  return children;
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export default Wrapper;
