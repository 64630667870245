import React from 'react';
import PropTypes from 'prop-types';

import { Text as RebassText } from 'rebass';

import colors, { invertColor } from 'assets/themes/colors';

const Text = ({
  children,
  color,
  colorByBackground,
  isLarge,
  isMedium,
  isSmall,
  isXSmall,
  isTruncated,
  ...rest
}) => {
  let variant = 'regular';
  if (isXSmall) variant = 'xSmall';
  if (isSmall) variant = 'small';
  if (isMedium) variant = 'medium';
  if (isLarge) variant = 'large';

  let override = {};

  if (isTruncated)
    override = {
      ...override,
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };

  return (
    <RebassText
      variant={`text.${variant}`}
      sx={{ ...override }}
      color={colorByBackground ? invertColor(colorByBackground) : color}
      {...rest}>
      {children}
    </RebassText>
  );
};

Text.defaultProps = {
  color: colors.primary,
};

Text.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  colorByBackground: PropTypes.string,
  isContainer: PropTypes.bool,
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
  isXSmall: PropTypes.bool,
  isTruncated: PropTypes.bool,
};

export default Text;
