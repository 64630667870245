import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const Swiper = ({ children, index, ...rest }) => {
  const [currentIndex, setIndex] = useState(index);

  useEffect(() => {
    setIndex(index);
  }, [index]);

  const length = Children.toArray(children).length;

  return (
    <VirtualizeSwipeableViews
      {...rest}
      enableMouseEvents
      slideCount={length}
      index={currentIndex}
      resistance
      ignoreNativeScroll
      slideRenderer={({ index }) => {
        if (index < 0 || index > length) return null;
        return Children.toArray(children)[index];
      }}
    />
  );
};

Swiper.defaultProps = {
  index: 0,
};

Swiper.propTypes = {
  index: PropTypes.number,
  children: PropTypes.any,
};

export default Swiper;
