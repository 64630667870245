import React from 'react';
import PropTypes from 'prop-types';

import { Group, Button, Title, Space, GeneralText } from 'atoms';

import { Fill } from 'atoms/Group';

import colors from 'assets/themes/colors';

import checkoutAddFunds from 'hooks/checkoutAddFunds';

import ImageWrapper from 'atoms/ImageWrapper';

import visaLogo from 'assets/media/logos/visa.svg';

const Checkout = ({ amount, userHasVault, cc, onClose }) => {
  const [submit, status] = checkoutAddFunds();

  const isProcessing = status === 'processing';
  const isSuccess = status === 'success';
  const isWaiting = status === 'waiting';
  const isError = status === 'error';

  return (
    <Group isVertical isCentered isFullWidth padding={5} style={{ width: 300 }}>
      <Title isLarge color={status === 'success' ? colors.positive : null}>
        <b>{status === 'success' ? 'Congratulations' : 'Checkout'}</b>
      </Title>

      <GeneralText>
        {status === 'success'
          ? 'Your order was processed'
          : 'Confirm your order'}
      </GeneralText>

      <Space isVertical size={20} />

      <hr style={{ width: '100%' }} />

      <Group isFullWidth isVerticalCentered>
        <GeneralText isLarge color={colors.tertiary}>
          Deposit amount:
        </GeneralText>
        <Fill />
        <GeneralText isLarge>
          <b>${amount}</b> USD
        </GeneralText>
      </Group>

      <hr style={{ width: '100%' }} />

      <Group isFullWidth isVerticalCentered spaceSize={5}>
        <GeneralText>
          <b>Payment method:</b> Visa Credit Card
        </GeneralText>
        <Fill />
        <ImageWrapper
          source={visaLogo}
          alt={'Available Credit Cards'}
          height={20}
        />
      </Group>

      <Space isVertical size={20} />

      <Button
        isSecondary
        isDisabled={isProcessing}
        isFullWidth
        isXSmall
        onClick={() =>
          isSuccess ? onClose() : submit({ amount, useVault: userHasVault, cc })
        }>
        {isProcessing && 'PROCESSING ORDER'}
        {isSuccess && 'CLOSE'}
        {(isWaiting || isError) && 'CONFIRM DEPOSIT'}
      </Button>
    </Group>
  );
};

Checkout.propTypes = {
  cc: PropTypes.object,
  onClose: PropTypes.func,
  amount: PropTypes.number,
  userHasVault: PropTypes.bool,
};

export default Checkout;
