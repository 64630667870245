import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { GeneralText, Group, ImageWrapper } from 'atoms';

import SSL from 'assets/media/images/ssl.svg';

const Wrapper = styled(Group)`
  background-color: rgba(0, 0, 0, 0.4);
`;

const SecurityHeader = ({ left, right }) => (
  <Wrapper
    isFullWidth
    isCentered
    isVerticalCentered
    spaceSize={15}
    padding={'10px 20px'}>
    <ImageWrapper source={SSL} height={32} />
    <GeneralText color={colors.white} isSmall>
      {left}
    </GeneralText>
    <GeneralText color={colors.white} isSmall>
      {right}
    </GeneralText>
  </Wrapper>
);

SecurityHeader.propTypes = {
  left: PropTypes.node,
  middle: PropTypes.node,
  right: PropTypes.node,
};

export default SecurityHeader;
