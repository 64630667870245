import { useEffect, useState } from 'react';

const useGeolocation = config => {
  const { watch, cached } = config || {};

  const [state, setState] = useState({
    waiting: true,
    accuracy: null,
    latitude: 0,
    longitude: 0,
    timestamp: Date.now(),
  });

  let mounted = true;
  let listenPosition;

  const onLoad = event => {
    if (mounted) {
      const result = {
        accuracy: event.coords.accuracy,
        latitude: event.coords.latitude,
        longitude: event.coords.longitude,
        timestamp: event.timestamp,
      };

      setState({
        waiting: false,
        ...result,
      });

      if (cached) {
        localStorage.setItem(
          'location',
          JSON.stringify({
            ...result,
          })
        );
      }
    }
  };

  const onError = error =>
    mounted && setState(oldState => ({ ...oldState, waiting: false, error }));

  useEffect(() => {
    if (cached) {
      let location = localStorage.getItem('location');
      if (location) {
        location = JSON.parse(location);
        const startTime = location.timestamp;
        const endTime = Date.now();
        const diff = endTime - startTime;
        const diffTime = diff / 1000 / 60 / 60;
        if (diffTime < 24) {
          if (mounted) {
            setState({
              waiting: false,
              ...location,
            });
          }
        }
      }
    }

    navigator.geolocation.getCurrentPosition(onLoad, onError);

    if (watch)
      listenPosition = navigator.geolocation.watchPosition(onLoad, onError);

    return () => {
      mounted = false;
      navigator.geolocation.clearWatch(listenPosition);
    };
  }, []);

  return state;
};

export default useGeolocation;
