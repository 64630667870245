import * as firebase from 'firebase/app';
import 'firebase/messaging';

import api from './api';

// TODO: Remove console.logs later after validation

const messaging = () => {
  if (!firebase.messaging.isSupported()) return null;

  let instance = firebase.messaging();

  const requestPermission = () => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  };

  const sendToken = topic => {
    if (getCurrentToken()) {
      api
        .sendFCMToken(getCurrentToken(), topic)
        .then(() => {
          console.log('Registration token success');
        })
        .catch(error => {
          console.log('Registration token error', error);
        });
    }
  };

  const deleteToken = () => {
    instance
      .getToken()
      .then(currentToken => {
        instance
          .deleteToken(currentToken)
          .then(() => {
            console.log('Token deleted.');
            localStorage.removeItem('FCMDeviceToken');
          })
          .catch(error => {
            console.log('Unable to delete token. ', error);
          });
      })
      .catch(error => {
        console.log('Error retrieving Instance ID token. ', error);
      });
  };

  const getCurrentToken = () => localStorage.getItem('FCMDeviceToken');

  const setDeviceToken = token => {
    localStorage.setItem('FCMDeviceToken', token);
  };

  instance.onTokenRefresh(() => {
    instance
      .getToken()
      .then(refreshedToken => {
        console.log('Token refreshed.');
        setDeviceToken(refreshedToken);
      })
      .catch(error => {
        console.log('Unable to retrieve refreshed token ', error);
      });
  });

  instance
    .getToken()
    .then(currentToken => {
      if (currentToken) {
        setDeviceToken(currentToken);
      } else {
        console.log(
          'No Instance ID token available. Request permission to generate one.'
        );
        requestPermission();
      }
    })
    .catch(error => {
      requestPermission();
      console.log('An error occurred while retrieving token. ', error);
    });

  instance.removeTokenOnClient = deleteToken;
  instance.sendTokenToServer = sendToken;

  return instance;
};

export default messaging;
