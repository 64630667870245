import { useEffect, useState } from 'react';

import { api } from 'services/firebase';

import useUserData from 'hooks/useUserData';

const getVaultData = () => {
  const [session] = useUserData();

  const [status, setStatus] = useState('waiting');
  const [data, setData] = useState(null);

  useEffect(() => {
    return () => {
      setStatus('waiting');
    };
  }, []);

  const call = () => {
    return new Promise((resolve, reject) => {
      setStatus('processing');
      session.state.getIdToken().then(token => {
        api
          .getVaultData(token)
          .then(response => {
            setData(response);
            setStatus('success');
            resolve();
          })
          .catch(error => {
            setData(error.message);
            setStatus('error');
            reject();
          });
      });
    });
  };

  return [call, { status, data }];
};

export default getVaultData;
