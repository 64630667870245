import React from 'react';
import PropTypes from 'prop-types';

import List from 'atoms/List';
import ImageWrapper from 'atoms/ImageWrapper';
import Group, { Fill } from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import Badge from 'atoms/Badge';
import Space from 'atoms/Space';

import InlinePlayerCard from 'organisms/InlinePlayerCard';

const CardsSummary = ({
  data,
  teamNumber,
  onClickEdit,
  specSelector,
  onClickPlayerInfo,
}) => {
  let teamName = 'Team';
  let teamLogo = 'Team';

  if (data[0].teamName) teamName = data[0].teamName;
  if (data[0].teamNameLogo) teamLogo = data[0].teamNameLogo;

  return (
    <Group specSelector={specSelector} isVertical>
      <Group isFullWidth isVerticalCentered spaceSize={6}>
        <ImageWrapper src={teamLogo} alt={`${teamName}`} height={32} />
        <GeneralText isMedium>
          <strong>{teamName}</strong>
        </GeneralText>
        <Fill />
        <Badge isLarge isDanger={teamNumber === 2}>
          <GeneralText isLarge color={'white'}>
            <strong>{teamNumber}</strong>
          </GeneralText>
        </Badge>
      </Group>
      <Space isMedium isVertical />
      <List>
        {data.map((item, index) =>
          item.status === 'BACKUP' ? (
            <React.Fragment key={index}>
              <GeneralText
                isSmall
                style={{
                  textAlign: 'center',
                  maxWidth: '80%',
                  margin: 'auto',
                }}>
                A backup player counts if one of the lineup players is unable to
                compete.
              </GeneralText>
              <Space isVertical size={12} />
              <InlinePlayerCard
                {...item}
                specSelector={`inline-player-card-${index + 1}`}
                isBordered
                isBackup
                onClickEdit={() => onClickEdit(item)}
                onClickPlayerInfo={() => onClickPlayerInfo(item)}
                key={index}
              />
            </React.Fragment>
          ) : (
            <InlinePlayerCard
              {...item}
              specSelector={`inline-player-card-${index + 1}`}
              onClickEdit={() => onClickEdit(item)}
              onClickPlayerInfo={() => onClickPlayerInfo(item)}
              key={index}
            />
          )
        )}
      </List>
    </Group>
  );
};

CardsSummary.defaultProps = {
  data: [],
  onClickEdit: () => {},
  onClickPlayerInfo: () => {},
};

CardsSummary.propTypes = {
  data: PropTypes.array.isRequired,
  onClickEdit: PropTypes.func,
  onClickPlayerInfo: PropTypes.func,
  specSelector: PropTypes.string,
  teamNumber: PropTypes.number,
};

export default CardsSummary;
