import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase.firestore;

const useCompetitorData = competitorId => {
  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState('waiting');

  useEffect(() => {
    setStatus('loading');
    db()
      .collection(process.env.firestoreCompetitors)
      .doc(competitorId)
      .get()
      .then(snapshot => {
        setResponse({ ...snapshot.data(), id: snapshot.id });
        setStatus('success');
      })
      .catch(() => {
        setStatus('error');
      });
    return () => {};
  }, []);

  return { response, status };
};

export default useCompetitorData;
