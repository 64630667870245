import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Button, GeneralText, Group, Icon, Input } from 'atoms';
import colors from 'assets/themes/colors';

import { Check, CalendarDay, Home, MapMarker, InfoCircle } from 'assets/icons';
import { navigate } from '@reach/router';

const CheckBox = styled(Button)`
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  border: 1px solid ${colors.secondary};
  flex-shrink: 0;
`;

const FormFields = ({ askForSsn, values, handleChange, handleSubmit }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Group isFullWidth isVertical spaceSize={20}>
      {askForSsn && (
        <Group isFullWidth isVertical spaceSize={5}>
          <GeneralText style={{ whiteSpace: 'nowrap' }}>
            <b>SSN (Social Security Number)</b>
          </GeneralText>
          <Input
            name="ssn"
            type="password"
            value={values.ssn}
            onChange={handleChange}
            placeholder="SSN"
            style={{ borderColor: colors.alert }}
          />
        </Group>
      )}

      <Group isFullWidth isVertical spaceSize={5}>
        <GeneralText style={{ whiteSpace: 'nowrap' }}>
          <b>First and last name</b>
        </GeneralText>
        <Input
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          placeholder="First name"
        />
        <Input
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          placeholder="Last name"
        />
      </Group>

      <Group isVertical spaceSize={5}>
        <GeneralText style={{ whiteSpace: 'nowrap' }}>
          <b>Date of birth</b>
        </GeneralText>
        <Input
          rightIcon={
            <Icon color={colors.accent} size={18} style={{ zIndex: 1 }}>
              <CalendarDay />
            </Icon>
          }
          type="date"
          name="dateOfBirth"
          value={values.dateOfBirth}
          onChange={handleChange}
          placeholder="Date of Birth"
          style={{ textTransform: 'uppercase' }}
        />
      </Group>

      <Group isVertical isFullWidth spaceSize={5}>
        <GeneralText style={{ whiteSpace: 'nowrap' }}>
          <b>Street address</b>
        </GeneralText>
        <Input
          leftIcon={
            <Icon color={colors.accent} size={18} style={{ zIndex: 1 }}>
              <Home />
            </Icon>
          }
          name="street"
          value={values.street}
          onChange={handleChange}
          placeholder="Street address"
        />
      </Group>

      <Group isVertical isFullWidth spaceSize={5}>
        <GeneralText style={{ whiteSpace: 'nowrap' }}>
          <b>City and state</b>
        </GeneralText>
        <Group isFullWidth>
          <Input
            leftIcon={
              <Icon color={colors.accent} size={18} style={{ zIndex: 1 }}>
                <MapMarker />
              </Icon>
            }
            name="city"
            value={values.city}
            onChange={handleChange}
            placeholder="City"
            style={{ flex: 1 }}
          />
          <Input
            name="state"
            value={values.state}
            onChange={handleChange}
            placeholder="State"
            style={{ width: 55 }}
          />
        </Group>
      </Group>

      <Group isVertical isFullWidth spaceSize={5}>
        <GeneralText style={{ whiteSpace: 'nowrap' }}>
          <b>Zip Code</b>
        </GeneralText>
        <Input
          leftIcon={
            <Icon color={colors.accent} size={18} style={{ zIndex: 1 }}>
              <InfoCircle />
            </Icon>
          }
          name="zipCode"
          value={values.zipCode}
          onChange={handleChange}
          placeholder="Zip Code"
        />
      </Group>

      <Group spaceSize={5}>
        <CheckBox
          isBare
          aria-label={`T&C checkbox is ${
            isChecked ? 'checked' : 'not checked'
          }`}
          onClick={() => setIsChecked(!isChecked)}>
          <Icon size={13} color={isChecked ? colors.accent : 'transparent'}>
            <Check />
          </Icon>
        </CheckBox>
        <GeneralText onClick={() => setIsChecked(!isChecked)}>
          <i>
            I agree to proceed with the verification and all information
            provided here is real and belongs to me.
          </i>
        </GeneralText>
      </Group>

      <Button
        type="submit"
        isDisabled={!isChecked}
        isFullWidth
        onClick={handleSubmit}>
        VERIFY YOUR ACCOUNT
      </Button>

      <Button
        type="button"
        isSecondary
        isOutlined
        isSmall
        isFullWidth
        onClick={() => navigate('/gametype')}>
        SKIP
      </Button>
    </Group>
  );
};

FormFields.propTypes = {
  askForSsn: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
};

export default FormFields;
