import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Group, ImageWrapper, Title } from 'atoms';

const CompetitionBlockTeam = ({
  isHomeTeam,
  teamName,
  source,
  alt,
  isSelected,
}) => (
  <Group isVertical isVerticalCentered spaceSize={0} style={{ width: '70px' }}>
    <Title isSmall>{teamName}</Title>

    <ImageWrapper
      isMuted={isSelected ? false : true}
      height={42}
      source={source}
      alt={alt}
      style={{
        margin: '5px 0 ',
      }}
    />
    {isHomeTeam ? (
      <Badge isSmall isPrimary>
        Home
      </Badge>
    ) : (
      <Badge isSmall isSecondary>
        Away
      </Badge>
    )}
  </Group>
);

CompetitionBlockTeam.propTypes = {
  alt: PropTypes.string,
  isHomeTeam: PropTypes.bool,
  isSelected: PropTypes.bool,
  source: PropTypes.string,
  teamName: PropTypes.string,
};

CompetitionBlockTeam.defaultProps = {
  isSelected: false,
};

export default CompetitionBlockTeam;
