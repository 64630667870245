import extractCompetitors from 'services/extractCompetitors';

// Fetch all teams with timeBlock status marked as PUBLISHED
export const fetchAvailableTeams = filter => ({
  type: 'FETCH_AVAILABLE_TEAMS',
  filter,
});

// Update store with two selected teams
export const updateSelectedTeams = selection => {
  return {
    type: 'UPDATE_SELECTED_TEAMS',
    payload: {
      competitors: extractCompetitors(selection),
      teams: selection,
      selectedDayDivisor: selection ? selection[0].timeBlock.dayDivisor : null,
      timeBlockId: selection ? selection[0].timeBlockId : null,
    },
  };
};
