import React from 'react';
import PropTypes from 'prop-types';

import { ImageWrapper } from 'atoms';

import assets from 'assets/themes/variants/assets';

const MeteorFantasyLogo = ({ size }) => {
  return <ImageWrapper source={assets['meteorFantasyLogo']} height={size} />;
};

MeteorFantasyLogo.propTypes = {
  size: PropTypes.number,
};

export default MeteorFantasyLogo;
