import { camelCaseToSpace } from 'services/utilities';

export const defaultMetrics = {
  // NFL
  QB: ['passingYards', 'touchdowns', 'interceptions'],
  RB: [
    'rushingYardsPerAttempt',
    'rushingAttempts',
    'touchdowns',
    'rushingYards',
  ],
  WR: ['receivingYards', 'receptions', 'touchdowns'],
  TE: ['receivingYards', 'receptions', 'touchdowns'],
  K: ['extraPointsMade', 'fieldGoalsMade'],
  OLB: ['soloTackles', 'assistedTackles'],
  ILB: ['soloTackles', 'assistedTackles'],
  DE: ['soloTackles', 'assistedTackles', 'quarterbackHits'],

  // NBA
  PF: ['points', 'rebounds', 'blockedShots'],
  C: ['points', 'rebounds', 'blockedShots'],
  SF: ['points', 'threePointersMade', 'assists'],
  PG: ['points', 'threePointersMade', 'assists', 'steals'],
  SG: ['points', 'threePointersMade', 'assists', 'steals'],
};

export default function formatMetrics(metrics, position) {
  if (!metrics) {
    return [];
  }
  return Object.keys(metrics)
    .map(metricKey => {
      if (
        defaultMetrics[position] &&
        defaultMetrics[position].includes(metricKey)
      ) {
        return {
          name: metricKey,
          label: camelCaseToSpace(metricKey, true),
          value: metrics[metricKey],
        };
      }
    })
    .filter(i => i != undefined);
}
