import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Router } from '@reach/router';

import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

import globalStyles from './assets/globalStyles';
import theme from './assets/themes/default';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'core/store';

import { initFirebase, messaging } from 'services/firebase';

import MainContainer from 'containers/MainContainer';
import SignInFlow from 'containers/SignInFlow';

import useServiceWorker from 'hooks/useServiceWorker';

initFirebase();

const timeout = setTimeout;

const App = () => {
  const { isReady } = useServiceWorker(
    '/custom-sw.js',
    registeredServiceWorker => {
      if (messaging()) messaging().useServiceWorker(registeredServiceWorker);
    }
  );

  useEffect(() => {
    timeout(
      () =>
        document
          .querySelector('#splash-screen')
          .setAttribute('style', 'display: none;'),
      2000
    );
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!isReady) return null;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Global styles={globalStyles} />
          <Router>
            <MainContainer path="/*" />
            <SignInFlow path="auth/*" />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
