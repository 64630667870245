import React from 'react';
import PropTypes from 'prop-types';
import { Button, GeneralText, Group, Icon, Space } from 'atoms';
import { Fill } from 'atoms/Group';
import colors from 'assets/themes/colors';
import { CreditCard as CCIcon, TrashAlt } from 'assets/icons';

const CreditCardCardContent = ({
  maskedCreditCard,
  toggleConfirmCreditCardDelete,
  toggleAddCreditCard,
}) => {
  return maskedCreditCard ? (
    <Group isVertical isFullWidth padding={20} spaceSize={15}>
      <Group isFullWidth>
        <GeneralText>{maskedCreditCard}</GeneralText>
        <Fill />
        <Button
          isBare
          onClick={() => {
            toggleConfirmCreditCardDelete();
          }}>
          <Icon size={18}>
            <TrashAlt />
          </Icon>
        </Button>
      </Group>
    </Group>
  ) : (
    <Group isCentered isVerticalCentered isFullWidth padding={20}>
      <Button isFullWidth onClick={toggleAddCreditCard}>
        <Icon color={colors.white} size={18}>
          <CCIcon />
        </Icon>
        <Fill />
        <Group isVerticalCentered>ADD A CARD</Group>
        <Fill />
        <Space isSmall />
      </Button>
    </Group>
  );
};

CreditCardCardContent.defaultProps = {};

CreditCardCardContent.propTypes = {
  maskedCreditCard: PropTypes.string,
  toggleAddCreditCard: PropTypes.func,
  toggleConfirmCreditCardDelete: PropTypes.func,
};

export default CreditCardCardContent;
