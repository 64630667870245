import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import useUserData from 'hooks/useUserData';

import { FormContainer, GeneralText, Group, Title } from 'atoms';

import Alert from 'molecules/Alert';

import FormFields from './components/FormFields';

import Wrapper from './components/Wrapper';
import Intro from './components/Intro';
import AlreadyVerified from './components/AlreadyVerified';

let askForSsn = false;

const AccountVerification = ({
  verificationStatus,
  error,
  verifyAccount,
  resetVerificationStatus,
}) => {
  const [session] = useUserData();

  const [readyToVerify, setReady] = useState(false);

  if (error && (error.code == 1002 || error.code == 'F')) askForSsn = true;

  useEffect(() => {
    askForSsn = false;
    return () => {
      resetVerificationStatus();
    };
  }, []);

  const isVerified = session.verified;

  return (
    <Wrapper>
      {isVerified && <AlreadyVerified />}

      {!readyToVerify && !isVerified && (
        <Intro onClickToProceed={() => setReady(true)} />
      )}

      {readyToVerify && !isVerified && (
        <Group isVertical isFullWidth isCentered>
          <Group isVertical isCentered isFullWidth spaceSize={20} padding={20}>
            <Title>Verification form</Title>
            <GeneralText>
              We just need a few more details about your person to check your
              account.
            </GeneralText>

            <FormContainer
              initialValues={{
                firstName: session.firstName,
                lastName: session.lastName,
              }}
              onSubmit={({
                firstName,
                lastName,
                ssn,
                dateOfBirth,
                street,
                city,
                state,
                zipCode,
              }) => {
                const body = {
                  firstName,
                  lastName,
                  ssn,
                  address: { street, city, state, zipCode },
                  dateOfBirth,
                };
                session.state.getIdToken().then(token => {
                  verifyAccount(body, token);
                });
              }}
              render={formProps => {
                return <FormFields {...formProps} askForSsn={askForSsn} />;
              }}
            />
          </Group>
        </Group>
      )}

      <Alert
        isOpen={verificationStatus === 'verifying'}
        disableClose
        title="Verifying account">
        <GeneralText>Please, wait.</GeneralText>
      </Alert>

      <Alert
        isOpen={error !== null && !!error.message}
        disableClose
        title="Uh oh! Something happened"
        cancelButtonLabel="Cancel"
        proceedButtonLabel={'Try again'}
        onClickToProceed={() => resetVerificationStatus()}>
        <GeneralText>
          Your information could not be verified. Please double check and try
          again.
        </GeneralText>
      </Alert>

      <Alert
        isOpen={verificationStatus === 'verified'}
        disableClose
        title="Account verified"
        proceedButtonLabel={'Go back'}
        onClickToProceed={() => {
          resetVerificationStatus();
          // navigate('/gameplay');
        }}>
        <GeneralText>
          Your account was verified and you are good to proceed with paid
          contests
        </GeneralText>
      </Alert>
    </Wrapper>
  );
};

AccountVerification.propTypes = {
  error: PropTypes.any,
  isVerifying: PropTypes.bool,
  resetVerificationStatus: PropTypes.func,
  verificationStatus: PropTypes.string,
  verifyAccount: PropTypes.func,
};

export default connect(
  store => ({
    ...store.accountVerification,
  }),
  { ...actions }
)(AccountVerification);
