const commons = {
  cursor: 'pointer',
  position: 'relative',
  fontSize: 2,
  outline: 'none',
  boxShadow: 'none',
  border: `2px solid`,
  borderColor: 'transparent',
  borderRadius: 8,
  bg: 'transparent',
  paddingLeft: 26,
  paddingRight: 26,
  paddingTop: 16,
  paddingBottom: 16,
  display: 'flex',
  minWidth: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  ':focus': {
    outline: 'none',
    boxShadow: 'none',
  },
  ':disabled': {
    bg: 'quaternary',
    color: 'tertiary',
    fill: 'tertiary',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    minWidth: '40px',
    minHeight: '40px',
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const primary = {
  ...commons,
  bg: 'accent',
};

const secondary = {
  ...commons,
  bg: 'primary',
};

const neutral = {
  ...commons,
  bg: 'white',
  color: 'primary',
};

const disabled = {
  ...commons,
  bg: 'quaternary',
  color: 'tertiary',
  fill: 'tertiary',
};

const success = {
  ...commons,
  bg: 'positive',
  color: 'white',
  fill: 'positive',
};

const danger = {
  ...commons,
  bg: 'alert',
  color: 'white',
  fill: 'alert',
};

const primaryOutlined = {
  ...commons,
  color: 'accent',
  borderColor: 'accent',
  fill: 'accent',
};

const secondaryOutlined = {
  ...commons,
  color: 'primary',
  borderColor: 'primary',
  fill: 'primary',
};

const neutralOutlined = {
  ...commons,
  color: 'white',
  borderColor: 'white',
  fill: 'white',
};

export default {
  primary,
  secondary,
  neutral,
  disabled,
  danger,
  success,
  primaryOutlined,
  secondaryOutlined,
  neutralOutlined,
};
