export const toArrayWithIds = snapshot => {
  return [
    ...snapshot.docs.map(doc => {
      let data = doc.data();
      if (!data) return null;
      return { ...data, id: doc.id };
    }),
  ];
};

export const toObjectWithId = snapshot => {
  let data = snapshot.data();
  if (!data) return null;
  return { ...data, id: snapshot.id };
};

export const toObjectWithUid = snapshot => {
  let data = snapshot.data();
  if (!data) return null;
  return { ...data, uid: snapshot.id };
};

export const toObject = snapshot => {
  let data = snapshot.data();
  if (!data) return null;
  return data;
};
