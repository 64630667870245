import moment from 'moment';

const parseDate = (date, format = 'MMM DD, YYYY') => {
  const hasToDate = date.toDate;
  const startDate = hasToDate ? date.toDate() : date.seconds;
  const dateParser = hasToDate ? moment : moment.unix;
  return dateParser(startDate).format(format);
};

export default parseDate;
