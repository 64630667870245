import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@rebass/forms';
import { Flex } from 'rebass';

import { Icon, Group, GeneralText, Space } from 'atoms';
import { AngleDown } from 'assets/icons';

const EntryFees = ({ entryFees, defaultEntryFeeAmount, onEntryFeeSelect }) => {
  const [selected, setSelected] = useState(defaultEntryFeeAmount);

  useEffect(() => {
    setSelected(defaultEntryFeeAmount);
  }, [defaultEntryFeeAmount]);
  return (
    <Flex
      as="label"
      htmlFor="EntryFees"
      css={theme => ({
        position: 'relative',
        height: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        backgroundColor: theme.colors.white,
        borderRadius: '8px',
        borderColor: theme.colors.secondary,
        borderWidth: '2px',
        borderStyle: 'solid',
      })}>
      {defaultEntryFeeAmount == 0 ? (
        <Group data-guided-tour="entry-fee-free" isCentered isVertical>
          <GeneralText isLarge>
            <b>FREE</b>
          </GeneralText>
          <GeneralText>
            <b>CONTEST</b>
          </GeneralText>
        </Group>
      ) : (
        <>
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'flex-start',
              marginRight: 'auto',
            }}>
            <GeneralText isLarge>
              <strong>{`$${selected.toFixed(2)}`}</strong>
            </GeneralText>
            <GeneralText isMedium color="black">
              Entry Fee
            </GeneralText>
          </Flex>
          <Space size={10} />
          <Icon size={24}>
            <AngleDown />
          </Icon>
          <Select
            id="EntryFees"
            value={selected}
            onChange={e => {
              onEntryFeeSelect(e.target.value);
              setSelected(e.target.value * 1);
            }}
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '0',
              border: 'none',
              height: '100%',
              width: '100%',
              fontSize: '1.3rem',
              fontWeight: '900',
              color: 'transparent',
              bg: 'transparent',
              mozAppearance: 'none',
              webkitAppearance: 'none',
              appearance: 'none',
              '& option': {
                color: 'primary',
              },
              '& ~ svg': {
                display: 'none',
              },
            }}>
            {entryFees.map(fee => (
              <option
                key={fee.id}
                value={fee.entryFee}
                disabled={fee.isDisabled}>{`$${fee.entryFee.toFixed(
                2
              )}`}</option>
            ))}
          </Select>
        </>
      )}
    </Flex>
  );
};

EntryFees.defaultProps = {
  onEntryFeeSelect: () => {},
  entryFees: [0],
  defaultEntryFeeAmount: 0,
};

EntryFees.propTypes = {
  entryFees: PropTypes.arrayOf(
    PropTypes.shape({
      entryFee: PropTypes.number.isRequired,
      disabled: PropTypes.bool,
      beginnersOnly: PropTypes.bool,
      full: PropTypes.bool,
      gameType: PropTypes.number,
      id: PropTypes.string,
      maxEntries: PropTypes.number,
      payout: PropTypes.number,
      timeBlockId: PropTypes.string,
    })
  ).isRequired,
  defaultEntryFeeAmount: PropTypes.number.isRequired,
  onEntryFeeSelect: PropTypes.func,
};

export default EntryFees;
