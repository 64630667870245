import React from 'react';
import PropTypes from 'prop-types';
import { Link as RebassLink } from 'rebass';

import colors from 'assets/themes/colors';

const Link = ({ to, children, color, isSmall, isMedium, isLarge }) => {
  let variant = 'regular';
  if (isSmall) variant = 'small';
  if (isMedium) variant = 'medium';
  if (isLarge) variant = 'large';

  return (
    <RebassLink
      variant={`text.${variant}`}
      href={to || children}
      sx={{
        color: color,
      }}>
      {children}
    </RebassLink>
  );
};

Link.defaultProps = {
  color: colors.accent,
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  color: PropTypes.string,
  isSmall: PropTypes.bool,
  isMedium: PropTypes.bool,
  isLarge: PropTypes.bool,
};

export default Link;
