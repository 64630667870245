import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { Group, ImageWrapper, Space, Title } from 'atoms';

import PlayerAvatar from 'molecules/PlayerAvatar';
import PersonHeading from 'molecules/PersonHeading';

import playerCardBg from 'assets/media/images/playerCardBg.png';

const AvatarWrapper = styled(Group)`
  background-image: url(${playerCardBg});
  background-repeat: no-repeat;
  background-position: center;
`;

const NumberWrapper = styled(Group)`
  height: 70px;
  width: 70px;
  max-height: 70px;
  max-width: 70px;
`;

const PlayerProfileHeader = ({
  teamLogoUrl,
  teamName,
  fullName,
  picture,
  position,
  playerNumber,
}) => {
  return (
    <>
      <div style={{ margin: -15 }}>
        <AvatarWrapper isCentered isVerticalCentered isVertical>
          <Space isVertical isMedium />
          <PlayerAvatar
            source={picture}
            isSecondary
            imageBackground={colors.lightGray}
          />
        </AvatarWrapper>
        <Space isVertical isMedium />
      </div>

      <Group isCentered isVerticalCentered style={{ bg: colors.lightGray }}>
        {teamLogoUrl && (
          <ImageWrapper
            source={teamLogoUrl}
            alt={`${teamName} logo`}
            height={'70px'}
          />
        )}
        <PersonHeading subtitle={position}>{`${fullName}${
          teamLogoUrl ? '' : ` (${teamName})`
        }`}</PersonHeading>
        {playerNumber && (
          <NumberWrapper isCentered isVerticalCentered>
            <Title isLarge>{playerNumber ? `#${playerNumber}` : ''}</Title>
          </NumberWrapper>
        )}
      </Group>
    </>
  );
};

PlayerProfileHeader.defaultProps = {
  teamName: '',
  position: '',
  playerNumber: 0,
};

PlayerProfileHeader.propTypes = {
  teamLogoUrl: PropTypes.string,
  teamName: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  picture: PropTypes.string,
  position: PropTypes.string,
  playerNumber: PropTypes.number,
};

export default PlayerProfileHeader;
