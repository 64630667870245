import React, { useState } from 'react';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';

import { Space, Group, GeneralText, Button } from 'atoms';

import { MeteorFantasyLogo } from 'molecules';

import SocialSignIn from 'organisms/SocialSignIn';

import { returnTo } from 'services/utilities';

const Home = () => {
  const [isSignIn, setSignInStatus] = useState(false);
  return (
    <Group
      isVertical
      isCentered
      style={{
        width: '100vw',
        padding: 20,
        textAlign: 'center',
        margin: 'auto',
        maxWidth: 415,
      }}>
      <Space size={60} isVertical />
      <MeteorFantasyLogo size={200} />
      <Space isLarge isVertical />

      <Button
        disabled={isSignIn}
        specSelector="auth-select-signup"
        isFullWidth
        onClick={() => navigate(returnTo('/auth/signup'))}>
        JOIN
      </Button>
      <br />
      <Button
        disabled={isSignIn}
        specSelector="auth-select-signin"
        isFullWidth
        isOutlined
        onClick={() => navigate(returnTo('/auth/signin'))}>
        LOGIN
      </Button>

      <Space isMedium isVertical />

      <Group isVertical isCentered isFullWidth spaceSize={10}>
        <GeneralText isSmall color={colors.quaternary}>
          SOCIAL LOGIN
        </GeneralText>
        <SocialSignIn
          onSignIn={() => setSignInStatus(true)}
          onStateChange={() => setSignInStatus(false)}
        />
      </Group>

      <Space isLarge isVertical />

      <Group isCentered isFullWidth>
        <Button
          specSelector="auth-select-app"
          isBare
          onClick={() => navigate('/')}>
          <GeneralText color={colors.white}>PLAY NOW!</GeneralText>
        </Button>
      </Group>
    </Group>
  );
};

export default Home;
