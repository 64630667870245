import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Alert from 'molecules/Alert';
import { GeneralText } from 'atoms';

const useAlert = config => {
  const { defaultOpen } = config || {};

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen !== undefined) setIsOpen(defaultOpen);
    return () => setIsOpen(false);
  }, []);

  const toggle = state => {
    if (typeof state !== 'boolean') state = !isOpen;
    setIsOpen(state);
  };

  const AlertComponent = ({
    children,
    onClickToCancel,
    onClickToProceed,
    onClickToClose,
    ...rest
  }) =>
    isOpen ? (
      <Alert
        isOpen
        onClickToCancel={() => {
          setIsOpen(false);
          if (onClickToCancel) onClickToCancel();
        }}
        onClickToProceed={() => {
          setIsOpen(false);
          if (onClickToProceed) onClickToProceed();
        }}
        onClickToClose={() => {
          setIsOpen(false);
          if (onClickToClose) onClickToClose();
        }}
        {...rest}>
        <GeneralText>{children}</GeneralText>
      </Alert>
    ) : null;

  AlertComponent.propTypes = {
    children: PropTypes.any,
    onClickToClose: PropTypes.func,
    onClickToCancel: PropTypes.func,
    onClickToProceed: PropTypes.func,
  };

  return [AlertComponent, toggle];
};

export default useAlert;
