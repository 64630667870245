import React from 'react';
import PropTypes from 'prop-types';

import { GeneralText as Text, Title, Group } from 'atoms';

const PersonHeading = ({ children, subtitle, ...rest }) => {
  return (
    <Group isVertical isCentered {...rest}>
      <Title isMedium>{children}</Title>
      {subtitle && <Text isMedium>{subtitle}</Text>}
    </Group>
  );
};

PersonHeading.defaultProps = {
  subtitle: null,
};

PersonHeading.propTypes = {
  children: PropTypes.node,
  subtitle: PropTypes.node,
};

export default PersonHeading;
