export const stateBlacklist = ['AL', 'AZ', 'HI', 'ID', 'IA', 'LA', 'MT', 'NV']; // Removed 'WA'

export const countryWhitelist = ['US', 'AR', 'BR', 'UY', 'DO']; // Added AR, BR, UY and DO

export const stateWaitingList = [
  'AR',
  'CO',
  'CT',
  'DE',
  'IN',
  'KS',
  'ME',
  'MD',
  'MS',
  'MO',
  'NH',
  'NJ',
  'OH',
  'PA',
  'TN',
  'VT',
  'VA',
]; // Removed 'NY'
