import React from 'react';
import PropTypes from 'prop-types';

import { useUserData } from 'hooks';

import { phoneMask } from 'services/inputMasks';
import { BasicTextCard } from 'organisms';

const ProfileCard = () => {
  const [{ displayName, phoneNumber, email }] = useUserData();

  const contactInfo = [
    { label: 'Display Name', value: displayName ? displayName : `NONE` },
    {
      label: 'Phone',
      value: phoneNumber ? phoneMask.resolve(phoneNumber) : `NONE`,
    },
    { label: 'Email', value: email },
  ];

  return <BasicTextCard data={contactInfo} />;
};

ProfileCard.defaultProps = {};

ProfileCard.propTypes = {
  contactInfo: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
export default ProfileCard;
