/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from '@emotion/styled';

import colors from 'assets/themes/colors';
import { GeneralText, Group, Title } from 'atoms';

const Wrapper = styled(Group)`
  background-color: ${colors.white};
`;

const TermsAndConditions = () => (
  <Wrapper isVertical padding="20px 20px" spaceSize={20}>
    <Title isSmall>1. Acceptance of terms</Title>
    <GeneralText isMedium>
      Meteor Fantasy, Inc. (collectively, "We", "Us", or "Meteor Fantasy")
      provide a fantasy sports website located at Meteor Fantasy.com (the
      "Site") and a related mobile app — which include all of the text, images,
      audio, code and other material they contain or provide (collectively, the
      "Content") and all of the features, contests and other services they
      provide. Residents of the United States will be contracting with Meteor
      Fantasy, Inc. The Site, the mobile app, and any other features, tools,
      materials, or other services (including co-branded or affiliated services)
      offered from time to time by Meteor Fantasy are referred to here as the
      "Service." Please read these Terms of Use (the "Terms" or "Terms of Use")
      carefully before using the Service. By using or otherwise accessing the
      Service, or clicking to accept or agree to these Terms where that option
      is made available, you (1) accept and agree to these Terms and our
      additional Rules and Scoring system and (2) consent to the collection,
      use, disclosure and other handling of information as described in our
      Privacy Policy and (3) any additional terms, rules and conditions of
      participation in particular contests issued by Meteor Fantasy from time to
      time. If you do not agree to the Terms, then you may not access or use the
      Content or Services.
      <br />
      Meteor Fantasy may issue additional terms, rules and conditions of
      participation in particular contests. For example, Meteor Fantasy may
      issue conditions as required by various state rules and regulations, which
      may impact your experience or participation on the Service. You agree to
      be subject to those additional rules if you participate in such contests.
    </GeneralText>

    <Title isSmall>2. Modification of Terms of Use</Title>
    <GeneralText isMedium>
      Except for Section 15, providing for binding arbitration and waiver of
      class action rights, Meteor Fantasy reserves the right, at its sole
      discretion, to modify or replace the Terms of Use at any time. The most
      current version of these Terms will be posted on our Site. You shall be
      responsible for reviewing and becoming familiar with any such
      modifications. If a revision to the Terms, in our sole discretion, is
      material, we will notify you by contacting you through the email address
      associated with your account. Use of the Services by you after any
      modification to the Terms constitutes your acceptance of the Terms of Use
      as modified.
    </GeneralText>

    <Title isSmall>3. Eligibility</Title>
    <GeneralText isMedium>
      You hereby represent and warrant that you are fully able and competent to
      enter into the terms, conditions, obligations, affirmations,
      representations and warranties set forth in these terms and to abide by
      and comply with these terms. By depositing money or entering a contest,
      you are representing and warranting that:
      <br />
      <br />
      ● you are of 18 years of age or older (21 years of age or older in
      Massachusetts)
      <br />
      ● you are a citizen or resident of the United States of America and that
      you have an address in the United States of America;
      <br />
      ● at the time of deposit or game entry you are physically located in the
      United States of America in a jurisdiction in which participation in the
      contest is not prohibited by applicable law;
      <br />
      ● you are not listed on any U.S. Government list of prohibited or
      restricted parties;
      <br />
      ● you will abide at all times by these Terms of Use and any other
      agreements between you and Meteor Fantasy regarding your use of the
      Service or participation in games;
      <br />
      ● when depositing funds or entering a paid contest, you are not physically
      located in of any of the following states: Alabama, Arizona, Arkansas,
      Colorado, Connecticut, Delaware, Hawaii, Idaho, Indiana, Iowa, Kansas,
      Louisiana, Maine, Maryland, Mississippi, Missouri, Montana, Nevada, New
      Hampshire, New Jersey, New York, Ohio, Pennsylvania, Tennessee,
      Washington, Vermont or Virginia;
      <br />
      ● you are not subject to backup withholding tax because: (a) you are
      exempt from backup withholding, or (b) you have not been notified by the
      Internal Revenue Service (IRS) that you are subject to backup withholding
      as a result of a failure to report all interest or dividends, or (c) the
      IRS has notified you that you are no longer subject to backup withholding.
      <br />
      ● When entering any contest that awards prizes, you are not an employee or
      operator of another daily fantasy site that charges entrance fees or
      offers cash prizes
      <br />
      ● You do not, by virtue of affiliation with another daily fantasy site,
      have access to the site’s pre-release non-public confidential data about
      contest-related information.
      <br />
      <br />
      If Meteor Fantasy determines that you do not meet the eligibility
      requirements of this section, then you are not authorized to use the
      Service. Meteor Fantasy may require you to provide proof that you are
      eligible to participate according to this section prior to receiving a
      prize. This includes by requesting that you fill out an affidavit of
      eligibility or other verification information (as discussed in Section 4
      below). If Meteor Fantasy otherwise determines that you do not meet the
      eligibility requirements of this section, in addition to any rights that
      Meteor Fantasy may have in law or equity, Meteor Fantasy reserves the
      right to terminate your account, withhold or revoke the awarding of any
      prizes associated with your account or limit your ability to withdraw. In
      such a situation, Meteor Fantasy may pay out any withheld or revoked
      prizes to the other entrants in the relevant contest in a manner
      consistent with the prize structure of the contest, to be precisely
      determined by Meteor Fantasy in its sole discretion. Meteor Fantasy also
      reserves the right to withhold revoked prizes to use in furtherance of its
      fraud prevention or anti-money laundering efforts. Meteor Fantasy
      employees may use the Service for the purpose of testing the user
      experience, but may not withdraw money or prizes except when playing in a
      private league. Relatives of Meteor Fantasy employees with whom they share
      a household are not eligible to participate in paid contests unless they
      are private contests with other Meteor Fantasy employees or household
      members. Meteor Fantasy consultants or promoters of the Service may play
      in contests without such limitation, but only if (i) their arrangement
      with Meteor Fantasy does not permit them to have any access to non-public
      Service data or any other data not made available to all players on the
      Service and (ii) they do not receive any other advantages in their play on
      the Service.
      <br />
      Employees or operators of other daily fantasy sites that charge entry fees
      or offer cash prizes, including but not limited to DraftKings and Yahoo,
      and individuals who, by virtue of affiliation with another daily fantasy
      site, have access to the site’s pre-release non-public confidential data
      about game-related information may not enter any contests in which a real
      money prize is awarded. If such person enters a Meteor Fantasy contest
      that awards prizes, Meteor Fantasy will disqualify the entry, will not
      award a prize, and may report such person’s violation of this provision to
      the daily fantasy site for which the entrant is employed by, operates or
      affiliated with. Additionally, Meteor Fantasy may maintain information
      about the entrant sufficient to assist Meteor Fantasy in blocking the user
      from entering future Meteor Fantasy contests, unless and until Meteor
      Fantasy determines, in its sole discretion, that the entrant is no longer
      an employee or operator of another daily fantasy site or no longer has
      access to pre-release non-public confidential data about game-related
      information by virtue of affiliation with a daily fantasy site.
      <br />
      Athletes, coaches and other team management, team support personnel (e.g.
      without limitation, team physicians) and team owners may not participate
      in any Meteor Fantasy contests in the sport or sports with which they’re
      associated. Team owners, referees, league employees, sports commissioners
      and other individuals who through an ownership interest or game-related
      employment can influence the gameplay are likewise ineligible.
      <br />
      Meteor Fantasy offers proactive tools and support meant to encourage
      healthy player behavior and deliver positive player experiences. We also
      allow qualified third parties, who have concerns about a player’s ability
      to manage his or her play, to request a limitation on that player’s use of
      Meteor Fantasy. You can learn more about our responsible play policies and
      tools at https://www.MeteorFantasy.com/responsible.
      <br />
      Meteor Fantasy is not affiliated with or sponsored by the National
      Football League, the National Hockey League or Major League Baseball.
    </GeneralText>

    <Title isSmall>4. Conditions of participation</Title>
    <GeneralText isMedium>
      <b>4.1 Registration</b>
      <br />
      In order to participate in a contest on the Service, you must register for
      an account. By registering as a user of the Service, you agree to provide
      accurate, current and complete information about yourself as prompted
      (such information being the "Registration Data") and maintain and promptly
      update the Registration Data to keep it accurate, current and complete. If
      you provide any information that is inaccurate, not current or incomplete,
      or Meteor Fantasy has reasonable grounds to suspect that such information
      is inaccurate, not current or incomplete, Meteor Fantasy may deny you
      access to areas requiring registration, or terminate your account, at its
      sole discretion.
      <br />
      You may establish, maintain, use and control only one account on the
      Service. Each account on the Service may only be owned, maintained, used
      and controlled by one individual. For avoidance of doubt, users may not
      "co-own" accounts on the Service. In the event Meteor Fantasy determines
      that you have opened, maintained, used or controlled more than one
      account, in addition to any other rights that Meteor Fantasy may have,
      Meteor Fantasy reserves the right to suspend or terminate any or all of
      your accounts and terminate, withhold or revoke the awarding of any
      prizes.
      <br />
      You agree that the sole and specific purpose of creating an account on
      Meteor Fantasy is to participate in fantasy sports contests on the
      Service. Meteor Fantasy shall be entitled to suspend, limit or terminate
      your account if we determine, in our sole discretion, that you are
      depositing funds without the intention of using them in contests on the
      Service. In such circumstances, we may also report such activity to
      relevant authorities.
      <br />
      <b>4.2 Account Password and Security</b>
      <br />
      At the time of registration for online account access, you must provide a
      valid email address and supply a Username and Password to be used in
      conjunction with your account. You may not use a Username that promotes a
      commercial venture or a Username that Meteor Fantasy in its sole
      discretion deems offensive. Many portions of the Service require
      registration for access (the "Restricted Areas"). You are responsible for
      maintaining the confidentiality of Password, and are fully responsible for
      all uses of your Username and Password, whether by you or others. You
      agree to (a) never to use the same Password for the Service that you use
      or have ever used outside of the Service; (b) keep your Username and
      Password confidential and not share them with anyone else; (c) immediately
      notify Meteor Fantasy of any unauthorized use of your Username and
      Password or account or any other breach of security; and (d) use only your
      own Username and Password to access the Service’s Restricted Areas. Meteor
      Fantasy cannot and will not be liable for any loss or damage arising from
      your failure to comply with this Section.
      <br />
      You acknowledge and agree that Meteor Fantasy is authorized to act on
      instructions received through the use of your Username and Password, and
      that Meteor Fantasy may, but is not obligated to, deny access or block any
      transaction made through use of your Username and Password without prior
      notice if we believe your Username and Password are being used by someone
      other than you, or for any other reason.
      <br />
      Meteor Fantasy may require you to change your Username or may unilaterally
      change your Username.
      <br />
      <b>4.3 Communications and Information Practices</b>
      <br />
      As a result of your registration for the Service, you may receive certain
      commercial communications from Meteor Fantasy. You understand and agree
      that these communications are part of your registration, and that, to the
      extent required by law, you may opt out of receiving these communications
      at any time by either using the unsubscribe functionality or sending an
      email to info [at] Meteor Fantasy [dot] com. Following such an opt-out, we
      may still communicate with you via email to the extent permitted by
      applicable law.
      <br />
      <br />
      <b>4.4 Disqualification and Cancellation</b>
      <br />
      Meteor Fantasy also reserves the right to cancel contests, in our sole
      discretion, without any restrictions. Meteor Fantasy, in its sole
      discretion, may disqualify you from a contest or the entire Service,
      refuse to award fantasy points or prizes and require the return of any
      prizes, or suspend, limit, or terminate your account if you engage in
      conduct Meteor Fantasy deems, in its sole discretion, to be improper,
      unfair, fraudulent or otherwise adverse to the operation of the Service or
      in any way detrimental to other users. Improper conduct includes, but is
      not limited to: falsifying personal information, including payment
      information, required to use the Service or claim a prize; violating
      eligible payment method terms, including the terms of any cash rewards
      payment card, violating any of these rules, accumulating points or prizes
      through unauthorized methods such as unauthorized scripts or other
      automated means; tampering with the administration of the Service or
      trying to in any way tamper with the computer programs associated with the
      Service; obtaining other entrants’ information and spamming other
      entrants; and abusing the Service in any way; or otherwise violating these
      Terms of Use. You acknowledge that the forfeiture and/or return of any
      prize shall in no way prevent Meteor Fantasy from informing the relevant
      authorities, and/or pursuing criminal or civil proceedings in connection
      with such conduct. If for any reason the Service is not running as
      originally planned (e.g., if the Site becomes corrupted or does not allow
      the proper usage and processing of entries in accordance with the rules,
      or if infection by a computer virus, bugs, tampering, unauthorized
      intervention, actions by entrants, fraud, technical failures, or any other
      causes of any kind, in the sole opinion of Meteor Fantasy corrupts or
      affects the administration, security, fairness, integrity or proper
      conduct of the Service), Meteor Fantasy reserves the right, in its sole
      discretion, to disqualify any individual implicated in or relating to the
      cause and/or to cancel, terminate, extend, modify or suspend the Service,
      and select the winner(s) from all eligible entries received. If such
      cancellation, termination, modification or suspension occurs, notification
      may be posted on the Site. The failure of Meteor Fantasy to comply with
      any provision of these Terms due to an act of God, hurricane, war, fire,
      riot, earthquake, terrorism, act of public enemies, actions of
      governmental authorities outside of the control of Meteor Fantasy
      (excepting compliance with applicable codes and regulations) or other
      force majeure event will not be considered a breach of these Terms.
      <br />
      <br />
      <b>4.5 Deposits and Withdrawals Generally</b>
      <br />
      By depositing funds or entering paid contests, you agree to provide us
      with a valid mailing address, date of birth and social security number and
      any other information we may require in order to run appropriate identity
      checks and comply with applicable rules and regulations. If necessary, you
      may be required to provide appropriate documentation that allows us to
      verify you. While your account is pending verification, you may be able to
      deposit funds into your account and participate in contests, but you will
      not be able to withdraw any funds from your account until verification is
      complete. If we are unable to verify you, we reserve the right to suspend
      your account and withhold any funds until such time as we have been able
      to successfully verify you. We also may conduct checks for Terms
      compliance, including anti-fraud checks on playing patterns and deposits
      prior to processing a withdrawal, and we may request additional
      information before permitting a withdrawal. Subject to such checks, you
      may close your account and withdraw your deposits and/or winnings at any
      time and for any reason. Deposits, and player winnings after contests are
      finished, are held in a separate, segregated bank account by a subsidiary
      of Meteor Fantasy, Inc. These funds belong to you, subject to review for
      evidence of fraud, verification or other prohibited conduct as described
      above, and Meteor Fantasy may not use them to cover its operating expenses
      or for other purposes. Your withdrawals will be made from this segregated
      bank account, and checks issued from that account may bear the name of
      Meteor Fantasy’s subsidiary. Users may cancel entries up to 30 minutes
      after submitting the entry. Cancellation is not allowed if there are less
      than 90 minutes between the submission and the start of the earliest
      scheduled competition within that contest group (early, afternoon, or
      late). If you timely cancel an entry, your entry fee will be refunded back
      into your Meteor Fantasy account. Meteor Fantasy has no obligation to
      honor cancellation requests received 30 minutes after submitting the entry
      or after the game starts. Similarly, contests lock 15 minutes before the
      earliest scheduled competition within that contest group (early,
      afternoon, or late), and no more entries or prediction changes may be made
      at that time. Deposits made on our site will appear on your statement as
      METEORFANTASY. All payments are final. No refunds will be issued. In the
      event of a dispute regarding the identity of the person submitting an
      entry, the entry will be deemed submitted by the person in whose name the
      account was registered. You may withdraw prize winnings and deposits
      (excluding bonuses, free credits and promotions, subject to the terms of
      said bonuses) from your Account Balances subject to the following terms.
      Any amount deposited can be withdrawn in full, minimum of $25 USD. Meteor
      Fantasy cannot, per US Anti Money Laundering Laws, facilitate a withdrawal
      for an amount equal to the deposit made until such time as a one (1) paid
      contest entry has been submitted and settled. Meteor Fantasy may limit the
      amount a user can deposit into his or her account in accordance with
      state-imposed deposit limits. In cases where you have participated in a
      promotion to receive a bonus or other benefit, you may be required to play
      through your deposit (by entering contests) whose total entry fees equal
      the value of the deposit to receive the entirety of such bonus.
      <br />
      <br />
      <b>4.6 Taxation</b>
      <br />
      Each year all winners who have won $600 or more over the previous year
      must provide updated address and social security details to Meteor
      Fantasy. These details will be used to allow Meteor Fantasy to comply with
      tax regulations and may be shared with appropriate tax authorities. You,
      not Meteor Fantasy, are responsible for filing and paying applicable state
      and federal taxes on any winnings. Meteor Fantasy does not provide tax
      advice, nor should any statements in this agreement or on the Service be
      construed as tax advice.
      <br />
      <br />
      <b>4.7 Publicity</b>
      <br />
      By entering a contest, you consent to Meteor Fantasy’s and its service
      providers’ and business partners’ use of your name, voice, likeness,
      location and photograph in connection with the development, production,
      distribution and/or exploitation (including marketing and promotion) of
      the selected contest and/or other Meteor Fantasy contests and Meteor
      Fantasy generally, unless otherwise prohibited by law. Meteor Fantasy Inc
      and its business partners reserve the right to make public statements
      about the entrants and winner(s), on-air, on the Internet, or otherwise,
      prior to, during, or following the contest. Entrants agree that Meteor
      Fantasy Inc may announce any winner's name on-air or on any of its
      websites or any other location at any time in connection with the
      marketing and promotion of Meteor Fantasy or other contests or games
      operated by Meteor Fantasy Inc. You agree that participation in and (where
      applicable) the winning of a prize in connection with a contest constitute
      complete compensation for your obligations under this paragraph, and you
      agree not to seek to charge a fee or impose other conditions on the
      fulfillment of these obligations. The rules specific to certain contests
      may contain additional publicity obligations or may require a written
      signature on a separate publicity waiver.
    </GeneralText>

    <Title isSmall>5. Game Rules</Title>
    <GeneralText isMedium>
      <b>5.1 Game of Skill</b>
      <br />
      Meteor Fantasy is a game of skill. Winners are determined by the criteria
      stated in each contest rules. For each contest, winners are determined by
      the individuals who use their skill and knowledge of relevant professional
      sports information and fantasy sports rules to accumulate the most fantasy
      points. Fantasy points are accumulated through the performance of
      individual athletes in sports events.
      <br />
      <br />
      <b>5.2 Entry fees</b>
      <br />
      Each Meteor Fantasy contest has an entry fee listed in US dollars. When
      you opt to participate in a contest, that amount in US dollars will be
      debited from your Meteor Fantasy account. Then, follow the links and
      instructions provided for entry. In the event of a dispute regarding the
      identity of the person submitting an entry, the entry will be deemed
      submitted by the person in whose Username the entry was submitted, or if
      possession of the Username itself is contested and in Meteor Fantasy’s
      opinion sufficiently uncertain, the name in which the email address on
      file was registered with the email service provider. Meteor Fantasy
      reserves the right not to award a prize to an individual it believes in
      its sole discretion did not submit the winning entry. Users may cancel
      entries up to 30 minutes after submitting the entry. Cancellation is not
      allowed if there are less than 90 minutes between the submission and the
      start of the earliest scheduled competition within that contest group
      (early, afternoon, or late). If you timely cancel an entry, your entry fee
      will be refunded back into your Meteor Fantasy account. Meteor Fantasy has
      no obligation to honor cancellation requests received 30 minutes after
      submitting the entry or after the game starts. Similarly, contests lock 15
      minutes before the earliest scheduled competition within that contest
      group (early, afternoon, or late), and no more entries or prediction
      changes may be made at that time.
      <br />
      <br />
      <b>5.3 Contest Term</b>
      <br />
      Meteor Fantasy offers contests for professional sports events generally
      taking place on a single day or at most a week.
      <br />
      <br />
      <b>5.4 Prizes</b>
      <br />
      After each contest ends, the tentative winners are announced (generally by
      the following day) but remain subject to final verification. The players
      in each contest who accumulate the most fantasy points and comply with
      eligibility requirements and applicable rules will win prizes as set out
      in the posted contest details. Prizes are added to the winning player's
      account balance. In the event of a tie, the prize is divided evenly
      between the tied players, unless otherwise specified. Meteor Fantasy
      offers a number of different types of contests. For each contest, we
      announce the entry fees and prizes in advance on the contest page. For a
      summary of entry fees and prizes for currently open contests please login
      and visit the main lobby. Prize calculations are based on the results as
      of the time when final scoring is tabulated by Meteor Fantasy. Once
      winners are initially announced by Meteor Fantasy, the scoring results
      will not be changed in light of official adjustments made by the
      professional leagues, though we reserve the right to make adjustments
      based on errors or irregularities in the transmission of information to us
      from our stats provider or in our calculation of results. We also may make
      adjustments in the event of noncompliance with the Terms. Meteor Fantasy
      has no obligation to delay the awarding of a prize in anticipation of any
      adjustment, and we reserve the right to reverse payments in the event of
      any adjustment. You agree to cooperate with our efforts to reverse
      payments. No substitution or transfer of a prize is permitted. All taxes
      associated with the receipt or use of any prize are the sole
      responsibility of the winner. In the event that the awarding of any prizes
      to winners of the Contest is challenged by any legal authority, Meteor
      Fantasy reserves the right in its sole discretion to determine whether or
      not to award or adjust such prizes. In all disputes arising out of the
      determination of the winner of Meteor Fantasy contests, Meteor Fantasy Inc
      is the sole judge and its actions are final and binding.
      <br />
      <br />
      <b>5.5 Notification</b>
      <br />
      Winners are generally posted on the Site after the conclusion of each
      contest by 10 AM ET on the following day. Winners may be requested to
      return via email or regular mail an affidavit of eligibility, a publicity
      agreement and appropriate tax forms by a specified deadline. Failure to
      comply with this requirement can result in disqualification. Any prize
      notification returned as undeliverable may result in disqualification and
      selection of an alternate winner. In addition, a list of winners and
      winner's names for each competition period may be obtained by writing to:
      Meteor Fantasy, Inc., PO Box 1162 Pineville, NC 28134.
      <br />
      <br />
      <b>5.6 Bonuses and Promotions</b>
      <br />
      We frequently offer bonuses to newly depositing users and for other
      marketing purposes. Certain bonuses are awarded as a "pending bonus"
      earned gradually when users enter and complete paid contests. A user’s
      pending bonus is converted into a monetary credit that can be used to play
      on Meteor Fantasy as the user enters real money contests in accordance
      with the terms of the offer or promotion. Unless otherwise stated, any
      unconverted pending bonus remaining in a player’s account 45 days after it
      has been initially credited can be removed by Meteor Fantasy. Any credit a
      new user receives is for entry into competitions on Meteor Fantasy and can
      only be withdrawn if they have been previously entered into at least one
      game. Additionally, if a user immediately withdraws money after a deposit
      which delivers a deposit bonus then the bonus will be retracted. In the
      event of abuse of the bonus system by any user, Meteor Fantasy reserves
      the right to retract your user bonuses. Meteor Fantasy may also offer
      Meteor Fantasy Points, or MFP, in accordance with the terms of a promotion
      or for other marketing purposes at Meteor Fantasy’s sole discretion. We
      frequently offer monetary credits for players competing in "Beat The
      Expert" competitions and Giveaway/Freeroll tournaments or for referring
      new users to Meteor Fantasy. Meteor Fantasy reserves the right to reclaim
      these credits if players do not use them to enter real money contests
      within 1 month of their initial crediting, or within the minimum amount of
      time allowed by applicable law. These credits can be used to enter real
      money contests but cannot be immediately withdrawn.
    </GeneralText>

    <Title isSmall>6. Conduct</Title>
    <GeneralText isMedium>
      As a condition of use, you promise not to use the Services for any purpose
      that is unlawful or prohibited by these Terms, or any other purpose not
      reasonably intended by Meteor Fantasy. By way of example, and not as a
      limitation, you agree not to:
      <br />
      <br />
      ● abuse, harass, impersonate, intimidate or threaten other Meteor Fantasy
      users;
      <br />
      ● post or transmit, or cause to be posted or transmitted, any Content that
      are infringing, libelous, defamatory, abusive, offensive, obscene,
      pornographic or otherwise violates any law or right of any third party;
      <br />
      ● use the Service for any unauthorized purpose, or in violation of any
      applicable law, including intellectual property laws;
      <br />
      ● post or transmit, or cause to be posted or transmitted, any
      communication or solicitation designed or intended to obtain password,
      account, or private information from any Meteor Fantasy user;
      <br />
      ● create or submit unwanted email ("Spam") to any other Meteor Fantasy
      users;
      <br />
      ● infringe upon the intellectual property rights of Meteor Fantasy, its
      users, or any third party;
      <br />
      ● submit comments linking to affiliate programs, multi-level marketing
      schemes, sites repurposing existing stories or off-topic content;
      <br />
      ● post, email, transmit, upload, or otherwise make available any material
      that contains software viruses or any other computer code, files or
      programs designed or functioning to interrupt, destroy, or limit the
      functionality of any computer software or hardware or telecommunications
      equipment;
      <br />
      ● use any robot, spider, scraper, sniping software or other automated
      means to access the Service for any purpose (except for RSS feed access)
      without our express written permission. Additionally, you agree that you
      will not: (1) take any action that imposes, or may impose in our sole
      discretion an unreasonable or disproportionately large load on our
      infrastructure; (2) interfere or attempt to interfere with the proper
      working of the Service or any activities conducted on the Service; or (3)
      bypass any measures we may use to prevent or restrict access to the
      Service;
      <br />
      ● use artificial means, including creating multiple user accounts, to
      inflate your position and standing with the Meteor Fantasy leaderboards
      and community;
      <br />
      ● use unauthorized scripts; all authorized scripts will be made available
      through the Service prior to game entry;
      <br />● advertise to, or solicit, any user to buy or sell any products or
      Service, or use any information obtained from the Service in order to
      contact, advertise to, solicit, or sell to users without their prior
      explicit consent;
    </GeneralText>
  </Wrapper>
);

export default TermsAndConditions;
