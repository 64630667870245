import React from 'react';
import PropTypes from 'prop-types';

import colors, { invertColor } from 'assets/themes/colors';

// eslint-disable-next-line react/prop-types
const Wrapper = ({ children, color, size, position, ...rest }) => (
  <span
    css={{
      ...position,
      alignItems: 'center',
      display: 'inline-flex',
      justifyContent: 'center',
      lineHeight: 1,
      svg: {
        width: 'auto',
        height: size ? size + 'px' : 'auto',
      },
      'path, circle': {
        fill: color || 'inherit',
      },
    }}
    {...rest}>
    {children}
  </span>
);

const Icon = ({
  children,
  color,
  colorByBackground,
  size,
  position,
  ...rest
}) => (
  <Wrapper
    position={position}
    color={colorByBackground ? invertColor(colorByBackground) : color}
    size={size}
    {...rest}>
    {children}
  </Wrapper>
);

Icon.defaultProps = {
  color: colors.primary,
  size: 16,
};

Icon.propTypes = {
  children: PropTypes.element.isRequired,
  position: PropTypes.exact({
    position: PropTypes.string,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
  }),
  color: PropTypes.string,
  colorByBackground: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
