import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { toObjectWithUid } from './utilities';

const db = firebase.firestore;

export default {
  get: (uid, callback) =>
    new Promise((resolve, reject) => {
      db()
        .collection(process.env.firestoreUsers)
        .doc(uid)
        .onSnapshot(
          snapshot => (callback || resolve)(toObjectWithUid(snapshot)),
          reject
        );
    }),
};
