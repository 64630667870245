import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { Icon, Input } from 'atoms';

export const iconProps = {
  color: colors.accent,
  size: 18,
  style: { zIndex: 10 },
};

const InputWrapper = ({
  iconSvg,
  values,
  errors,
  handleChange,
  name,
  ...rest
}) => (
  <>
    <Input
      leftIcon={
        <Icon
          {...iconProps}
          color={errors[name] ? colors.alert : colors.accent}>
          {iconSvg}
        </Icon>
      }
      name={name}
      value={values[name]}
      placeholder={name[0].toUpperCase() + name.slice(1)}
      {...rest}
      onChange={handleChange}
    />
  </>
);

InputWrapper.propTypes = {
  iconSvg: PropTypes.element,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  name: PropTypes.string,
};

export default InputWrapper;
