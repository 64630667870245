import React from 'react';

import { Contact as ContactPage } from 'pages';
import Wrapper from './Wrapper';

const Contact = () => (
  <Wrapper title="Contact Us">
    <ContactPage />
  </Wrapper>
);

export default Contact;
