import React from 'react';

import { Group } from 'atoms';

import { getParamByName } from 'services/utilities';

import VerifyEmail from './VerifyEmail';
import ResetPassword from './ResetPassword';

const Actions = () => {
  const actionCode = getParamByName('oobCode');
  const mode = getParamByName('mode');
  const uid = getParamByName('uid');
  let View = null;
  switch (mode) {
    case 'resetPassword':
      View = <ResetPassword {...{ actionCode, uid }} />;
      break;
    case 'verifyEmail':
      View = <VerifyEmail {...{ actionCode, uid }} />;
      break;
    default:
      break;
  }
  return (
    <Group
      isVertical
      isFullWidth
      isCentered
      isVerticalCentered
      style={{ margin: 'auto', maxWidth: 415 }}>
      {View}
    </Group>
  );
};

export default Actions;
