import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import { MainContext } from 'containers/MainContainer';
import { navigate } from '@reach/router';

// Components
import Wrapper from './components/Wrapper';
import TeamsSelector from './components/TeamsSelector';
import CurrentTeamCard from './components/CurrentTeamCard';

import { Button, Space, GeneralText } from 'atoms';

import useGameSteps from 'hooks/useGameSteps';
import { useDispatch } from 'react-redux';

import { useDraftContest } from 'organisms/ContestBuilder';

import useGuidedTour from 'hooks/guidedTour';
import { teamSelectionTour } from 'hooks/guidedTour/tours';
import DayDivisor from './components/DayDivisor';

export const TeamsSelection = props => {
  const {
    availableTeams,
    fetchAvailableTeams,
    gameType,
    selectedDayDivisor,
    isLoading,
    teams,
    updateSelectedTeams,
  } = props;

  const dispatch = useDispatch();

  const { isOnline } = useContext(MainContext);

  const dayDivisors = Object.keys(availableTeams);
  const defaultDayDivisor = selectedDayDivisor || dayDivisors[0];

  const [listedTeams, updateList] = useState([]);
  const [dayDivisor, setDayDivisor] = useState(defaultDayDivisor);

  const [selection, updateSelection] = useState(teams || []);

  const [teamNumber, setTeamNumber] = useState(0);

  const { draft, isReady, update } = useDraftContest();

  const isSelectionDone = selection.length === 2;

  const tourInteractions = [
    {
      stepIndex: 4,
      selector: '[data-guided-tour-emulate-click="team-logo"]',
      nextStepDelay: 2000,
    },
  ];

  const [Tour] = useGuidedTour({
    autoStart: true,
    isControlled: true,
    tourInteractions,
    tourName: teamSelectionTour.name,
  });

  useEffect(() => {
    setDayDivisor(defaultDayDivisor);
  }, [defaultDayDivisor]);

  useEffect(() => {
    if (isOnline && isReady) {
      fetchAvailableTeams({ sport: draft.sport });
    }
  }, [draft.sport, isReady]);

  useEffect(() => {
    updateList(availableTeams[dayDivisor || defaultDayDivisor]);
  }, [availableTeams, dayDivisor]);

  useEffect(() => {
    if (isSelectionDone) {
      update({ lineups: selection });
      updateSelectedTeams(selection);
    }
  }, [selection]);

  useEffect(() => {
    if (isSelectionDone && teams === null) {
      updateSelection([]);
      update({ lineups: null });
      setTeamNumber(0);
    }
  }, [teams]);

  if (!useGameSteps(['gameType']).isReadyByValidator) {
    navigate('/gametype');
    return null;
  }

  return (
    <Wrapper isReady={isReady && !isLoading}>
      <DayDivisor
        dayDivisors={dayDivisors}
        defaultSelected={defaultDayDivisor}
        availableTeams={availableTeams}
        onChange={selected => {
          updateSelection([]);
          update({ lineups: null });
          setTeamNumber(0);
          setDayDivisor(selected);
          updateList(availableTeams[selected]);
        }}
      />

      {!listedTeams && (
        <GeneralText
          style={{
            width: '100%',
            height: 80,
            textAlign: 'center',
            padding: '32px 30px 10px',
          }}>
          {isLoading ? (
            'Loading...'
          ) : (
            <>
              There are currently no active games <br />
              for <b>{String(draft.sport).toUpperCase()}</b> in this Week
            </>
          )}
        </GeneralText>
      )}

      <TeamsSelector
        listedTeams={listedTeams}
        selection={selection}
        onSelect={({ name }) => {
          dispatch({ type: 'RESET_PREDICTIONS' });
          let selectionCopy = [...selection];
          if (selectionCopy.length === 0) {
            if (teamNumber === 1) setTeamNumber(0);
          }
          if (selectionCopy.length >= 1) {
            if (teamNumber === 0) setTeamNumber(1);
          }
          if (selectionCopy.length === 2) {
            selectionCopy.shift();
          }
          selectionCopy.push(listedTeams.filter(t => t.teamName === name)[0]);
          updateSelection(selectionCopy);
        }}
      />

      <Space isVertical size={8} />

      <CurrentTeamCard
        hasTeams={listedTeams && listedTeams.length > 0}
        currentTeam={selection[teamNumber]}
        gameType={gameType}
        current={teamNumber}
        onChange={setTeamNumber}
        onClickToClear={() => {
          updateSelection([]);
          update({ lineups: null });
          setTeamNumber(0);
        }}
      />

      {/* TODO: Move this to a molecule */}
      <div
        style={{
          width: '100%',
          padding: 15,
          backgroundColor: 'rgba(243,243,243,1)',
          boxShadow: isSelectionDone ? '0px -2px 4px rgba(0, 0, 0, 0.1)' : null,
          position: isSelectionDone ? 'sticky' : 'static',
          bottom: 0,
          zIndex: 6,
        }}>
        <Button
          data-guided-tour="lets-play"
          specSelector="teams-selection-finish"
          isFullWidth
          style={{ margin: 'auto', maxWidth: 415 }}
          disabled={!isSelectionDone}
          onClick={() => navigate('/gameplay')}>
          LET&#39;S PLAY!
        </Button>
      </div>

      <Tour steps={teamSelectionTour.steps} disableScrolling />
    </Wrapper>
  );
};

TeamsSelection.defaultProps = {
  availableTeams: {},
};

TeamsSelection.propTypes = {
  availableTeams: PropTypes.object,
  fetchAvailableTeams: PropTypes.func,
  gameType: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedDayDivisor: PropTypes.string,
  teams: PropTypes.array,
  updateSelectedTeams: PropTypes.func,
};

export default connect(
  store => ({
    ...store.teamsSelection,
    gameType: store.gameType.type,
  }),
  { ...actions }
)(TeamsSelection);
