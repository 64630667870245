import React from 'react';

export default [
  {
    target: '[data-guided-tour="side-drawer-avatar"]',
    title: 'Side Drawer',
    content: <div>sidedrawer stuff</div>,
    placement: 'left',
  },
];
