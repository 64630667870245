/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { GeneralText, Space, Group } from 'atoms';
import { StatsRow } from 'molecules';
import { BasicTextCard, PlayerProfileHeader } from 'organisms';

import { positions } from 'services/friendlyNames';
import formatMetrics from 'services/formatMetrics';

import { useDraftContest } from 'organisms/ContestBuilder';

import useCompetitorData from './useCompetitorData';
import moment from 'moment';

const PlayerProfile = ({ competitorId, seasonAverage }) => {
  // TODO: As a page (similar to a container, this component should not receive this amount of data to handle by it self).
  // We have to wrap it with another "container like" component or receive a payload and drill down to its children

  const { draft } = useDraftContest();

  const { response, status } = useCompetitorData(competitorId);

  if (!response) return null; // TODO: Change for a nice loading component

  let seasonPerformanceList = Object.keys(response.seasonResults || {})
    .map(w => Number(w.split('week')[1]))
    .sort((a, b) => a - b)
    .reverse();

  if (draft && draft.sport === 'nba') {
    seasonPerformanceList = Object.keys(response.seasonResults || {})
      .map(w => moment(w, 'YYYY-MMM-DD').format('YYYYMMDD'))
      .sort((a, b) => a - b)
      .reverse();
  }

  return (
    <>
      <PlayerProfileHeader
        teamName={response.teamName}
        fullName={`${response.firstName} ${response.lastName}`}
        teamLogoUrl={null}
        picture={response.headshotUrl}
        position={positions[draft.sport][response.position]}
        playerNumber={null}
      />

      <Space isVertical isSmall />

      <StatsRow stats={seasonAverage || []} />

      <Space isVertical isSmall />

      <Group isVertical isCentered>
        <BasicTextCard
          title="Profile"
          isAnimated={false}
          data={[
            {
              label: 'Birthdate',
              value: response.birthDate,
            },
            {
              label: 'Experience',
              value: response.experience,
            },
            {
              label: 'College',
              value: response.college,
            },
            {
              label: 'Height',
              value: response.height,
            },
          ]}
        />

        {seasonPerformanceList.length > 0 && (
          <>
            <Space isVertical isMedium />

            <GeneralText>
              <b>Current Season Performance</b>
            </GeneralText>

            <Space isVertical />
            <Group isFullWidth isVertical spaceSize={10}>
              {seasonPerformanceList.map((item, index) => {
                let title = `Week ${item}`;
                let data = response.seasonResults[`week${item}`];

                if (draft && draft.sport === 'nba') {
                  const momentObject = moment(item, 'YYYYMMDD');
                  title = momentObject.format('YYYY/MM/DD');
                  console.log(
                    response.seasonResults,
                    momentObject.format('YYYY-MMM-DD').toUpperCase(),
                    response.seasonResults[
                      momentObject.format('YYYY-MMM-DD').toUpperCase()
                    ]
                  );
                  data =
                    response.seasonResults[
                      momentObject.format('YYYY-MMM-DD').toUpperCase()
                    ];
                }

                // console.log(response)

                // console.log(formatMetrics(data, response.position, draft.sport), data, response.position)

                return (
                  <BasicTextCard
                    key={item}
                    title={title}
                    isAnimated={false}
                    data={formatMetrics(data, response.position, draft.sport)}
                  />
                );
              })}
            </Group>
          </>
        )}
      </Group>
    </>
  );
};

PlayerProfile.propTypes = {
  competitorId: PropTypes.string.isRequired,
  seasonAverage: PropTypes.array,
};

export default PlayerProfile;
